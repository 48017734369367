import React from 'react';
import PropTypes from 'prop-types';
import { getDistanceBetweenPoints, getPointOnCircle } from 'helpers/math-helper';
import { getAllTasksData } from 'helpers/module-helper';
import TaskCircle from './task-circle';
import './task-counter.scss';

const TaskCounter = ({
	currentTaskId, 
	circleCenterPoint = {x: 27.75, y: 27.75}, 
	startingAngle = 220, 
	totalAngle = 80, 
	radius = 25,
}) => {
	// Debugging variable, if on, will show the middle of the circle
	const isDebugging = false;

	const taskData = getAllTasksData();
	const angleBetweenPoints = totalAngle / (taskData.length + 2); // Two circles are enlarged
	const actualAngleBetweenPoints = totalAngle / taskData.length;

	// Calculating average circle size
	const pointA = getPointOnCircle(circleCenterPoint, radius, 0);
	const pointB = getPointOnCircle(circleCenterPoint, radius, angleBetweenPoints);
	const distance = getDistanceBetweenPoints(pointA, pointB);
	// Percentage in size of circle, used to distance circles
	const percentageDistanceBetweenPoints = 10;
	const circleSize = distance - distance * ((percentageDistanceBetweenPoints * 2) / 100);

	return (
		<div className='TaskCounter'>
			{taskData && taskData.map((task, index) => {

				const isCurrent = task.id === currentTaskId;
				const isBig = isCurrent || index === taskData.length - 1;

				// Check current task and handle task circle state
				let currentAngle = index * actualAngleBetweenPoints + startingAngle;

				// Only so mange degrees in a circle
				if (currentAngle > 360) {
					currentAngle = currentAngle - 360;
				}

				return (
					<TaskCircle key={index}
						startAngle={startingAngle}
						endAngle={currentAngle} 
						isBig={isBig} 
						isCurrent={isCurrent} 
						size={circleSize}
						radius={radius}
						centerPoint={circleCenterPoint}
					/>
				);
			})}

			{isDebugging &&
				<div className='TaskCounter-debuggerCircleCenter'
					style={{top: circleCenterPoint.y + 'em', left: circleCenterPoint.x + 'em'}}/>
			}
		</div>
	);
};

TaskCounter.propTypes = {
	currentTaskId: PropTypes.string.isRequired,
	circleCenterPoint: PropTypes.object,
	startingAngle: PropTypes.number,
	totalAngle: PropTypes.number,
	radius: PropTypes.number,
};

export default TaskCounter;