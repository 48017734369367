import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import {getSliderEffects} from 'helpers/module-helper';
import Button from 'components/ui/button/button';
import TaskIntro from '../task-intro/task-intro';
import './slider.scss';

const Slider = ({
	moduleId, 
	previousTaskData, 
	taskData, 
	handleCompleteTask, 
	updateLoggedTime, 
	goToPreviousTask, 
	canGoBack
}) => {
	const [currentAnswerPercentage, setCurrentAnswerPercentage] = useState(50);

	const timeout = useRef(null);
	const [continueIsDisabled, setContinueIsDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	// Setup timeout for switching page
	useEffect(() => {
		setContinueIsDisabled(true);
		timeout.current = setTimeout(() => {
			setContinueIsDisabled(false);
		}, 1000);

		return () => {
			/* Clear timeout on unmount */
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData]);

	/**
	 * Update current answer percentage on previousTaskData change
	 */
	useEffect(() => {
		setIsLoading(false);
		if (previousTaskData && previousTaskData.currentAnswerPercentage) {
			setCurrentAnswerPercentage(previousTaskData.currentAnswerPercentage);
		} else {
			setCurrentAnswerPercentage(50);
		}
	}, [taskData, previousTaskData]);

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Get new effects */
		let newEffects = getSliderEffects(taskData, currentAnswerPercentage);

		handleCompleteTask(
			newEffects,
			{currentAnswerPercentage: currentAnswerPercentage}
		);
	};

	/**
	 * Handles updating current answer percentage, and logged time
	 */
	const handleUpdateCurrentAnswerPercentage = (newValue) => {
		updateLoggedTime();
		setCurrentAnswerPercentage(newValue);
	};

	return (
		<div className='Slider'>
			<div id='taskIntro' className='Slider-intro'>
				<TaskIntro 
					moduleId={moduleId}
					taskId={taskData.id}
					text={taskData.text}
					image={taskData.image}
				/>
			</div>

			<div className='Slider-sliderWrapper'>
				<input 
					className='Slider-inputSlider'
					onChange={(e) => {
						handleUpdateCurrentAnswerPercentage(e.target.value);
					}}
					type='range'
					min={0} 
					max={100} 
					value={currentAnswerPercentage}
				/>
				<div className='Slider-textWrapper'>
					<div className='Slider-text minimum'>
						<span>{taskData.minText}</span>
					</div>
					<div className='Slider-text maximum'>
						<span>{taskData.maxText}</span>
					</div>
				</div>
			</div>

			{/* Done button */}
			<div className='Slider-doneBtn'>
				<Button
					isDisabled={continueIsDisabled || isLoading}
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'ok')}
					onClick={() => {completeTask(); setIsLoading(true);}}
				/>
			</div>
			
			{canGoBack &&
				<div className='Slider-previousButton'>
					<Button
						isDisabled={isLoading}
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'back')}
						onClick={() => {goToPreviousTask(true); setIsLoading(true);}}
					/>
				</div>
			}
		</div>
	);
};

Slider.propTypes = {
	moduleId: PropTypes.string.isRequired,
	previousTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
	canGoBack: PropTypes.bool.isRequired,
};

export default Slider;