import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { calculateDataForKeywordCategory, getResultsFromData } from 'helpers/keywords-helpers';
import { getText } from 'helpers/text-helper';
import { nextStepData } from 'data/next-step-data';
import { resultsData } from 'data/results-data';
import { keywordCategories } from 'data/keywords-data';
import ResultDocument from 'components/pdf-styles/result-pdf';
import Button from 'components/ui/button/button';
import './results-overview.scss';

const ResultsOverview = ({keywordPoints, goToPreviousTask}) => {
	/* Get results */
	const careerResults = getResultsFromData(keywordPoints, resultsData).splice(0, appConfig.shownCareers);
	
	const keywordCategoriesData = [];
	keywordCategories.forEach((category) => {
		const keywordCategoryData = calculateDataForKeywordCategory(category.id, keywordPoints)
			.splice(0, appConfig.shownKeywords);
		
		keywordCategoriesData.push({
			id: category.id,
			name: category.name,
			data: keywordCategoryData
		});
	});
	
	const nextStepResults = getResultsFromData(keywordPoints, nextStepData).splice(0, 3);

	return (
		<div className='ResultsOverview'>
			{/* KEYWORD RESULT OVERVIEW */}
			<div className='ResultsOverview-keywords'>
				<div className='ResultsOverview-keywordsTitle'>
					<span>{getText('gameUi', 'yourProfile')}</span>
				</div>
				{keywordCategoriesData && keywordCategoriesData.map((keywordCategory) => {
					return (
						<div key={keywordCategory.id} className={'ResultsOverview-category ' + keywordCategory.id}>
							<div className='ResultsOverview-categoryTitle'>
								<span>{keywordCategory.name}</span>
							</div>
							<div className='ResultsOverview-keywordsWrapper'>
								{keywordCategory.data.map((data, index) => {
									return (
										<div key={index} className='ResultsOverview-keyword'>
											<div className={'ResultsOverview-point ' + keywordCategory.id}/>
											<div className='ResultsOverview-keywordName'>
												<span>{data.name}</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>

			{/* CAREER RESULT OVERVIEW */}
			<div className='ResultsOverview-career'>
				<div className='ResultsOverview-careersTitle'>
					<span>{getText('statsTexts', 'careerPaths')}</span>
				</div>
				<div className='ResultsOverview-careers'>
					{careerResults.map((career, index) => {
						const careerData = resultsData.find((r) => {return r.id === career.resultId;});
						
						// If careerData could not be found
						if (!careerData) {
							return <div key={index}></div>;
						}

						return (
							<div key={index} className={'ResultsOverview-careerBubble number-' + index}>
								<span>{careerData.splitTitle ? careerData.splitTitle : careerData.title}</span>
							</div>
						);
					})}
				</div>
			</div>

			{/* NEXT STEP RESULT OVERVIEW */}
			<div className='ResultsOverview-nextSteps'>
				<div className='ResultsOverview-resultTitle'>
					<span>{getText('gameUi', 'nextStep')}</span>
				</div>
				{nextStepResults.map((nextStep, index) => {
					/* Get result data */
					const data = nextStepData.find((r) => {return r.id === nextStep.resultId;});
					if (!data) {
						return <div key={index}/>;
					}

					return (
						<div key={index} className={'ResultsOverview-nextStep number-' + index}>
							<span>{data.title}</span>

							<div className={'ResultsOverview-nextStepSubtitle number-' + index}>
								<span>{data.subtitle}</span>
							</div>
						</div>
					);
				})}
			</div>
			
			{/* Back button */}
			<div className="ResultsOverview-backBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'back')}
					onClick={() => {goToPreviousTask();}}
				/>
			</div>

			<div className='ResultsOverview-pdfButton'>
				<PDFDownloadLink
					document={<ResultDocument keywords={keywordPoints}/>}
					fileName={
						getText('pdf', 'fileName')
					}
				>
					{({loading}) => {
						return (
							<Button
								text={getText('gameUi', 'savePdf')}
								isDisabled={loading}
								classes={['white', 'confirmTask', 'responsive', 'shadow']}
								onClick={() => {}}
							/>
						);
					}}
				</PDFDownloadLink>
			</div>
		</div>
	);
};

ResultsOverview.propTypes = {
	keywordPoints: PropTypes.array.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
};

export default ResultsOverview;