import React, { useEffect, useState } from 'react';
import { confirmPasswordReset } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import appConfig from 'config/app.config';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './reset-password.scss';

const ResetPassword = () => {
	// Password input and repeat input
	const [newPasswordInput, setNewPasswordInput] = useState('');
	const [newPasswordInputConfirm, setNewPasswordInputConfirm] = useState('');
	
	// Feedback for error messages and validation loading
	const [feedback, setFeedback] = useState('');
	const [isValidating, setIsValidating] = useState(false);
	
	// Controls shown layout
	const [hasReset, setHasReset] = useState(false);

	// Firebase confirmation code, found in URL
	const [oobCode, setOobCode] = useState('');

	useEffect(() => {
		const searchParams = new URLSearchParams(document.location.search);
		const oobCode = searchParams.get('oobCode');
		setOobCode(oobCode);
	}, []);

	/**
	 * Handles input for given input category
	 * @param {string} category 
	 * @param {object} event 
	 */
	const handleInput = (category, event) => {
		const value = event.target.value;
		switch (category) {
		case 'password':
			setNewPasswordInput(value);
			break;
		case 'password-repeat':
			setNewPasswordInputConfirm(value);
			break;

		default:
			console.error('UNKNOWN INPUT CATEGORY');
			break;
		}
	};

	/**
	 * Tries to validate password, if possible will try and reset password
	 */
	const submitNewPassword = async () => {
		setIsValidating(true);

		if (newPasswordInput === '' || newPasswordInputConfirm === '') {
			setIsValidating(false);
			setFeedback(getText('error', 'allFields'));
			return;
		} else if (newPasswordInput !== newPasswordInputConfirm) {
			setIsValidating(false);
			setFeedback(getText('error', 'codesNotTheSame'));
			return;
		} else if (!oobCode) {
			setIsValidating(false);
			setFeedback(getText('error', 'unknownResetError'));
			return;
		}

		try {
			const auth = firebase.auth();
			await confirmPasswordReset(auth, oobCode, newPasswordInputConfirm).then(() => {
				setHasReset(true);
			});
		} catch (error) {
			setFeedback(getText('error', 'unknownResetError'));
		}
	};

	return (
		<div className='ResetPassword'>
			<div className='ResetPassword-dialogue'>
				{
					hasReset ?
						<div className='ResetPassword-returnWrapper'>
							<div className='ResetPassword-title'>
								<span>{getText('adminTexts', 'success')}</span>
							</div>
							<div className='ResetPassword-message'>
								<a href={appConfig.gameUrl + '/skole'}>
									{getText('adminTexts', 'goToLogin')}
								</a>
							</div>
						</div>
						:
						<form className='ResetPassword-form'>
							<div className='ResetPassword-header'>
								<span>{getText('adminTexts', 'resetPassword2')}</span>
							</div>
							<div className='ResetPassword-inputWrapper'>
								<span>{getText('adminTexts', 'newPassword')}</span>
								<input
									className='ResetPassword-input'
									name="password"
									type="password"
									placeholder={getText('adminTexts', 'newPassword')}
									autoComplete="section-ResetPassword password"
									value={newPasswordInput ? newPasswordInput : ''}
									onChange={(event) => {handleInput('password', event);}}
								/>
							</div>
							<div className='ResetPassword-inputWrapper'>
								<span>{getText('adminTexts', 'repeatPassword')}</span>
								<input
									className='ResetPassword-input'
									name="password"
									type="password"
									placeholder={getText('adminTexts', 'newPassword')}
									autoComplete="section-ResetPassword password"
									value={newPasswordInputConfirm ? newPasswordInputConfirm : ''} 
									onChange={(event)=>{handleInput('password-repeat', event);}}
								/>
							</div>
							<div className='ResetPassword-loginButton'>
								<Button 
									isLoading={isValidating}
									classes={['shadow', 'white', 'blueBorder', 'responsive']}
									text={getText('adminTexts', 'resetPassword2')} 
									onClick={() => {submitNewPassword();}}
								/>
							</div>
							<p className="ResetPassword-errorMessage">{feedback}</p>
						</form>
				}
			</div>
		</div>
	);
};

export default ResetPassword;