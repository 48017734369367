import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import './order-dnd-background-container.scss';

const OrderDndBackgroundContainer = ({handleUnsetPreviewItem}) => {
	/* Drop functionality */
	const [, drop] = useDrop({
		accept: 'item',
		collect: (monitor) => {
			if (monitor.isOver()) {
				handleUnsetPreviewItem('background', 0);
			}
		}
	});

	return (
		<div ref={drop} className={'OrderDndBackgroundContainer'}/>
	);
};

OrderDndBackgroundContainer.propTypes = {
	handleUnsetPreviewItem: PropTypes.func.isRequired,
};

export default OrderDndBackgroundContainer;