import {sortArrayByProperty} from './array-helper';
import {calculateDataForKeywordCategory, getResultsFromData} from 'helpers/keywords-helpers';
import {keywordCategories, keywordsData } from 'data/keywords-data';
import {nextStepData} from 'data/next-step-data';
import {resultsData} from 'data/results-data';
import {module1Tasks} from 'data/module-1-tasks-data';
import {taskTypesData} from 'data/task-types-data';

/**
 * Gets the number of playthroughs for the given school id
 */
export function getNumberOfPlaythroughs(schoolId, statistics) {
	return statistics.filter((stat) => {
		return (
			stat.schoolId === schoolId &&
			stat.results
		);
	}).length;
};


/**
 * Get all school classes
 * @param {array} schoolStats 
 * @returns 
 */
export function getSchoolClassesAndYears(schoolStats) {
	const classes = [];
	const years = [];
	const classLevels = [];

	/* No stats */
	if (!schoolStats) {
		return [classes, years, classLevels];
	}

	schoolStats.forEach((schoolStat) => {
		if (schoolStat.schoolClass && classes.indexOf(schoolStat.schoolClass) < 0) {
			classes.push(schoolStat.schoolClass);

			const level = schoolStat.schoolClass.replace(/\D/g, '');
			if (classLevels.indexOf(level) < 0) {
				classLevels.push(level);
			}
		} 
		if (schoolStat.created && schoolStat.created.length === 7) {
			const year = schoolStat.created.slice(0, 4);
			if (years.indexOf(year) < 0) {
				years.push(year);
			}
		}
	});
	return [classes.sort(), years.sort().reverse(), classLevels.sort()];
}

/**
 * Get basic results (keyword points, next steps, careers)
 * @param {array} schoolStats 
 * @returns 
 */
export function getBasicResults(schoolStats) {
	const basicResults = [];
	schoolStats.forEach((data) => {
		/* Get results */
		const keywordsData = data.results;

		/* Calculate keyword points */
		const keywordsResult = [];
		keywordCategories.forEach((category) => {
			const categoryResult = calculateDataForKeywordCategory(category.id, keywordsData);
			keywordsResult.push({categoryId: category.id, data: categoryResult});
		});

		/* Get next steps and careers */
		const currentNextStepResults = getResultsFromData(keywordsData, nextStepData);
		const currentCareerResults = getResultsFromData(keywordsData, resultsData);
		
		/* Add to array */
		const dataObject = {
			keywords: keywordsResult,
			nextSteps: currentNextStepResults,
			careers: currentCareerResults,
		};
		basicResults.push(dataObject);
	});

	return basicResults;
};

/**
 * Calculate keyword stats
 * @param {array} basicResults 
 * @returns 
 */
export function calculateKeywordStats(basicResults) {
	const keywordStats = [];
	
	keywordsData.forEach((keyword) => {
		let sum = 0;
		basicResults.forEach((resultSet) => {
			const categoryList = resultSet.keywords.find((set) => {
				return set.categoryId === keyword.categoryId;
			});

			if (categoryList) {
				const keywordData = categoryList.data.find((data) => {
					return data.keywordId === keyword.id;
				});

				if (keywordData) {
					sum += keywordData.percent;
				}
			}
		});

		const average = sum > 0 ? sum / basicResults.length : 0;
		const keywordCategoryData = keywordCategories.find((kc) => {
			return kc.id === keyword.categoryId;
		});
		const category = (keywordCategoryData ? keywordCategoryData.name : keyword.categoryId);

		keywordStats.push({title: keyword.title, category, average});
	});
	sortArrayByProperty(keywordStats, 'categoryId', 'DESC', 'average', 'DESC');
	
	return keywordStats;
}

/**
 * Calculate next step stats
 * @param {array} basicResults 
 * @returns 
 */
export function calculateNextStepStats(basicResults) {
	const nextStepStats = [];

	nextStepData.forEach((step) => {
		let firstCount = 0;
		let topThreeCount = 0;
		let avrPercentage = 0;
		let avrCount = 0;
		basicResults.forEach((resultSet) => {
			const stepIndex = resultSet.nextSteps.findIndex((set) => {
				return set.resultId === step.id;
			});

			if (stepIndex === 0) {
				firstCount ++;
				topThreeCount ++;
			} else if (stepIndex < 3 && stepIndex > -1) {
				topThreeCount ++;
			}

			if (stepIndex >= 0) {
				avrCount ++;
				avrPercentage += resultSet.nextSteps[stepIndex].score;
			}	
		});

		const firstPercentage = firstCount > 0 ? firstCount / basicResults.length * 100 : 0;
		const topThreePercentage = topThreeCount > 0 ? topThreeCount / basicResults.length * 100 : 0;
		avrPercentage = (avrCount > 0 ? (avrPercentage / avrCount) * 100 : 0);
		
		nextStepStats.push({
			id: step.id,
			title: step.title,
			subtitle: step.subtitle,
			firstPercentage: firstPercentage,
			topThreePercentage: topThreePercentage,
			avrPercentage: avrPercentage
		});
	});
	sortArrayByProperty(nextStepStats, 'firstPercentage', 'DESC', 'topThreePercentage', 'DESC');

	return nextStepStats;
};

/**
 * Calculate careers stats
 * @param {array} basicResults 
 */
export function calculateCareerStats(basicResults) {
	const careerStats = [];
	
	resultsData.forEach((career) => {
		let firstCount = 0;
		let topThreeCount = 0;
		let avrPercentage = 0;
		let avrCount = 0;
		
		basicResults.forEach((resultSet) => {
			const stepIndex = resultSet.careers.findIndex((set) => {
				return set.resultId === career.id;
			});

			if (stepIndex === 0) {
				firstCount ++;
				topThreeCount ++;
			} else if (stepIndex < 3 && stepIndex > -1) {
				topThreeCount ++;
			}

			if (stepIndex >= 0) {
				avrCount ++;
				avrPercentage += resultSet.careers[stepIndex].score;
			}
		});

		const firstPercentage = firstCount > 0 ? firstCount / basicResults.length * 100 : 0;
		const topThreePercentage = topThreeCount > 0 ? topThreeCount / basicResults.length * 100 : 0;
		avrPercentage = (avrCount > 0 ? (avrPercentage / avrCount) * 100 : 0);

		careerStats.push({
			id: career.id,
			title: career.title,
			firstPercentage: firstPercentage,
			topThreePercentage: topThreePercentage,
			avrPercentage: avrPercentage
		});
	});

	sortArrayByProperty(careerStats, 'firstPercentage', 'DESC', 'topThreePercentage', 'DESC');
	
	return careerStats;
};


/**
 * Calculate 
 * @param {array} tasks 
 * @returns 
 */
export function calculateTaskStats(tasks) {
	let taskStats = {};

	/* Group task stats by task type */
	taskTypesData.forEach((taskType) => {
		taskStats[taskType.id] = [];

		/* Get data for all tasks of type */
		const tasksData = module1Tasks.filter((task) => {return task.type === taskType.id;});
		tasksData.forEach((taskData) => {
			/* Get all task answers */
			const taskAnswers = tasks.filter((t) => {return taskData.id === t.id;});

			/* Default stat object */
			const taskStatObj = {
				id: taskData.id,
				text: taskData.text,
				answers: taskAnswers.length
			};

			/* Type: Multiple choice */
			if (taskData.type === 'multiple-choice') {
				/* Prepare stat object */
				for (let i = 1; i <= taskData.options.length; i++) {
					taskStatObj['option-' + i] = 0;
					if (taskData.subtype !== 'images') {
						taskStatObj['option-' + i + '-text'] = taskData.options[i - 1].text;
					} else {
						taskStatObj['option-' + i + '-image'] = taskData.layout + '/option-' + i + '.png';
					}
				}

				/* Count how many times each option has been selected */
				taskAnswers.forEach((answer) => {
					if (answer.selectedOptionIds && answer.selectedOptionIds.length > 0) {
						answer.selectedOptionIds.forEach((optionId) => {
							if (!taskStatObj.hasOwnProperty('option-' + optionId)) {
								taskStatObj['option-' + optionId] = 0;
							}
							taskStatObj['option-' + optionId] += 1;
						});
					}
				});

				/* Calculate how many times each option has been selected in percent */
				for (let i = 1; i <= taskData.options.length; i++) {
					if (taskAnswers.length > 0) {
						const percent = (taskStatObj['option-' + i] / taskAnswers.length) * 100;
						taskStatObj['option-' + i] = percent.toFixed(1) + '%';
					}
				}
			}

			/* Type: Slider */
			if (taskData.type === 'slider') {
				let valueSum = 0;
				let answers = 0;
				taskAnswers.forEach((answer) => {
					if (answer.hasOwnProperty('currentAnswerPercentage')) {
						valueSum += parseInt(answer['currentAnswerPercentage']);
						answers += 1;
					}
				});
				const average = (answers > 0 ? (valueSum / answers).toFixed(1) : 0);
				taskStatObj.average = average;
			}

			/* Type: Order */
			if (taskData.type === 'order') {
				/* Prepare stat object */
				for (let i = 1; i <= taskData.items.length; i++) {
					taskStatObj['option-' + i] = 0;
					taskStatObj['option-' + i + '-text'] = taskData.items[i - 1].text;
				}

				/* Sum the order number of each item */
				let answers = 0;
				taskAnswers.forEach((answer) => {
					if (answer.orderedItems && answer.orderedItems.length > 0) {
						answers += 1;
						answer.orderedItems.forEach((item) => {
							if (!taskStatObj.hasOwnProperty('option-' + item.id)) {
								taskStatObj['option-' + item.id] = 0;
							}
							taskStatObj['option-' + item.id] += parseInt(item.positionNumber);
						});
					}
				});

				/* Calculate average order of each item */
				for (let i = 1; i <= taskData.items.length; i++) {
					if (answers > 0) {
						const avr = (taskStatObj['option-' + i] / answers);
						taskStatObj['option-' + i] = avr.toFixed(1);
					}
				}
			}

			taskStats[taskType.id].push(taskStatObj);
		});
	});

	return taskStats;
};