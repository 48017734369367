import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getPointOnCircle } from 'helpers/math-helper';
import './task-circle.scss';

const TaskCircle = ({centerPoint, startAngle = 0, endAngle, radius, size, isCurrent, isBig}) => {
	const startPoint = getPointOnCircle(centerPoint, radius, startAngle);
	const [currentPosition, setCurrentPosition] = useState(startPoint);
	const [currentAngle, setCurrentAngle] = useState(startAngle);
	const interval = useRef(null);
	
	const incrementChange = 0.5;
	useEffect(() => {
		let newAngle = currentAngle;

		interval.current = setInterval(() => {
			let endInterval = false;
			newAngle = newAngle + incrementChange;

			if (newAngle >= endAngle) {
				newAngle = endAngle;
				endInterval = true;
			}
			const newPosition = getPointOnCircle(centerPoint, radius, newAngle);
			setCurrentAngle(newAngle);
			setCurrentPosition(newPosition);

			if (endInterval) {
				clearInterval(interval.current);
			}
		}, 5);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {

		return () => {
			/* Clear interval on unmount */
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, []);

	return (
		<div className={'TaskCircle' + (isCurrent ? ' current' : '') + (isBig ? ' big' : '')}
			style={{
				top: currentPosition.y + 'em', 
				left: currentPosition.x + 'em',
				width: size + 'em',
				height: size + 'em',
			}}>
		</div>
	);
};

TaskCircle.propTypes = {
	centerPoint: PropTypes.object.isRequired,
	startAngle: PropTypes.number,
	endAngle: PropTypes.number.isRequired,
	isCurrent: PropTypes.bool.isRequired,
	isBig: PropTypes.bool.isRequired,
	radius: PropTypes.number.isRequired,
	size: PropTypes.number.isRequired,
};

export default TaskCircle;