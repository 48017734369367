import PropTypes from 'prop-types';
import {calculateDataForKeywordCategory} from 'helpers/keywords-helpers';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import KeywordCategory from 'components/ui/keyword-category/keyword-category';
import './results-keywords.scss';

const ResultsKeywords = ({taskData, keywordPoints, handleCompleteTask, goToPreviousTask}) => {
	/**
	 * Complete task
	 */
	const completeTask = () => {
		handleCompleteTask([], {});
	};

	const keywordData = calculateDataForKeywordCategory(taskData.categoryId, keywordPoints);

	return (
		<div className="ResultsKeywords">
			<div className="ResultsKeywords-title"><span>{taskData.title}</span></div>
			<div className="ResultsKeywords-categories">
				<KeywordCategory 
					keywordsData={keywordData} 
					categoryId={taskData.categoryId}
				/>
			</div>
			{/* Back button */}
			{taskData.categoryId !== 'skills' &&
				<div className="ResultsKeywords-backBtn">
					<Button
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'back')}
						onClick={() => {goToPreviousTask();}}
					/>
				</div>
			}

			{/* Done button */}
			<div className="ResultsKeywords-doneBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'continue')}
					onClick={() => {completeTask();}}
				/>
			</div>
			
		</div>
	);
};

ResultsKeywords.propTypes = {
	taskData: PropTypes.object.isRequired,
	keywordPoints: PropTypes.array.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
};

export default ResultsKeywords;