import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './facilitator-password-reset.scss';

const FacilitatorPasswordReset = ({
	email,
	isResettingPassword,
	passwordIsReset,
	feedback,
	handleEmailInput,
	resetPassword,
	goToPage
}) => {

	if (passwordIsReset) {
		return (
			<div className='FacilitatorPasswordReset'>
				<div className="FacilitatorPasswordReset-content">
					<div className="FacilitatorPasswordReset-header">
						<div className='FacilitatorPasswordReset-title'>
							<span>{getText('adminTexts', 'resetPassword')}</span>
						</div>
					</div>
					<div className="FacilitatorPasswordReset-body">
						<div className="FacilitatorPasswordReset-text">
							<span>{feedback}</span>
						</div>
						<div className="FacilitatorPasswordReset-goToPageBtn">
							<Button
								text={getText('adminTexts', 'backToLogin')}
								classes={['smallFontSize', 'darkBlueText', 'responsive']}
								onClick={() => {goToPage('login');}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className='FacilitatorPasswordReset'>
			<div className="FacilitatorPasswordReset-content">
				<div className="FacilitatorPasswordReset-header">
					<div className='FacilitatorPasswordReset-title'>
						<span>{getText('adminTexts', 'resetPassword')}</span>
					</div>
				</div>
				<div className="FacilitatorPasswordReset-body">
					<div className="FacilitatorPasswordReset-text">
						<span>{getText('adminTexts', 'resetMessage')}</span>
					</div>
					<form className="FacilitatorPasswordReset-form" onSubmit={resetPassword}>
						<div className='FacilitatorPasswordReset-inputWrapper'>
							<span>{getText('statsTexts', 'email')}</span>
							<input
								className={'FacilitatorPasswordReset-input'}
								name="email"
								type="text"
								placeholder={getText('statsTexts', 'email')}
								autoComplete="section-resetpassword email"
								value={email ? email : ''}
								onChange={(event)=>{handleEmailInput(event);}}
							/>
						</div>
						<div className='FacilitatorPasswordReset-confirmButton'>
							<Button
								isDisabled={isResettingPassword}
								isLoading={isResettingPassword}
								classes={['shadow', 'blue', 'blueBorder', 'responsive']}
								text={'Nulstil'}
								onClick={resetPassword}
							/>
						</div>
						<div className="FacilitatorPasswordReset-errorMessage">
							<span>{feedback}</span>
						</div>
					</form>
					<div className="FacilitatorPasswordReset-goToPageBtn">
						<Button
							text={getText('adminTexts', 'backToLogin')}
							classes={['smallFontSize', 'darkBlueText', 'responsive']}
							onClick={() => {goToPage('login');}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

FacilitatorPasswordReset.propTypes = {
	email: PropTypes.string,
	isResettingPassword: PropTypes.bool,
	passwordIsReset: PropTypes.bool,
	feedback: PropTypes.string,
	handleEmailInput: PropTypes.func.isRequired,
	resetPassword: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
};

export default FacilitatorPasswordReset;