import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {modulesData} from 'data/modules-data';
import './task-navigation.scss';

const TaskNavigation = ({currentModuleAndTaskId, setCurrentModuleAndTaskId}) => {
	
	/* Track if open / closed */
	const [isOpen, setIsOpen] = useState(false);

	/* On open, autoscroll to current task on list */
	useEffect(() => {
		if (isOpen) {
			const taskElements = document.getElementsByClassName('TaskNavigation-task selected');
			if (taskElements && taskElements.length > 0) {
				taskElements[0].scrollIntoView(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div className={'TaskNavigation' + (isOpen ? ' open' : '')} onClick={() => {setIsOpen(!isOpen);}}>
			{isOpen && <div className="TaskNavigation-modules">
				{modulesData.map((module) => {
					return (
						<div key={module.id} className="TaskNavigation-module">
							{module.tasks && module.tasks.map((task, index) => {
								const isSelected = (
									module.id === currentModuleAndTaskId.moduleId &&
									task.id === currentModuleAndTaskId.taskId
								);
								return (
									<div 
										key={task.id} 
										className={'TaskNavigation-task' + (isSelected ? ' selected' : '')}
										onClick={() => {
											setCurrentModuleAndTaskId({
												moduleId: module.id,
												taskId: task.id
											});
										}}
									>
										{task.id} <span>({task.type + (task.subtype ? ' ' + task.subtype : '')})</span>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>}
			<div className="TaskNavigation-toggleBtn" />
		</div>
	);
};

TaskNavigation.propTypes = {
	currentModuleAndTaskId: PropTypes.object.isRequired,
	setCurrentModuleAndTaskId: PropTypes.func.isRequired,
};

export default TaskNavigation;
