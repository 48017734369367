import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {
	getCurrentModuleAndTaskId, 
	getTaskFromId, 
	getTaskComponentFromType, 
	getNextModuleAndTaskId, 
	checkIfLastTaskInLastModule,
	getTimeSinceLastActivity,
	getPreviousModuleAndTaskId
} from 'helpers/module-helper';
import {getText} from 'helpers/text-helper';
import {addPointsToKeyword} from 'helpers/keywords-helpers';
import TaskNavigation from 'components/ui/dev-tools/task-navigation';
import PopupKeywords from 'components/ui/dev-tools/popup-keywords';
import PopupResults from 'components/ui/dev-tools/popup-results';
import TaskCounter from 'components/ui/task-counter/task-counter';
import Button from 'components/ui/button/button';
import './module.scss';

const Module = (props) => {
	const {
		playerData, 
		updatePlayerData, 
		saveStatistics,
		startNewTest,
		transitionBackground, 
		updatePreviousTaskData,
		previousTaskData
	} = props;
	/* Popup with keywords max points, popup with results */
	const [showKeywordsPopup, setShowKeywordsPopup] = useState(false);
	const [showResultsPopup, setShowResultsPopup] = useState(false);

	// Tracking time
	const [loggedTime, setLoggedTime] = useState(0);
	const [timeStamp, setTimeStamp] = useState(Date.now());

	const [fadeOut, setFadeOut] = useState(false);
	const [isCompletingTask, setIsCompletingTask] = useState(false);
	const transitionTimeout = useRef(null);

	// Has gone back to previous task already
	const [hasGoneBack, setHasGoneBack] = useState(false);

	/* Current module and task id */
	const [currentModuleAndTaskId, setCurrentModuleAndTaskId] = useState({moduleId: null, taskId: null});

	/* Get data for current module and task */
	const currentTaskData = getTaskFromId(currentModuleAndTaskId.moduleId, currentModuleAndTaskId.taskId);

	/* Get task component */
	const CurrentTaskComponent = (currentTaskData 
		? getTaskComponentFromType(currentTaskData.type)
		: null
	);

	/* Get player data for current task */
	const currentPlayerTaskData = (previousTaskData && previousTaskData.id === currentTaskData.id 
		? previousTaskData : null);

	/* Check if last task in last module */
	const isLastTaskInLastModule = 
		checkIfLastTaskInLastModule(currentModuleAndTaskId.moduleId, currentModuleAndTaskId.taskId);
	
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Get current module id and task id */
		setCurrentModuleAndTaskId(getCurrentModuleAndTaskId(playerData));

		return () => {
			/* Update logged time */
			updateLoggedTime();

			/* Clear timeout on unmount */
			if (transitionTimeout.current) {
				clearTimeout(transitionTimeout.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * Update logged time
	 * @param {bool} resetLoggedTime
	 */
	const updateLoggedTime = (resetLoggedTime = false) => {
		/* Get number of miliseconds since last update */
		const miliseconds = getTimeSinceLastActivity(timeStamp);
		const newLoggedTime = loggedTime + miliseconds;

		setLoggedTime(resetLoggedTime ? 0 : newLoggedTime);
		setTimeStamp(Date.now());
		return newLoggedTime;
	};

	/**
	 * Updates player data and marks current task as completed
	 * @param {array} newEffects 
	 * @param {object} answer 
	 */
	const handleCompleteTask = (newEffects, answer) => {
		if (isCompletingTask) {
			return;
		}

		setIsCompletingTask(true);

		/* Get logged time, reset */
		const loggedTime = updateLoggedTime(true);

		/* Implement new effects */
		let keywordPoints = (playerData.keywordPoints ? JSON.parse(JSON.stringify(playerData.keywordPoints)) : []);
		const newKeywordPoints = [];
		newEffects.forEach((effect) => {
			if (effect.type === 'keyword-points') {
				// Saving keywordpoints for task, so that it can be potentially undone
				newKeywordPoints.push({keywordIds: effect.keywordIds, points: effect.points});
				effect.keywordIds.forEach((keywordId, index) => {
					if (effect.points.length > index) {
						keywordPoints = addPointsToKeyword(keywordPoints, keywordId, effect.points[index]);
					}
				});
			}
		});

		/* Add completed task to array of tasks */
		const playerTasks = (playerData.tasks ? JSON.parse(JSON.stringify(playerData.tasks)) : []);
		const currentTask = {
			taskId: currentTaskData.id,
			time: loggedTime,
		};
		playerTasks.push(currentTask);

		transitionBackground();
		setFadeOut(true);
		const newTimeout = setTimeout(function() {
			setFadeOut(false);

			const {nextModuleId, nextTaskId} = 
				getNextModuleAndTaskId(currentModuleAndTaskId.moduleId, currentModuleAndTaskId.taskId);

			/* Update player data */
			updatePlayerData({
				tasks: playerTasks,
				currentModuleId: nextModuleId ? nextModuleId : playerData.currentModuleId,
				currentTaskId: nextTaskId ? nextTaskId : playerData.currentTaskId,
				keywordPoints
			}).then((response) => {
				// Save task data separately
				const taskData = {
					type: currentTaskData.type,
					moduleId: currentModuleAndTaskId.moduleId,
					id: currentModuleAndTaskId.taskId, 
					...answer,
					keywordPoints: newKeywordPoints,
				};
				updatePreviousTaskData(taskData, response.playerData).then(() => {
					/* Go to next task */
					setIsCompletingTask(false);
					goToNextTask(nextModuleId, nextTaskId);
				});
			});
		}, 500);
		transitionTimeout.current = newTimeout;
	};

	/**
	 * Navigate to next task 
	 * @param {string} nextModuleId 
	 * @param {string} nextTaskId 
	 * @param {object} taskData
	 */
	const goToNextTask = (nextModuleId, nextTaskId) => {
		setHasGoneBack(false);
		if (nextModuleId && nextTaskId) {
			/* Go to next task */
			setCurrentModuleAndTaskId({moduleId: nextModuleId, taskId: nextTaskId});
			const nextTaskData = getTaskFromId(nextModuleId, nextTaskId);

			if (nextTaskData && nextTaskData.saveStatistics) {
				saveStatistics('results');
			}
		} else {
			// TODO: last task completed
		}
	};

	/**
	 * Navigate to previous task
	 * @param {bool} isSolvableTask
	 */
	const goToPreviousTask = (isSolvableTask = false) => {
		transitionBackground();
		setFadeOut(true);
		const newTimeout = setTimeout(function() {
			setFadeOut(false);
			const {previousModuleId, previousTaskId} = 
				getPreviousModuleAndTaskId(currentModuleAndTaskId.moduleId, currentModuleAndTaskId.taskId);

			if (isSolvableTask) {
				setHasGoneBack(true);
			}

			setCurrentModuleAndTaskId({moduleId: previousModuleId, taskId: previousTaskId});
		}, 500);

		transitionTimeout.current = newTimeout;
	};

	/**
	 * Checks whether a back button should be shown for tasks.
	 * @returns Bool true if back button should be accessible
	 */
	const canGoBack = () => {
		if (!hasGoneBack && previousTaskData && currentTaskData && currentTaskData.number !== 1) {
			return true;
		}

		return false;
	};
	
	/**
	 * Start new test
	 */
	const handleStartNewTest = () => {
		/* Reset player data */
		startNewTest().then((response) => {
			/* Go to first module and task id */
			transitionBackground();
			setFadeOut(true);
			const newTimeout = setTimeout(function() {
				setFadeOut(false);
				
				/* Get first module and task id */
				const {moduleId, taskId} = getCurrentModuleAndTaskId(response.newPlayerData);
				setCurrentModuleAndTaskId({moduleId, taskId});
			}, 500);
	
			transitionTimeout.current = newTimeout;
			
			/* Reset logged time */
			setLoggedTime(0);
			setTimeStamp(Date.now());

		});
	};

	return (
		<div className={'Module'}>
			{(currentModuleAndTaskId && currentModuleAndTaskId.taskId) &&
				<TaskCounter currentTaskId={currentModuleAndTaskId.taskId}/>
			}
			<div className={'ModuleWrapper ' + currentModuleAndTaskId.moduleId + (fadeOut ? ' hidden' : '')}>
				<div className="Module-task">
					{CurrentTaskComponent &&
						<CurrentTaskComponent
							key={currentModuleAndTaskId.taskId}
							moduleId = {currentModuleAndTaskId.moduleId}
							taskData = {currentTaskData}
							previousTaskData = {currentPlayerTaskData ? currentPlayerTaskData : null}
							keywordPoints = {playerData.keywordPoints ? playerData.keywordPoints : []}
							handleCompleteTask = {handleCompleteTask}
							updateLoggedTime = {updateLoggedTime}
							goToPreviousTask = {goToPreviousTask}
							canGoBack = {canGoBack()}
						/>
					}
				</div>
				{/* Start new test btn */}
				{isLastTaskInLastModule && <div className="Module-startNewTestBtn">
					<Button 
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'startNewTest')} 
						onClick={() => {handleStartNewTest();}}
					/>
				</div>}

				{/* Dev tool: task navigation */}
				{appConfig.showDevTools && <TaskNavigation 
					currentModuleAndTaskId={currentModuleAndTaskId}
					setCurrentModuleAndTaskId={setCurrentModuleAndTaskId}
				/>}

				{/* Dev tool: keywords popup */}
				{appConfig.showDevTools && 
					<div 
						className="Module-keywordsPopupBtn" 
						onClick={() => {setShowKeywordsPopup(true);}}
					>{getText('gameUi', 'keywords')}</div>}
				{showKeywordsPopup && <PopupKeywords setShowKeywordsPopup={setShowKeywordsPopup} />}

				{/* Dev tool: results popup */}
				{appConfig.showDevTools && 
					<div 
						className="Module-resultsPopupBtn" 
						onClick={() => {setShowResultsPopup(true);}}
					>{getText('gameUi', 'results')}</div>}
				{showResultsPopup && 
					<PopupResults 
						keywordPoints = {playerData.keywordPoints ? playerData.keywordPoints : []}
						setShowResultsPopup = {setShowResultsPopup}
					/>
				}
			</div>
		</div>
	);
};

Module.propTypes = {
	playerData: PropTypes.object,
	updatePlayerData: PropTypes.func.isRequired,
	saveStatistics: PropTypes.func.isRequired,
	startNewTest: PropTypes.func.isRequired,
	transitionBackground: PropTypes.func.isRequired,
	updatePreviousTaskData: PropTypes.func.isRequired,
	previousTaskData: PropTypes.object
};

export default Module;