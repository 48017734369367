import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import Facilitator from './facilitator';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			schools: [],
			schoolId: null,
			schoolStats: [],
			users: []
		};
		this.unsubscribeSchools = null;
		this.unsubscribeSchoolStats = null;
		this.unsubscribeUsers = null;
	}
	
	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.subscribeToUsers();
		this.subscribeToSchools().then((response) => {
			if (response.status === 'success') {
				/* Auto-select first school */
				if (this.state.schools.length > 0) {
					this.handleSelectSchool(this.state.schools[0].id);
				} else {
					/* No schools */
					this.setState({isLoading: false});
				}
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeSchools !== null) this.unsubscribeSchools();
		if (this.unsubscribeSchoolStats !== null) this.unsubscribeSchoolStats();
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
	};
	
	/**
	 * Subscribe to schools
	 * @returns 
	 */
	subscribeToSchools = () => {
		if (this.unsubscribeSchools !== null) this.unsubscribeSchools();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeSchools = db.collection(appConfig.schoolsDbName).onSnapshot(
				(querySnapshot) => {
					let schools = [];
					querySnapshot.forEach((doc) => {
						/* Only get schools the facilitator is added to */
						if (
							doc.data().facilitatorEmails && 
							doc.data().facilitatorEmails.indexOf(this.props.userData.email) >= 0
						) {
							schools.push({id: doc.id, ...doc.data()});
						}
					});
					this.setState({schools}, () => {
						resolve({status: 'success'});
					});
				},
				(error) => {
					console.error(getText('error', 'couldNotGetSchool'), error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Subscribe to school game data
	 */
	subscribeToSchoolStats = () => {
		if (this.unsubscribeSchoolStats !== null) this.unsubscribeSchoolStats();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeSchoolStats = db.collection(appConfig.statisticsDbName)
				.where('schoolId', '==', this.state.schoolId)
				.onSnapshot(
					(querySnapshot) => {
						let data = [];
						querySnapshot.forEach((doc) => {
							const statObj = {id: doc.id, ...doc.data()};
							if (statObj.schoolClass) {
								statObj.schoolClass = statObj.schoolClass.toLowerCase();
							}							
							data.push(statObj);
						});						
						this.setState({schoolStats: data, isLoading: false}, () => {
							resolve({status: 'success'});
						});
					},
					(error) => {
						console.error(getText('error', 'couldNotGetSchool'), error);
						resolve({status: 'error', error: error, isLoading: false});
					}
				);
		});
	};

	/**
	 * Subscribe to users
	 */
	subscribeToUsers = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();

		/* Subscribe to users */
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			this.unsubscribeUsers = db.collection(appConfig.usersDbName).onSnapshot(
				(querySnapshot) => {
					const users = [];
					querySnapshot.forEach((doc) => {
						users.push({id: doc.id, ...doc.data()});
					});
					this.setState({users: users}, () => {
						resolve({status: 'success'});
					});
				},
				(error) => {
					console.error(getText('error', 'couldNotGetUsers'), error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Select school
	 * @param {string} schoolId 
	 */
	handleSelectSchool = (schoolId) => {
		if (this.unsubscribeSchoolStats !== null) this.unsubscribeSchoolStats();
		this.setState({
			isLoading: true,
			schoolId: schoolId,
			schoolStats: []
		}, () => {
			this.subscribeToSchoolStats();
		});
	};

	/**
	 * Update school data
	 * @param {string} schoolId
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateSchool = (schoolId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update course data */
		const db = firebase.firestore();
		return db.collection(appConfig.schoolsDbName).doc(schoolId).update(updates);
	};

	render = () => {
		if (this.state.isLoading) {
			return null;
		}
		return (
			<Facilitator
				schoolId={this.state.schoolId}
				schoolStats={this.state.schoolStats}
				schools={this.state.schools}
				users={this.state.users}
				handleSelectSchool={this.handleSelectSchool}
				updateSchool={this.updateSchool}
				handleLogout={this.props.handleLogout}
			/>
		);
	};
};

FacilitatorController.propTypes = {
	userData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorController;