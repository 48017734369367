import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './cookie-warning.scss';

const CookieWarning = ({hasShownCookieBanner, openCookieBanner}) => {

	return (
		<div className='CookieWarning'>
			{hasShownCookieBanner ?
				<div className='CookieWarning-text'>
					<span>{getText('cookieUi', 'cookieWarning')}</span>
				</div>
				:
				<div className='CookieWarning-text'>
					<div className='CookieWarning-welcomeText bold'>
						<span>{getText('flow', 'welcome')}</span>
					</div>
					<div className='CookieWarning-welcomeText topPadded'>
						<span>{getText('flow', 'welcome2')}</span>
					</div>
					<div className='CookieWarning-button'>
						<Button
							classes={['white', 'confirmTask', 'responsive', 'shadow']} 
							text={getText('gameUi', 'start')} 
							onClick={() => {openCookieBanner();}}
						/>
					</div>
				</div>
			}
		</div>
	);
};

CookieWarning.propTypes = {
	hasShownCookieBanner: PropTypes.bool.isRequired,
	openCookieBanner: PropTypes.func.isRequired,
};

export default CookieWarning;