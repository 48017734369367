import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './calculating-dot.scss';
import resetAnimation from 'helpers/animation-helper';

const CalculatingDot = ({index, size, colors, animationDelay}) => {
	const elementId = 'dot' + index;
	const [colorIndex, setColorIndex] = useState(Math.floor(Math.random() * (colors.length)));

	// setup animation end event and anination replay
	useEffect(() => {
		const element = document.getElementById(elementId);

		if (element) {
			let newColorIndex = colorIndex;
			element.addEventListener('animationend', () => {
				resetAnimation(elementId);

				newColorIndex = Math.floor(Math.random() * (colors.length));
				
				setColorIndex(newColorIndex);
			});
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id={elementId} className={'CalculatingDot ' + colors[colorIndex]}
			style={{
				width: size + 'em',
				height: size + 'em',
				animationDelay: animationDelay + 's'
			}}>
		</div>
	);
};

CalculatingDot.propTypes = {
	index: PropTypes.number.isRequired,
	size: PropTypes.number.isRequired,
	colors: PropTypes.array.isRequired,
	animationDelay: PropTypes.number.isRequired,
};

export default CalculatingDot;