import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {
	getBasicResults, 
	calculateNextStepStats, 
	calculateCareerStats, 
	calculateKeywordStats,
} from 'helpers/stats-helper';
import StatsTable from 'components/ui/stats-table/stats-table';
import './admin-stats.scss';

const AdminStats = (props) => {
	/* Props */
	const {
		schools, 
		statistics,
		tagsData, 
		years, 
		classLevels, 
		selectedYears, 
		selectedClassLevels, 
		selectedTagIds, 
		toggleYear, 
		toggleClassLevel, 
		toggleTag
	} = props;

	/* Get filtered stats data */
	const startedGames = [];
	const completedGames = [];
	let totalCompletionTime = 0;

	statistics.forEach((statData) => {
		const year = (statData.created && statData.created.length === 7 
			? statData.created.slice(0, 4) 
			: null
		);
		const classLevel = (statData.schoolClass && statData.schoolClass.length > 0
			? statData.schoolClass.replace(/\D/g, '')
			:	null
		);
		
		
		const schoolData = schools.find((school) => {return school.id === statData.schoolId;});
		let includeStat = true;
		if (selectedTagIds.length > 0) {
			if (schoolData && schoolData.tagIds && schoolData.tagIds.length > 0) {
				includeStat = false;
				schoolData.tagIds.forEach((tagId) => {
					if (includeStat) return;
					if (selectedTagIds.indexOf(tagId) >= 0) includeStat = true;
				});
			} else {
				includeStat = false;
			}
		}

		if (
			year && selectedYears.indexOf(year) >= 0 &&
			classLevel && selectedClassLevels.indexOf(classLevel) >= 0 &&
			includeStat 
		) {
			if (!statData.results) {
				startedGames.push(statData);
			} else {
				completedGames.push(statData);
				if (statData.totalTime > 0) {
					totalCompletionTime += statData.totalTime;
				}
			}
		}
	});

	/* Avr completion time */
	const avrCompletionTime = (completedGames.length > 0
		? (totalCompletionTime / completedGames.length) / (60 * 1000.)
		: 0
	);

	/* Calculate basic stats */
	const basicResults = getBasicResults(completedGames);
			
	/* Calculate keyword stats */
	const keywordStats = calculateKeywordStats(basicResults);
		
	/* Calculate next step stats */
	const nextStepStats = calculateNextStepStats(basicResults);
	
	/* Calculate careers stats */
	const careerPathStats = calculateCareerStats(basicResults);
	
	return (
		<div className='AdminStats'>
			{/* Filters */}
			<div className="AdminStats-filters">

				{/* Years */}
				<div className="AdminStats-filter years">
					<div className="AdminStats-filterName">
						<span>{getText('statsTexts', 'years')}:</span>	
					</div>
					<div className="AdminStats-filterOptions">
						{years.map((year, index) => {
							return (
								<div 
									key={index}
									className={'AdminStats-filterOption' 
										+ (selectedYears.indexOf(year) >= 0 ? ' selected' : '')}
									onClick={() => {toggleYear(year);}}
								>
									<span>{year}</span>
								</div>
							);
						})}
					</div>
				</div>

				{/* Class levels */}
				<div className="AdminStats-filter classLevels">
					<div className="AdminStats-filterName">
						<span>{getText('statsTexts', 'classLevel')}:</span>	
					</div>
					<div className="AdminStats-filterOptions">
						{classLevels.map((cLevel, index) => {
							return (
								<div 
									key={index}
									className={'AdminStats-filterOption' 
										+ (selectedClassLevels.indexOf(cLevel) >= 0 ? ' selected' : '')}
									onClick={() => {toggleClassLevel(cLevel);}}
								>
									<span>{cLevel}</span>
								</div>
							);
						})}
					</div>
				</div>

				{/* Tags */}
				<div className="AdminStats-filter tags">
					<div className="AdminStats-filterName">
						<span>{getText('adminTexts', 'tags')}:</span>	
					</div>
					<div className="AdminStats-filterOptions">
						{tagsData.map((tag, index) => {
							return (
								<div 
									key={index}
									className={'AdminStats-filterOption' 
										+ (selectedTagIds.indexOf(tag.id) >= 0 ? ' selected' : '')}
									onClick={() => {toggleTag(tag.id);}}
								>
									<span>{tag.value}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{/* Games started & completed, avr completion time */}
			<div className="AdminStats-games">
				<div className="AdminStats-gamesStarted">
					<span>{getText('statsTexts', 'numberOfStartedGames')}:</span>
					<span>{startedGames.length}</span>
				</div>
				<div className="AdminStats-gamesCompleted">
					<span>{getText('statsTexts', 'numberOfCompletedGames')}:</span>
					<span>{completedGames.length}</span>
				</div>
				<div className="AdminStats-avrCompletionTime">
					<span>{getText('statsTexts', 'avrCompletionTime')}:</span>
					<span>{avrCompletionTime.toFixed(2)} {getText('statsTexts', 'minutes')}</span>
				</div>
			</div>
		

			{/* Stats */}
			{(nextStepStats && careerPathStats && keywordStats) &&
				<div className='AdminStats-stats'>
					<StatsTable 
						nextStepStats={nextStepStats} 
						careerPathStats={careerPathStats}
						keywordStats={keywordStats}
					/>
				</div>}
		</div>
	);
};

AdminStats.propTypes = {
	schools: PropTypes.array.isRequired,
	statistics: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	years: PropTypes.array.isRequired,
	classLevels: PropTypes.array.isRequired,
	selectedYears: PropTypes.array.isRequired,
	selectedClassLevels: PropTypes.array.isRequired,
	selectedTagIds: PropTypes.array.isRequired,
	toggleYear: PropTypes.func.isRequired,
	toggleClassLevel: PropTypes.func.isRequired,
	toggleTag: PropTypes.func.isRequired,
};

export default AdminStats;