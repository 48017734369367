import { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { getResultsFromData } from 'helpers/keywords-helpers';
import { getText, renderMarkdown } from 'helpers/text-helper';
import { nextStepData } from 'data/next-step-data';
import NextStep from 'components/ui/next-step/next-step';
import Button from 'components/ui/button/button';
import './results-next-step.scss';

const ResultsNextStep = ({taskData, keywordPoints, handleCompleteTask, goToPreviousTask}) => {
	/* Get result */
	const results = getResultsFromData(keywordPoints, nextStepData);

	/* Page variables */
	const shownAmount = appConfig.shownSteps;

	/* Page variables */
	const [pageNumber, setPageNumber] = useState(1);
	const pageAmount = Math.ceil(results.length / shownAmount);

	const [openResultId, setOpenResultId] = useState(null);

	/**
	 * Increase or decrease page number, clamped to lowest and highest amount of number available
	 * @param {number} direction either -1 or 1 
	 */
	const incrementPage = (direction) => {
		let newPage = pageNumber + direction;

		if (newPage < 1) {
			newPage = 1;
		} else if (newPage > pageAmount) {
			newPage = pageAmount;
		}

		setPageNumber(newPage);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		handleCompleteTask([], {});
	};

	return (
		<div className="ResultsNextStep">
			<div className="ResultsNextStep-title"><span>{taskData.title}</span></div>
			{(taskData.subtitle && pageNumber === 1 && !openResultId) &&
				<div className='ResultsNextStep-subtitle'>
					{renderMarkdown(taskData.subtitle)}
				</div>
			}
			<div className="ResultsNextStep-results">
				{/* Careers */}
				{results.slice(shownAmount * (pageNumber - 1), shownAmount * pageNumber).map((result, index) => {
					// If result is open, then dont show others behind it
					if (openResultId && openResultId !== result.resultId) {
						return <div key={result.resultId}></div>;
					}

					return (
						<NextStep key={index} 
							resultId={result.resultId}
							style={'position-' + index}
							setOpenResultId={setOpenResultId}
						/>
					);
				})}
			</div>
			
			{(!openResultId && pageNumber > 1) &&
				<div className='ResultsNextStep-nextButton previous' onClick={() => {incrementPage(-1);}}/>
			}
			{(!openResultId && pageNumber < pageAmount) &&
				<div className='ResultsNextStep-nextButton' onClick={() => {incrementPage(1);}}/>
			}
			
			{/* Back button */}
			<div className="ResultsNextStep-backBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'back')}
					onClick={() => {goToPreviousTask();}}
				/>
			</div>

			{/* Done button */}
			<div className="ResultsNextStep-doneBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'continue')}
					onClick={() => {completeTask();}}
				/>
			</div>
		</div>
	);
};

ResultsNextStep.propTypes = {
	keywordPoints: PropTypes.array.isRequired,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
};

export default ResultsNextStep;