import { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getResultsFromData} from 'helpers/keywords-helpers';
import {getText, renderMarkdown} from 'helpers/text-helper';
import { resultsData } from 'data/results-data';
import Button from 'components/ui/button/button';
import Result from 'components/ui/result/result';
import './results-careers.scss';

const ResultsCareers = ({taskData, keywordPoints, handleCompleteTask, goToPreviousTask}) => {
	/**
	 * Complete task
	 */
	const completeTask = () => {
		handleCompleteTask([], {});
	};
	
	/* Get result */
	const results = getResultsFromData(keywordPoints, resultsData);

	/* Page variables */
	const [pageNumber, setPageNumber] = useState(1);
	const shownAmount = appConfig.shownCareers;
	const pageAmount = Math.ceil(results.length / shownAmount);

	const [openResultId, setOpenResultId] = useState(null);

	/**
	 * Increase or decrease page number, clamped to lowest and highest amount of number available
	 * @param {number} direction either -1 or 1 
	 */
	const incrementPage = (direction) => {
		let newPage = pageNumber + direction;

		if (newPage < 1) {
			newPage = 1;
		} else if (newPage > pageAmount) {
			newPage = pageAmount;
		}

		setPageNumber(newPage);
	};

	return (
		<div className="ResultsCareers">
			{/* Title */}
			<div className="ResultsCareers-title"><span>{taskData.title}</span></div>
			{(!openResultId && pageNumber === 1 && taskData.subtitle) &&
				<div className='ResultsCareers-subtitle' >
					{renderMarkdown(taskData.subtitle)}
				</div>
			}

			{/* Careers */}
			{results.slice(shownAmount * (pageNumber - 1), shownAmount * pageNumber).map((result, index) => {
				// If result is open, then dont show others behind it
				if (openResultId && openResultId !== result.resultId) {
					return <div key={result.resultId}></div>;
				}

				return (
					<Result
						key={result.resultId}
						rank={pageNumber === 1 ? index + 1 : null}
						style={
							'rank-' + (index + 1) + 
							(pageNumber > 1 ? ' small' : '') + 
							(navigator.userAgent.indexOf('Mac') > 0 ? ' mac' : '')
						}
						resultId={result.resultId}
						setOpenResultId={setOpenResultId}
					/>
				);
			})}
			
			{(!openResultId && pageNumber > 1) &&
				<div className='ResultsCareers-nextButton previous' onClick={() => {incrementPage(-1);}}/>
			}
			{(!openResultId && pageNumber < pageAmount) &&
				<div className='ResultsCareers-nextButton' onClick={() => {incrementPage(1);}}/>
			}

			{/* Back button */}
			<div className="ResultsCareers-backBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'back')}
					onClick={() => {goToPreviousTask();}}
				/>
			</div>

			{/* Done button */}
			<div className="ResultsCareers-doneBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'continue')}
					onClick={() => {completeTask();}}
				/>
			</div>
		</div>
	);
};

ResultsCareers.propTypes = {
	taskData: PropTypes.object.isRequired,
	keywordPoints: PropTypes.array.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
};

export default ResultsCareers;