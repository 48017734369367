const keywordsData = [
	// EVNER
	{
		id: 'independent',
		categoryId: 'skills',
		title: 'Selvstændig'
	},
	{
		id: 'goodHands',
		categoryId: 'skills',
		title: 'God til at bruge hænderne'
	},
	{
		id: 'creative',
		categoryId: 'skills',
		title: 'Kreativ'
	},
	{
		id: 'systematic',
		categoryId: 'skills',
		title: 'Systematisk'
	},
	{
		id: 'social',
		categoryId: 'skills',
		title: 'Social'
	},
	{
		id: 'communication',
		categoryId: 'skills',
		title: 'Kommunikation'
	},
	{
		id: 'writing',
		categoryId: 'skills',
		title: 'Skrive'
	},
	{
		id: 'drawing',
		categoryId: 'skills',
		title: 'Farver/tegne'
	},
	{
		id: 'math',
		categoryId: 'skills',
		title: 'Matematik'
	},
	{
		id: 'teamwork',
		categoryId: 'skills',
		title: 'Samarbejde'
	},
	{
		id: 'spotlight',
		categoryId: 'skills',
		title: 'At være på'
	},
	{
		id: 'bookish',
		categoryId: 'skills',
		title: 'Boglig'
	},
	{
		id: 'language',
		categoryId: 'skills',
		title: 'Sprog'
	},
	{
		id: 'thorough',
		categoryId: 'skills',
		title: 'Omhyggelig'
	},
	{
		id: 'analytical',
		categoryId: 'skills',
		title: 'Analytisk'
	},

	// INTERESSER
	{
		id: 'cooking',
		categoryId: 'interests',
		title: 'Madlavning'
	},
	{
		id: 'science',
		categoryId: 'interests',
		title: 'Videnskab'
	},
	{
		id: 'reading',
		categoryId: 'interests',
		title: 'At læse'
	},
	{
		id: 'machines',
		categoryId: 'interests',
		title: 'Maskiner'
	},
	{
		id: 'politics',
		categoryId: 'interests',
		title: 'Politik og samfund'
	},
	{
		id: 'nature',
		categoryId: 'interests',
		title: 'Natur'
	},
	{
		id: 'culture',
		categoryId: 'interests',
		title: 'Kunst og kultur'
	},
	{
		id: 'computers',
		categoryId: 'interests',
		title: 'IT'
	},
	{
		id: 'technique',
		categoryId: 'interests',
		title: 'Teknik'
	},
	{
		id: 'business',
		categoryId: 'interests',
		title: 'Forretning og økonomi'
	},
	{
		id: 'health',
		categoryId: 'interests',
		title: 'Sundhed'
	},
	{
		id: 'building',
		categoryId: 'interests',
		title: 'At bygge ting'
	},
	{
		id: 'environment',
		categoryId: 'interests',
		title: 'Miljø og klima'
	},
	{
		id: 'startingThings',
		categoryId: 'interests',
		title: 'Sætte ting i gang'
	},
	{
		id: 'people',
		categoryId: 'interests',
		title: 'Mennesker'
	},
	{
		id: 'sport',
		categoryId: 'interests',
		title: 'Sport'
	},
	
	// PRÆFERENCER
	{
		id: 'independence',
		categoryId: 'preferences',
		title: 'Selvbestemmelse'
	},
	{
		id: 'workingOutside',
		categoryId: 'preferences',
		title: 'Arbejde ude'
	},
	{
		id: 'variation',
		categoryId: 'preferences',
		title: 'Variation'
	},
	{
		id: 'community',
		categoryId: 'preferences',
		title: 'Fællesskab'
	},
	{
		id: 'responsibility',
		categoryId: 'preferences',
		title: 'Have ansvar'
	},
	{
		id: 'helping',
		categoryId: 'preferences',
		title: 'Hjælpe andre'
	},
	{
		id: 'creating',
		categoryId: 'preferences',
		title: 'At skabe noget'
	},
	{
		id: 'routines',
		categoryId: 'preferences',
		title: 'Rutiner'
	},
	{
		id: 'pay',
		categoryId: 'preferences',
		title: 'God løn'
	},
	{
		id: 'shortEducation',
		categoryId: 'preferences',
		title: 'Kort uddannelse'
	},
	{
		id: 'practicalWork',
		categoryId: 'preferences',
		title: 'Praktisk arbejde'
	},
	{
		id: 'manyOpportunities',
		categoryId: 'preferences',
		title: 'Mange karriereveje'
	},
	{
		id: 'intellectualChallenges',
		categoryId: 'preferences',
		title: 'Intellektuelle udfordringer'
	},
	{
		id: 'officeWork',
		categoryId: 'preferences',
		title: 'Kontorarbejde'
	},
	{
		id: 'workingAlone',
		categoryId: 'preferences',
		title: 'Arbejde alene'
	},
	{
		id: 'creativeWork',
		categoryId: 'preferences',
		title: 'Kreativt arbejde'
	},
];

const keywordCategories = [
	{
		id: 'skills',
		name: 'Evner',
		description: 'Hvad er du god til?',
	},
	{
		id: 'interests',
		name: 'Interesser',
		description: 'Hvad interesserer du dig for?',
	},
	{
		id: 'preferences',
		name: 'Præferencer',
		description: 'Hvad er vigtigt for dig, når det gælder arbejde og uddannelse?'
	}
]

export {
	keywordsData,
	keywordCategories
};