import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './landing-page.scss';

const LandingPage = ({openCookieBanner, startGame, resetGame, hasCookieData}) => {
	/**
	 * Handle start game
	 */
	const handleStartGame = () => {
		startGame(true);
	};

	return (
		<div className='LandingPage'>
			<div className='LandingPage-flowTextWrapper'>
				<div className='LandingPage-flowText'>
					<span>{getText('flow', 'landingPage')}</span>
				</div>
				<div className='LandingPage-flowText bold topPadded'>
					<span>{getText('flow', 'landingPage2')}</span>
				</div>
			</div>
			{hasCookieData ?
				<div className='LandingPage-gameButtons twoButtons'>
					<div className='LandingPage-resetGame'>
						<Button 
							classes={['white', 'confirmTask', 'responsive', 'shadow']}
							text={getText('gameUi', 'startNew')} 
							onClick={() => {resetGame(true); handleStartGame();}}
						/>
					</div>
					
					<Button 
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'continueGame')} 
						onClick={() => {handleStartGame();}}
					/>
				</div>
				:
				<div className='LandingPage-gameButtons'>
					<Button 
						classes={['white', 'confirmTask', 'responsive', 'shadow']} 
						text={getText('gameUi', 'start')} 
						onClick={() => {handleStartGame();}}
					/>
				</div>
			}
			<div 
				className="LandingPage-toggleCookiePopup" 
				onClick={() => {openCookieBanner();}}
			/>
			<div className="LandingPage-resetGameBtn">
				<Button 
					classes={['white', 'confirmTask', 'responsive', 'shadow']} 
					text={getText('gameUi', 'reset')} 
					onClick={() => {resetGame();}}
				/>
			</div>
		</div>
	);
};

LandingPage.propTypes = {
	openCookieBanner: PropTypes.func.isRequired,
	startGame: PropTypes.func.isRequired,
	resetGame: PropTypes.func.isRequired,
	hasCookieData: PropTypes.bool.isRequired,
};

export default LandingPage;