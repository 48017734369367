import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SortDndContainer from './sort-dnd-container';
import SortDndPreview from './sort-dnd-preview';
import SortDndItem from './sort-dnd-item';
import Button from 'components/ui/button/button';
import TaskIntro from '../task-intro/task-intro';
import { getSortEffects } from 'helpers/module-helper';
import {shuffleArray} from 'helpers/array-helper';
import { getText } from 'helpers/text-helper';
import './sort.scss';

const Sort = (props) => {
	const {
		moduleId,
		previousTaskData, 
		taskData, 
		handleCompleteTask,
		updateLoggedTime,
		goToPreviousTask,
		canGoBack,
	} = props;

	/* Get items to be sorted */
	const getItems = () => {
		/* Get items from player data or data file */
		let items = (previousTaskData && previousTaskData.sortedItems
			? previousTaskData.sortedItems // TODO: check it matches?
			: taskData.items.map((item) => {return {itemId: item.id, containerId: null};})
		);

		/* Shuffle items and return */
		return shuffleArray(items);
	};

	/* Track sorted items & errors */
	const [sortedItems, setSortedItems] = useState(getItems());

	/* Check if completed already */
	const [isCompleted, setIsCompleted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	/* Update sorted items if new task */
	useEffect(() => {
		const items = getItems();
		setSortedItems(items);
		setIsLoading(false);
		
		/* Check if task is completed: all items are placed into a category */
		if (items && !items.some((item) => {return item.containerId === null;})) {
			setIsCompleted(true);
		} else {
			setIsCompleted(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = sortedItems.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, containerId) => {
		/* Move item */
		let newSortedItems = JSON.parse(JSON.stringify(sortedItems));
		const itemIndex = newSortedItems.findIndex((item) => {return item.itemId === itemId;});
		newSortedItems[itemIndex].containerId = containerId;
		setSortedItems(newSortedItems);

		/* Update logged time */
		updateLoggedTime();

		const placedItems = newSortedItems.filter((item) => {
			return item.containerId !== null;
		});

		/* Check if task is completed: all items are placed into a category */
		if (placedItems.length === taskData.items.length) {
			setIsCompleted(true);
		} else {
			setIsCompleted(false);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSortedItems) => {
		/* Return if not all items have been sorted */
		if (newSortedItems.some((item) => {return item.containerId === null;})) return;

		let newEffects = getSortEffects(taskData, newSortedItems);

		/* Save completed task */
		handleCompleteTask(
			newEffects,
			{sortedItems: newSortedItems}
		);
	};

	return (
		<div className='Sort'>
			<div className="Sort-intro">
				<TaskIntro 
					moduleId={moduleId}
					taskId={taskData.id}
					text={taskData.text}
					image={taskData.image}
				/>
			</div>
			<div className='Sort-taskWrapper'>
				<div className="Sort-categories">
					{taskData.categories && taskData.categories.map((categoryData) => {
						return (
							<div key={categoryData.id} className="Sort-category">
								{taskData.withHeader && <div className="Sort-categoryTitle">
									<span>{categoryData.text}</span>
								</div>}
								<SortDndContainer
									layout={taskData.layout}
									containerId={categoryData.id}
									handleFindItem={handleFindItem}
									handleMoveItem={handleMoveItem}
								>
									{sortedItems.filter((item) => {
										return item.containerId === categoryData.id;
									}).map((item) => {
										const itemData = taskData.items.find((itemData) => {
											return itemData.id === item.itemId;
										});
										if (!itemData) return null;

										let classes = null;

										return (
											<SortDndItem 
												key={itemData.id} 
												isDraggable={true} 
												classes={classes}
												itemId={itemData.id}
											>
												<span>{itemData.text}</span>
											</SortDndItem>
										);
									})}
								</SortDndContainer>
							</div>
						);
					})}
				</div>
				<div className="Sort-items">
					{sortedItems.filter((i) => {return i.containerId === null;}).map((item) => {
						const itemData = taskData.items.find((itemData) => {
							return itemData.id === item.itemId;
						});
						if (!itemData) return null;
						const isDraggable = !previousTaskData || !previousTaskData.isCompleted;
						return (
							<SortDndItem key={item.itemId} isDraggable={isDraggable} itemId={item.itemId}>
								<span>{itemData.text}</span>
							</SortDndItem>
						);
					})}
				</div>
			</div>
			<SortDndPreview itemsData={taskData.items} />
			
			{/* Done button */}
			<div className="Sort-doneBtn">
				<Button
					isDisabled={!isCompleted || isLoading}
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'ok')}
					onClick={() => {completeTask(sortedItems); setIsLoading(true);}}
				/>
			</div>
			
			{canGoBack &&
				<div className='Sort-previousButton'>
					<Button
						isDisabled={isLoading}
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'back')}
						onClick={() => {goToPreviousTask(true); setIsLoading(true);}}
					/>
				</div>
			}
		</div>
	);
};

Sort.propTypes = {
	moduleId: PropTypes.string.isRequired,
	previousTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
	canGoBack: PropTypes.bool.isRequired,
};

export default Sort;
