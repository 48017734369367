
import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import { getText, renderMarkdown } from 'helpers/text-helper';
import AdminLoginController from './admin/admin-login/admin-login-controller';
import FacilitatorLoginController from './facilitator/facilitator-login/facilitator-login-controller';
import ResetPassword from './reset-password/reset-password';
import Game from './game/game';
import Login from './login/login';
import LandingPage from './landing-page/landing-page';
import CookieBanner from './ui/cookie-banner/cookie-banner';
import CookieWarning from './cookie-warning/cookie-warning';
import Background from './ui/background/background';
import ImageLoader from './ui/image-loader/image-loader';
import Button from './ui/button/button';
import './app.scss';

function App() {
	/* Login info (if logged in) */
	const [loginInfo, setLoginInfo] = useState(null);

	/* Cookies */
	const [showCookieBanner, setShowCookieBanner] = useState(false);
	const [cookiesAccepted, setCookiesAccepted] = useState(false);
	const [hasShownCookieBanner, setHasShownCookieBanner] = useState(false);
	const cookieData = getCookie(appConfig.gameDataCookieName);
	const hasCookieData = (cookieData && cookieData.length > 0) ? true : false;

	/* Page (landing or game page) */
	const [startGame, setStartGame] = useState(false);
	
	/* Background transition */
	const [isBackgroundTransitioning, setIsBackgroundTransitioning] = useState(false);
	const transitionBackground = () => {
		setIsBackgroundTransitioning(true);
	};

	/* Logout warning */
	const [showLogoutWarning, setShowLogoutWarning] = useState(false);

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Initialize firebase */
		firebaseInit();

		/* Check cookie consent */
		const gameDataFromCookie = getCookie(appConfig.cookiesAcceptedCookieName);
		if (gameDataFromCookie && gameDataFromCookie.length > 0) {
			setCookiesAccepted(true);
		} else {
			setCookiesAccepted(false);
		}

		/* Check for login cookie */
		const loginCookie = getCookie(appConfig.loginCookieName);
		if (loginCookie && loginCookie.length > 0) {
			setLoginInfo(JSON.parse(loginCookie));
		} else {
			setLoginInfo(null);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Accept / deny cookies
	 * @param {bool} isAccepted 
	 */
	const toggleAcceptCookies = (isAccepted) => {
		setHasShownCookieBanner(true);
		setShowCookieBanner(false);
		setCookiesAccepted(isAccepted);

		if (isAccepted) {
			setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
			// If logged in, save it in cookie
			if (loginInfo) {
				setLoginCookie();
			}
		} else {
			setLoginInfo(null);
			deleteCookie(appConfig.loginCookieName);
			deleteCookie(appConfig.cookiesAcceptedCookieName);
			deleteCookie(appConfig.gameDataCookieName);
		}
	};

	/**
	 * Handles player login, and saves login if cookies are accepted
	 */
	const handleLogin = (schoolId, schoolName, schoolClass) => {	
		setLoginCookie(schoolId, schoolName, schoolClass);
		setLoginInfo({schoolId, schoolName, schoolClass});
	};

	/**
	 * Handles player logout
	 */
	const handleLogout = () => {
		setLoginInfo(null);
		resetGame(true);
		setStartGame(false);
		deleteCookie(appConfig.loginCookieName);
		setShowLogoutWarning(false);
	};

	/**
	 * Handles setting login cookie if cookies are accepted
	 */
	const setLoginCookie = (schoolId, schoolName, schoolClass) => {
		/* Check cookie consent */
		const gameDataFromCookie = getCookie(appConfig.cookiesAcceptedCookieName);
		if (gameDataFromCookie && gameDataFromCookie.length > 0) {
			setCookie(
				appConfig.loginCookieName, JSON.stringify({
					schoolId: schoolId, schoolName: schoolName, schoolClass: schoolClass
				}), 1);
		}
	};

	/**
	 * Reset game
	 */
	const resetGame = (onlyGame = false) => {
		if (!onlyGame) {
			deleteCookie(appConfig.cookiesAcceptedCookieName);
		}
		deleteCookie(appConfig.gameDataCookieName);
	};

	/**
	 * Opens cookie banner
	 */
	const openCookieBanner = () => {
		setShowCookieBanner(true);
	};

	/* Admin section */
	if (window.location.pathname.toLowerCase().includes('admin')) {
		return <div id="app-admin" className="App admin">
			<Background 
				isLandingPage={false}
				isBackgroundTransitioning={false} 
				setIsBackgroundTransitioning={setIsBackgroundTransitioning}
			/>
			<AdminLoginController />
		</div>;
	}

	/* Facilitator section (school stats) */
	if (window.location.pathname.toLowerCase().includes('skole')) {
		return <div id="app-facilitator" className='App facilitator'>
			<Background 
				isLandingPage={false}
				isBackgroundTransitioning={false} 
				setIsBackgroundTransitioning={setIsBackgroundTransitioning}
			/>
			<FacilitatorLoginController />
		</div>;
	}

	/* Reset password */
	if (window.location.pathname.toLowerCase().includes('passreset')) {
		return <div id="app-passreset" className='App passreset'>
			<Background 
				isLandingPage={false}
				isBackgroundTransitioning={false} 
				setIsBackgroundTransitioning={setIsBackgroundTransitioning}
			/>
			<ResetPassword />
		</div>;
	}

	/* Player game */
	return (
		<div className="App">
			{/* Pre-load images */}
			<ImageLoader />

			<div className="App-blueBg" />
			<div className='App-wrap'>
				<Background 
					isLandingPage={!startGame} 
					isBackgroundTransitioning={isBackgroundTransitioning} 
					setIsBackgroundTransitioning={setIsBackgroundTransitioning}
				/>
				{loginInfo ? 
					<div className='App-gameWrapper'>
						<div className='App-logout'>
							<Button
								text={getText('adminTexts', 'logout')}
								classes={['responsive', 'white', 'rounded', 'small', 'bold']}
								onClick={() => {setShowLogoutWarning(true);}}
							/>
						</div>
						{startGame ?
							<Game transitionBackground={transitionBackground} loginInfo={loginInfo}/>
							:
							<LandingPage 
								startGame={setStartGame}
								resetGame={resetGame}
								openCookieBanner={openCookieBanner}
								cookiesAccepted={cookiesAccepted}
								hasCookieData={hasCookieData}
							/>
						}
					</div>
					:
					<div className='App-loginWrapper'>
						{cookiesAccepted ?
							<Login handleLogin={handleLogin}/>
							:
							<CookieWarning 
								openCookieBanner={openCookieBanner}
								hasShownCookieBanner={hasShownCookieBanner}
							/>
						}
					</div>
				}
				<div 
					className="App-toggleCookiePopup" 
					onClick={() => {openCookieBanner();}}
				/>
				
				{showCookieBanner && 
					<CookieBanner 
						toggleAcceptCookies={toggleAcceptCookies}
					/>
				}

				{showLogoutWarning &&
					<div className='App-logoutPopup'>
						<div className='App-logoutPopupWindow'>
							<div className='App-logoutPopupText'>
								{renderMarkdown(getText('gameUi', 'logoutWarning'))}
							</div>
							<div className='App-logoutPopupButtons'>
								<div className='App-logoutPopupButton'>
									<Button 
										text={getText('gameUi', 'cancel')}
										classes={['shadow', 'white', 'blueBorder', 'responsive']}
										onClick={() => {setShowLogoutWarning(false);}}
									/>
								</div>
								<div className='App-logoutPopupButton'>
									<Button 
										text={getText('gameUi', 'continue')}
										classes={['shadow', 'blue', 'responsive']}
										onClick={() => {handleLogout();}}
									/>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default App;
