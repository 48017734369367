import PropTypes from 'prop-types';
import {getModuleFromId} from 'helpers/module-helper';
import {getText, renderMarkdown} from 'helpers/text-helper';
import './task-intro.scss';

const TaskIntro = ({moduleId, taskId, showNumberOfAnswersToSelect, numberOfCorrectAnswers, text, image}) => {
	/* Get task title */
	let title = null;
	const currentModuleData = getModuleFromId(moduleId);
	if (currentModuleData && currentModuleData.tasks) {
		const taskIndex = currentModuleData.tasks.findIndex((t) => {return t.id === taskId;});
		if (taskIndex >= 0) {
			title = getText('gameUi', 'task') + ' ' + (taskIndex + 1);
		}
	}

	/* Get required number of answers */
	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className='TaskIntro-title'><span>{title}</span></div>
			<div className='TaskIntro-text'>
				{text && renderMarkdown(text)}
				{showRequiredNumberOfAnswers && <span>{getText('gameUi', 'choose')} {numberOfCorrectAnswers}:</span>}
			</div>
			{image && <div className='TaskIntro-image' />}
		</div>
	);
};

TaskIntro.propTypes = {
	moduleId: PropTypes.string.isRequired,
	taskId: PropTypes.string.isRequired,
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string
};

export default TaskIntro;
