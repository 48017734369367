import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import {checkIfTagIsInUse} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './popup-delete-school.scss';

const PopupDeleteSchool = ({schools, schoolId, setDeleteSchoolId}) => {
	/**
	 * Deleting status and error message 
	 */
	const [isDeleting, setIsDeleting] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	/* Delete school */
	const handleDeleteSchool = () => {
		if (isDeleting) return;

		setIsDeleting(true);

		/* Delete tags that are no longer in use */
		const db = firebase.firestore();
		const schoolData = schools.find((school)=>{return school.id === schoolId;});
		if (schoolData && schoolData.tagIds && schoolData.tagIds.length > 0) {
			schoolData.tagIds.forEach((tagId) => {
				const tagIsInUse = checkIfTagIsInUse(tagId, schools, schoolId);
				if (!tagIsInUse) {
					db.collection(appConfig.tagsDbName).doc(tagId).delete().catch((error) => {
						console.error(error);
					});
				}
			});
		}

		/* Delete school */
		db.collection(appConfig.schoolsDbName).doc(schoolId).delete().then(() => {
			setIsDeleting(false);
			setDeleteSchoolId(null);
		}).catch((error) => {
			console.error(error);
			setIsDeleting(false);
			setErrorMessage(getText('error', 'unknownError'));
		});
	};

	return (
		<div className='PopupDeleteSchool'>
			<div className='PopupDeleteSchool-popupWindow'>
				<div className='PopupDeleteSchool-popupWindowHeader'>
					<span>{getText('adminTexts', 'deleteSchoolPopupTitle')}</span>
				</div>
				<div className='PopupDeleteSchool-popupWindowText'>
					<span>{getText('adminTexts', 'deleteSchoolPopupText')}</span>
				</div>
				<div className='PopupDeleteSchool-popupWindowErrorMessage'>
					<span>{errorMessage}</span>
				</div>
				<div className='PopupDeleteSchool-popupWindowButtons'>
					<div className='PopupDeleteSchool-popupWindowButton'>
						<Button
							text={getText('adminTexts', 'cancel')}
							classes={['shadow', 'white', 'blueBorder', 'responsive']}
							onClick={() => {setDeleteSchoolId(null);}}
						/>
					</div>
					<div className='PopupDeleteSchool-popupWindowButton'>
						<Button
							isLoading={isDeleting}
							text={getText('adminTexts', 'continue')}
							classes={['shadow', 'blue', 'responsive']}
							onClick={() => {handleDeleteSchool(schoolId);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupDeleteSchool.propTypes = {
	schoolId: PropTypes.string.isRequired,
	setDeleteSchoolId: PropTypes.func.isRequired,
	schools: PropTypes.array.isRequired
};

export default PopupDeleteSchool;