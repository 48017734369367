import {sortArrayByProperty} from './array-helper';
import {module1Tasks} from 'data/module-1-tasks-data';
import {keywordsData} from 'data/keywords-data';
import {resultsData} from 'data/results-data';

/**
 * Calculate max possible keyword points (only from module 1 tasks)
 * @param {string} keywordId 
 */
export function calculateMaxKeywordPoints(keywordId) {
	let maxPoints = 0;


	module1Tasks.forEach((taskData) => {
		let taskMaxPoints = 0;

		/* Task type: slider */
		if (taskData.type === 'slider' && taskData.effects) {
			taskData.effects.forEach((effect) => {
				/* Max 1 effect should trigger for this task type, so find the one the gives the most points */
				if (effect.type === 'keyword-points' && effect.keywordIds.indexOf(keywordId) >= 0) {
					const keywordIndex = effect.keywordIds.indexOf(keywordId);
					if (
						effect.points.length > keywordIndex && 
						effect.points[keywordIndex] > taskMaxPoints
					) {
						taskMaxPoints = effect.points[keywordIndex];
					}
				}
			});
		}

		/* Task type: sort */
		if (taskData.type === 'sort' && taskData.items) {
			taskData.items.forEach((item) => {
				/* Each item can have multiple effects */
				let itemMaxPoints = 0;
				if (item.effects) {
					item.effects.forEach((effect) => {
						/* Max 1 effect should trigger for each item, find the one that gives the most points */
						if (effect.type === 'keyword-points' && effect.keywordIds.indexOf(keywordId) >= 0) {
							const keywordIndex = effect.keywordIds.indexOf(keywordId);
							if (
								effect.points.length > keywordIndex && 
								effect.points[keywordIndex] > itemMaxPoints
							) {
								itemMaxPoints = effect.points[keywordIndex];
							}
						}
					});
				}

				/* Add max points for each item to max points for task */
				taskMaxPoints += itemMaxPoints;
			});
		}

		/* Task type: order */
		if (taskData.type === 'order' && taskData.items) {
			taskData.items.forEach((item) => {
				let itemMaxPoints = 0;
				if (item.effects) {
					item.effects.forEach((effect) => {
						/* All (2) effects are triggered if item is placed at the top of the order */
						if (effect.type === 'keyword-points' && effect.keywordIds.indexOf(keywordId) >= 0) {
							const keywordIndex = effect.keywordIds.indexOf(keywordId);

							/* Add all points */
							itemMaxPoints += effect.points[keywordIndex];
						}
					});
				}
				if (itemMaxPoints > taskMaxPoints) {
					taskMaxPoints = itemMaxPoints;
				}
			});
		}

		/* Task type: multiple choice */
		if (taskData.type === 'multiple-choice' && taskData.options) {
			/* Get max points for each option */
			let optionsMaxPoints = [];
			taskData.options.forEach((option) => {
				let optionMaxPoints = 0;
				if (option.effects) {
					option.effects.forEach((effect) => {
					/* Currently only 1 effect for each option, but assume max 1 can be triggered */
						if (effect.type === 'keyword-points' && effect.keywordIds.indexOf(keywordId) >= 0) {
							const keywordIndex = effect.keywordIds.indexOf(keywordId);
							if (
								effect.points.length > keywordIndex && 
								effect.points[keywordIndex] > optionMaxPoints
							) {
								optionMaxPoints = effect.points[keywordIndex];
							}
						}
					});
				}
				/* Add to array of options max point */
				if (optionMaxPoints > 0) {
					optionsMaxPoints.push({points: optionMaxPoints});
				}
			});

			/* Order points and get the X highest (depending on how many answers player can select) */
			optionsMaxPoints = sortArrayByProperty(optionsMaxPoints, 'points', 'DESC');
			const maxSelectableOptions = (taskData.canAnswerAnyAmount === true
				? taskData.options.length
				: taskData.numberOfAnswersToSelect
			);
			for (let i = 0; i < maxSelectableOptions; i++) {
				if (optionsMaxPoints.length > i) {
					taskMaxPoints += optionsMaxPoints[i].points;
				}
			}
		} 

		/* Add max points for each task to max points for keyword */
		maxPoints += taskMaxPoints;
	});

	return maxPoints;
}


/**
 * Add new keyword points to array
 * @param {array} keywordPoints 
 * @param {string} keywordId 
 * @param {string} points 
 * @returns 
 */
export function addPointsToKeyword(keywordPoints, keywordId, points = 1) {
	let newKeywordPoints = JSON.parse(JSON.stringify(keywordPoints));

	const keywordIndex = newKeywordPoints.findIndex((kp) => {return kp.keywordId === keywordId;});
	if (keywordIndex >= 0) {
		newKeywordPoints[keywordIndex].points += points;
	} else {
		newKeywordPoints.push({keywordId, points});
	}

	return newKeywordPoints;
};

/**
 * Get results
 * @param {array} keywordPoints 
 * @returns 
 */
export function getResultsFromData(keywordPoints, data) {
	let results = [];
	data.forEach((result) => {
		if (result.keywords && result.keywords.length > 0) {
			let totalPoints = 0;
			result.keywords.forEach((keyword) => {
				const maxPoints = calculateMaxKeywordPoints(keyword.keywordId);
				if (
					maxPoints > 0 &&
					keywordPoints.some((k) => {return k.keywordId === keyword.keywordId;})
				) {
					const points = keywordPoints.find((k) => {
						return k.keywordId === keyword.keywordId;
					}).points;
					totalPoints += (points / maxPoints);
				}
			});
			if (totalPoints > 0) {
				const score = (totalPoints / result.keywords.length);
				results.push({resultId: result.id, score});
			}
		}
	});

	results = sortArrayByProperty(results, 'score', 'DESC');

	return results;
}

/**
 * Get results
 * @param {array} keywordPoints 
 * @returns 
 */
export function getExpandedResults(keywordPoints) {
	let results = [];
	resultsData.forEach((result) => {

		if (result.keywords && result.keywords.length > 0) {
			let totalPoints = 0;
			let keywords = [];
			result.keywords.forEach((keyword) => {
				const maxPoints = calculateMaxKeywordPoints(keyword.keywordId);
				let keywordObj = {
					keywordId: keyword.keywordId,
					maxPoints: maxPoints,
					points: 0,
					percent: 0
				};
				if (
					keywordPoints.some((k) => {return k.keywordId === keyword.keywordId;})
				) {
					const points = keywordPoints.find((k) => {
						return k.keywordId === keyword.keywordId;
					}).points;
					if (maxPoints > 0) {
						totalPoints += (points / maxPoints);
					}
					
					keywordObj.points = points;
					keywordObj.percent = totalPoints;
				}
				keywords.push(keywordObj);
			});
			
			const score = (totalPoints / result.keywords.length);
			results.push({resultId: result.id, score, keywords});
		}
	});

	results = sortArrayByProperty(results, 'score', 'DESC');
	if (results.length > 10) {
		results = results.slice(0, 10);
	}

	return results;
}


/**
 * Calculate pie chart data for keyword category
 * @param {string} keywordCategoryId 
 * @param {array} keywordPoints 
 * @returns 
 */

export function calculateDataForKeywordCategory(keywordCategoryId, keywordPoints) {
	/* Get all keyword points in category, calculate point value in percent of max possible value */
	let keywordPointsInCategory = [];

	if (keywordPoints.length > 0) {
		keywordPoints.forEach((keywordPoint) => {
			const keywordData = keywordsData.find((k) => {return k.id === keywordPoint.keywordId;});
			if (keywordData && keywordData.categoryId === keywordCategoryId && keywordPoint.points > 0) {
				// totalPoints += keywordPoint.points;
				const maxPoints = calculateMaxKeywordPoints(keywordData.id);
				const pointsInPercent = (maxPoints > 0
					? Math.round(keywordPoint.points * 100 / maxPoints)
					: 0
				);
				
				keywordPointsInCategory.push({
					keywordId: keywordPoint.keywordId,
					name: keywordData.title,
					points: keywordPoint.points,
					percent: pointsInPercent
				});
			}
		});

		/* Order by max percentage */
		keywordPointsInCategory = sortArrayByProperty(keywordPointsInCategory, 'percent', 'DESC');
	}

	return keywordPointsInCategory;
};