import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import apiHelper from 'helpers/api-helper';
import {checkIfValidEmail} from 'helpers/format-helper';
import {
	generateUniqueIdFromString, 
	checkIfTagIsInUse, 
	getNumberOfSchoolsEmailIsFacilitatorOf
} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import Select from 'components/ui/select/select';
import './popup-add-school.scss';

const PopupAddSchool = ({schools, users, tagsData, setShowAddSchoolPopup, setNewestSchoolAddition}) => {
	/* School input */
	const [schoolName, setSchoolName] = useState('');
	const [contactPerson, setContactPerson] = useState('');
	const [email, setEmail] = useState('');
	const [facilitatorEmails, setFacilitatorEmails] = useState('');
	const [tagIds, setTagIds] = useState([]);

	/* Adding school status, adding/remove tag status, and error message */
	const [isAddingSchool, setIsAddingSchool] = useState(false);
	const [isEditingTags, setIsEditingTags] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	/**
	 * Handle input of new school data
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		const value = event.target.value;
		if (event.target.name === 'schoolName') {
			setSchoolName(value);
		} else if (event.target.name === 'contactPerson') {
			setContactPerson(value);
		} else if (event.target.name === 'email') {
			setEmail(value);
		} else if (event.target.name === 'facilitatorEmails') {
			setFacilitatorEmails(value);
		}
		setErrorMessage(null);
	};

	/**
	 * Add new school
	 */
	const handleAddSchool = () => {
		if (isAddingSchool) return;

		setIsAddingSchool(true);

		/* Check input */
		let errMsg = null;
		if (!schoolName || schoolName.length === 0) {
			errMsg = getText('error', 'errEmptySchoolName');
		} else {
			if (
				schools.some((school) => {
					return (school.name && school.name.toLowerCase() === schoolName.toLowerCase());
				})
			) {
				errMsg = getText('error', 'errSchoolNameExists');
			} else {
				if (email && !checkIfValidEmail(email)) {
					errMsg = getText('error', 'errInvalidEmail');
				}
			}
		}

		if (errMsg) {
			/* Input not valid, show error message */
			setErrorMessage(errMsg);
			setIsAddingSchool(false);
		} else {
			/* Input valid, get valid facilitator emails */
			const facilitatorEmailsArrTrimmed = [];
			const sendWelcomeEmailTo = [];
			if (facilitatorEmails && facilitatorEmails.length > 0) {
				const facilitatorEmailsArr = facilitatorEmails.split(',');
				facilitatorEmailsArr.forEach((email) => {
					const emailTrimmed = email.trim();
					if (emailTrimmed.length > 0 && checkIfValidEmail(emailTrimmed.toLowerCase())) {
						facilitatorEmailsArrTrimmed.push(emailTrimmed.toLowerCase());

						const numberOfSchools = 
							getNumberOfSchoolsEmailIsFacilitatorOf(emailTrimmed.toLowerCase(), schools);
						if (
							numberOfSchools === 0 && 
							!users.some((user) => {return user.email === emailTrimmed.toLowerCase();})
						) {
							/* User does not exist, send welcome e-mail */
							sendWelcomeEmailTo.push(emailTrimmed.toLowerCase());
						}
					}
				});
			}
			apiHelper('schools/create-new-school', {
				name: schoolName,
				contactPerson: (contactPerson ? contactPerson : null),
				email: (email ? email : null),
				facilitatorEmails: facilitatorEmailsArrTrimmed,
				sendWelcomeEmailTo,
				tagIds: (tagIds ? tagIds : [])
			}).then((response) => {
				if (response.status === 'success') {
					setNewestSchoolAddition(response.schoolId);
					setShowAddSchoolPopup(false);
				} else {
					console.error(response.error);
					setIsAddingSchool(false);
					setErrorMessage(getText('error', 'unknownError'));
				}
			}).catch((error) => {
				console.error(error);
				setIsAddingSchool(false);
				setErrorMessage(getText('error', 'unknownError'));
			});
		}
	};

	/**
	 * Add school tag
	 * @param {string} tag 
	 * @param {bool} isNew 
	 */
	const addTag = (tag, isNew) => {
		if (isEditingTags) return;

		setIsEditingTags(true);
		setErrorMessage(null);
		const newTagIds = JSON.parse(JSON.stringify(tagIds));

		if (!isNew) {
			/* Tag already exists in database, just add tag id to school */
			newTagIds.push(tag);
			setTagIds(newTagIds);
			setIsEditingTags(false);
		} else {
			/* New tag, generate unique tag id */
			const existingTagIds = tagsData.map((tag) => {return tag.id;});
			generateUniqueIdFromString(tag, existingTagIds).then((response) => {
				/* Add tag to database */
				const db = firebase.firestore();
				db.collection(appConfig.tagsDbName).doc(response.id).set({value: tag.trim()}).then(() => {
					/* Add tag to school */
					newTagIds.push(response.id);
					setTagIds(newTagIds);
					setIsEditingTags(false);
				}).catch((error) => {
					/* Error: could not add tag do database */
					console.error(error);
					setErrorMessage(getText('error', 'unknownError'));
					setIsEditingTags(false);
				});
			});
		}		
	};

	/**
	 * Remove school tag
	 * @param {string} tagId 
	 */
	const removeTag = (tagId) => {
		if (isEditingTags) return;

		setIsEditingTags(true);
		setErrorMessage(null);
		const newTagIds = JSON.parse(JSON.stringify(tagIds));
		const tagIndex = newTagIds.indexOf(tagId);
		if (tagIndex >= 0) {
			/* Remove tag from school */
			newTagIds.splice(tagIndex, 1);
			setTagIds(newTagIds);
			setIsEditingTags(false);

			const tagIsInUse = checkIfTagIsInUse(tagId, schools);
			if (!tagIsInUse) {
				/* Tag is not in use by any school, delete from tag database */
				const db = firebase.firestore();
				db.collection(appConfig.tagsDbName).doc(tagId).delete().catch((error) => {
					console.error(error);
				});
			} 
		} else {
			setIsEditingTags(false);
		}
	};

	/* Tag options */
	const tagOptions = tagsData.filter((tag) => {
		return (tagIds && !tagIds.includes(tag.id));
	});

	return (
		<div className='PopupAddSchool'>
			<div className='PopupAddSchool-content'>
				{/* Header */}
				<div className='PopupAddSchool-header'>
					<span>{getText('adminTexts', 'addSchool')}</span>
				</div>

				{/* Body */}
				<div className='PopupAddSchool-body'>

					{/* School name */}
					<div className='PopupAddSchool-inputLabel'>
						<span>{getText('adminTexts', 'name')}</span>
					</div>
					<div className='PopupAddSchool-input'>
						<input
							className='Admin-input'
							name="schoolName"
							type="text"
							placeholder={''}
							value={schoolName}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>

					{/* Contact person */}
					<div className='PopupAddSchool-inputLabel'>
						<span>{getText('adminTexts', 'contactPerson')}</span>
					</div>
					<div className='PopupAddSchool-input'>
						<input
							name="contactPerson"
							type="text"
							placeholder={''}
							value={contactPerson}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>

					{/* Contact e-mail */}
					<div className='PopupAddSchool-inputLabel'>
						<span>{getText('adminTexts', 'contactEmail')}</span>
					</div>
					<div className='PopupAddSchool-input'>
						<input
							name="email"
							type="text"
							placeholder={''}
							value={email}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>

					{/* Facilitators */}
					<div className="PopupAddSchool-inputLabel">
						<span>{getText('adminTexts', 'facilitators')}</span>
					</div>
					<div className="PopupAddSchool-inputLabel small">
						<span>{getText('adminTexts', 'useCommaToAddMultipleFacilitators')}.</span>
					</div>
					<div className='PopupAddSchool-input'>
						<input
							name="facilitatorEmails"
							type="text"
							placeholder={''}
							value={facilitatorEmails}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>

					{/* Tags */}
					<div className='PopupAddSchool-inputLabel'>
						<span>{getText('adminTexts', 'tags')}</span>
					</div>
					<div className='PopupAddSchool-tag'>
						<Select 
							isDisabled={false}
							canSearch={true} 
							canAddOption={true}
							type="tags" 
							placeholderText={getText('adminTexts', 'typeToAddTag')}
							noMatchText={getText('adminTexts', 'noMatchesPressEnter')}
							options={tagOptions}
							onSelect={addTag}
						/>
						{tagIds && <div className="PopupAddSchool-tags">
							{tagIds.map((tagId, index) => {
								const tagData = tagsData.find((tag) => {return tag.id === tagId;});
								if (!tagData) return null;
								return (
									<div key={index} className="PopupAddSchool-tag">
										<div className="PopupAddSchool-tagValue">
											<span>{tagData.value}</span>
										</div>
										<div 
											className="PopupAddSchool-removeTagBtn"
											onClick={() => {removeTag(tagId);}}
										/>
									</div>
								);
							})}
						</div>} 
					</div>


					{/* Error msg */}
					<div className="PopupAddSchool-errMsg">
						<span>{errorMessage}</span>
					</div>

					<div className='PopupAddSchool-buttons'>
						<div className='PopupAddSchool-button'>
							<Button
								isLoading={isAddingSchool}
								isDisabled={isAddingSchool}
								text={getText('adminTexts', 'cancel')}
								classes={['shadow', 'white', 'blueBorder', 'responsive']}
								onClick={() => {setShowAddSchoolPopup(false);}}
							/>
						</div>
						<div className='PopupAddSchool-button'>
							<Button
								isLoading={isAddingSchool}
								isDisabled={isAddingSchool}
								text={getText('adminTexts', 'continue')}
								classes={['shadow', 'blue', 'responsive']}
								onClick={() => {handleAddSchool();}}
							/>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
};

PopupAddSchool.propTypes = {
	schools: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	setShowAddSchoolPopup: PropTypes.func.isRequired,
	setNewestSchoolAddition: PropTypes.func.isRequired,
};

export default PopupAddSchool; 