import MultipleChoice from 'components/modules/multiple-choice/multiple-choice';
import Slider from 'components/modules/slider/slider';
import Order from 'components/modules/order/order';
import Sort from 'components/modules/sort/sort';
import ResultsCareers from 'components/modules/results-careers/results-careers';
import ResultsNextStep from 'components/modules/results-next-step/results-next-step';
import ResultsKeywords from 'components/modules/results-keywords/results-keywords';
import ResultsKeywordsAll from 'components/modules/results-keywords-all/results-keywords-all';
import ResultsOverview from 'components/modules/results-overview/results-overview';
import CalculateResults from 'components/ui/calculate-result/calculate-result';
import {modulesData} from 'data/modules-data';
import {getText} from './text-helper';
import appConfig from 'config/app.config';
import { module1Tasks } from 'data/module-1-tasks-data';
import { sortArrayByProperty } from './array-helper';

/**
 * Get current module and task id
 * @param {object} playerData 
 * @returns 
 */
export function getCurrentModuleAndTaskId(playerData) {
	let moduleId = null;
	let taskId = null;

	/* Get current module id and data */
	moduleId = (playerData.currentModuleId 
		? playerData.currentModuleId
		: modulesData[0].id
	);
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	

	/* Get current task */
	if (moduleData && moduleData.tasks) {
		taskId = (playerData.currentTaskId 
			? playerData.currentTaskId
			: moduleData.tasks[0].id
		);
	}

	return {moduleId, taskId};
}
/**
 * Gets module type based on string id. Prints error if type does not exist.
 * @param {string} type 
 * @returns module object, or null if type does not exist
 */
export function getTaskComponentFromType(type) {
	let taskModule = null;
	switch (type) {
	case 'multiple-choice':
		taskModule = MultipleChoice;
		break;
	case 'order':
		taskModule = Order;
		break;
	case 'sort':
		taskModule = Sort;
		break;
	case 'slider':
		taskModule = Slider;
		break;
	case 'calculate-result':
		taskModule = CalculateResults;
		break;
	case 'results-keywords':
		taskModule = ResultsKeywords;
		break;
	case 'results-keywords-all':
		taskModule = ResultsKeywordsAll;
		break;
	case 'results-careers':
		taskModule = ResultsCareers;
		break;
	case 'results-next-step':
		taskModule = ResultsNextStep;
		break;
	case 'results-overview':
		taskModule = ResultsOverview;
		break;
	default:
		console.error(getText('error', 'unknownTaskType') + ' ' + type);
	}

	return taskModule;
};

/**
 * Get module data from id
 * @param {string} moduleId 
 * @returns moduleData object or null
 */
export function getModuleFromId(moduleId) {
	let moduleData = null;
	if (modulesData.some((m) => {return m.id === moduleId;})) {
		moduleData = modulesData.find((m) => {return m.id === moduleId;});
	}

	return moduleData;
}

/**
 * Gets task from taskData, from id
 * @param {string} taskId 
 * @returns taskData object or null
 */
export function getTaskFromId(moduleId, taskId) {
	let taskData = null;

	const moduleData = getModuleFromId(moduleId);
	if (
		moduleData && 
		moduleData.tasks &&
		moduleData.tasks.some((t) => {return t.id === taskId;})
	) {
		taskData = moduleData.tasks.find((t) => {return t.id === taskId;});
	}

	return taskData;
};

/**
 * Gets the next module and task if such a task exists
 * @param {string} currentModuleId 
 * @param {string} currentTaskId 
 * @returns taskdata object for next task, or null if currentTask does not exist or there are no next task.
 */
export function getNextModuleAndTaskId(currentModuleId, currentTaskId) {
	/* Default values are current values */
	let nextModuleId = currentModuleId;
	let nextTaskId = currentTaskId;

	const moduleData = getModuleFromId(currentModuleId);
	if (moduleData && moduleData.tasks) {
		const currentTaskIndex = moduleData.tasks.findIndex((t) => {
			return t.id === currentTaskId;
		});	
		if (currentTaskIndex >= 0) {
			if (moduleData.tasks.length > currentTaskIndex + 1) {
				/* Next task is in current module */
				nextTaskId = moduleData.tasks[currentTaskIndex + 1].id;
			} else {
				/* Current task is last task of current module, check if there is a next module */
				const currentModuleIndex = modulesData.findIndex((m) => {
					return m.id === currentModuleId;
				});
				// TODO: go to module 2 if extra questions are needed
				if (currentModuleIndex >= 0 && modulesData.length > currentModuleIndex + 1) {
					/* Next task is first task in next module */
					const nextModuleData = modulesData[currentModuleIndex + 1];
					nextModuleId = nextModuleData.id;
					nextTaskId = (nextModuleData.tasks && nextModuleData.tasks.length > 0
						? nextModuleData.tasks[0].id
						: null
					);
				}
			}
		}
	}

	return {nextModuleId, nextTaskId};
};

/**
 * Gets the previous module and task if such a task exists
 * @param {string} currentModuleId 
 * @param {string} currentTaskId 
 * @returns taskdata object for previous task, or null if currentTask does not exist or there are no previous task.
 */
export function getPreviousModuleAndTaskId(currentModuleId, currentTaskId) {
	/* Default values are current values */
	let previousModuleId = currentModuleId;
	let previousTaskId = currentTaskId;

	let moduleData = getModuleFromId(currentModuleId);
	if (moduleData && moduleData.tasks) {
		const currentTaskIndex = moduleData.tasks.findIndex((t) => {
			return t.id === currentTaskId;
		});	
		if (currentTaskIndex >= 0) {
			if (currentTaskIndex - 1 >= 0) {
				/* previous task is in current module */
				previousTaskId = moduleData.tasks[currentTaskIndex - 1].id;
			} else {
				/* Current task is first task of current module*/
				const currentModuleIndex = modulesData.findIndex((m) => {
					return m.id === currentModuleId;
				});

				// check if there is a previous module
				if (currentModuleIndex > 0) {
					/* previous task is last task in previous module */
					const previousModuleData = modulesData[currentModuleIndex - 1];
					previousModuleId = previousModuleData.id;
					previousTaskId = (previousModuleData.tasks && previousModuleData.tasks.length > 0
						? previousModuleData.tasks[previousModuleData.tasks.length - 1].id
						: null
					);
				}
			}
		}
	}

	return {previousModuleId, previousTaskId};
};


/**
 * Check if current task is last task in last module
 * @param {string} currentModuleId 
 * @param {string} currentTaskId 
 * @returns 
 */
export function checkIfLastTaskInLastModule(currentModuleId, currentTaskId) {
	let isLastTaskInLastModule = false;
	if (currentModuleId === modulesData[modulesData.length - 1].id) {
		/* Current module is last module, check current task */
		const moduleData = getModuleFromId(currentModuleId);
		if (moduleData && moduleData.tasks) {
			if (currentTaskId === moduleData.tasks[moduleData.tasks.length - 1].id) {
				/* Current task is last task */
				isLastTaskInLastModule = true;
			}
		}
	}

	return isLastTaskInLastModule;
};

/**
 * Steps through effects and checks whether item satisfies any possible conditions
 * @param {array} effects 
 * @param {object} item 
 * @returns 
 */
export function getAppliedEffects(effects, item) {
	let appliedEffects = [];

	effects.forEach((effect) => {
		// If no conditions, add to array
		if (!effect.conditions) {
			appliedEffects.push(effect);
			return;
		}

		/* Check each conditions */
		let conditionsAreMet = true;
		effect.conditions.forEach((condition) => {
			switch (condition.type) {
			case 'containerId': 
				if (condition.containerId !== item.containerId) {
					conditionsAreMet = false;
				}
				break;
			case 'maxPositionNumber':
				if (item.positionNumber > condition.maxPositionNumber) {
					conditionsAreMet = false;
				}
				break;
			case 'valueRange':
				if (item.value < condition.minValue || item.value > condition.maxValue) {
					conditionsAreMet = false;
				}
				break;
			default:
				conditionsAreMet = false; 
				console.error(getText('error', 'unknownConditionType') + ' '  + condition.type);
				break;
			}
		});

		/* Add to applied effects if all conditions are met */
		if (conditionsAreMet) {
			appliedEffects.push(effect);
		}
	});
	
	return appliedEffects;
}

/**
 * Get number of miliseconds since last activity timestamp
 * @param {number} timestamp 
 * @returns 
 */
export function getTimeSinceLastActivity(timestamp) {
	/* Get difference in miliseconds */
	const seconds = Date.now() - timestamp;

	/* Return differnce (max value is inactivity limit) */
	return Math.min(seconds, appConfig.inactivityLimitSeconds * 1000);
};

/**
 * Gets data for all tasks
 * @returns list of all tasks
 */
export function getAllTasksData() {
	return module1Tasks;
}

/**
 * Gets effects for multiple choice
 * @param {object} taskData 
 * @param {array} selectedOptions 
 * @returns 
 */
export function getMultipleChoiceEffects(taskData, selectedOptions) {
	let effects = [];
	selectedOptions.forEach((optionId) => {
		const optionData = (taskData.options 
			? taskData.options.find((o) => {return o.id === optionId;})
			: 0
		);
		
		if (optionData && optionData.effects.length > 0) {
			optionData.effects.forEach((effect) => {
				effects.push(effect);
			});
		}
	});

	return effects;
}

/**
 * Gets effects for order
 * @param {object} taskData 
 * @param {array} orderedItems 
 * @returns 
 */
export function getOrderEffects(taskData, orderedItems) {
	/* Get effects */
	let effects = [];
	const sortedOrderedItems = sortArrayByProperty(orderedItems, 'positionNumber', 'ASC');
	sortedOrderedItems.forEach((item) => {
		const itemData = taskData.items.find((i) => {return i.id === item.id;});
		if (itemData.effects && itemData.effects.length > 0) {
			const appliedEffects = getAppliedEffects(itemData.effects, item);
			if (appliedEffects.length > 0) {
				effects.push(...appliedEffects);
			}
		}
	});

	return effects;
}

/**
 * Gets effects for sort
 * @param {object} taskData 
 * @param {array} sortedItems 
 * @returns 
 */
export function getSortEffects(taskData, sortedItems) {
	const taskItems = taskData.items;
	let effects = [];
	sortedItems.forEach((item) => {
		// Get itemData from task items.
		const itemData = taskItems.find((taskItem) => {
			return taskItem.id === item.itemId;
		});

		// If itemData has any effects, find whether any corresponds to the chosen container
		if (itemData && itemData.effects.length > 0) {
			const itemEffects = itemData.effects;
			let containerEffects = getAppliedEffects(itemEffects, item);

			if (containerEffects && containerEffects.length > 0) {
				effects.push(...containerEffects);
			}
		}
	});

	return effects;
}

/**
 * Gets effects for slider
 * @param {object} taskData 
 * @param {number} currentAnswerPercentage 
 * @returns 
 */
export function getSliderEffects(taskData, currentAnswerPercentage) {
	let effects = [];
	if (taskData.effects && taskData.effects.length > 0) {
		effects = getAppliedEffects(taskData.effects, {value: Math.round(currentAnswerPercentage)});
	}
	return effects;
}