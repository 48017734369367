import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {
	getSchoolClassesAndYears,
	getBasicResults, 
	calculateNextStepStats, 
	calculateCareerStats, 
	calculateKeywordStats
} from 'helpers/stats-helper';
import {getText} from 'helpers/text-helper';
import FacilitatorFilters from './facilitator-filters/facilitator-filters';
import StatsTable from 'components/ui/stats-table/stats-table';
import Button from 'components/ui/button/button';
import PopupAddFacilitator from './popup-add-facilitator/popup-add-facilitator';
import SchoolDocument from 'components/pdf-styles/school-pdf';
import './facilitator.scss';

const Facilitator = ({schoolId, schoolStats, schools, users, handleSelectSchool, updateSchool, handleLogout, }) => {
	/* Classes & years */
	const [schoolClasses, years,] = getSchoolClassesAndYears(schoolStats);

	/* Filters */
	const [includedYears, setIncludedYears] = useState(years);
	const [includedSchoolClasses, setIncludedSchoolClasses] = useState(schoolClasses);

	/* Add facilitator popup */
	const [showAddFacilitatorPopup, setShowAddFacilitatorPopup] = useState(false);

	/* School data */
	let schoolData = null;
	if (schoolId && schools) {
		schoolData = schools.find((s) => {return s.id === schoolId;});
	}

	/* Diploma download file name */
	const diplomaFileName = (schoolData
		? schoolData.name.replace(/\./g, '').replace(/\s/g, '_')
		: null
	);
	
	/* Stats */
	const startedGames = [];
	const completedGames = [];
	if (schoolStats) {
		schoolStats.forEach((schoolStat) => {
			const year = (schoolStat.created && schoolStat.created.length === 7 
				? schoolStat.created.slice(0, 4) 
				: null
			);
			if (includedSchoolClasses.indexOf(schoolStat.schoolClass) >= 0 && includedYears.indexOf(year) >= 0) {
				if (!schoolStat.results) {
					startedGames.push(schoolStat);
				} else {
					completedGames.push(schoolStat);
				}
			}
		});
	}

	const basicResults = getBasicResults(completedGames);
	const keywordStats = calculateKeywordStats(basicResults);
	const nextStepStats = calculateNextStepStats(basicResults);
	const careerPathStats = calculateCareerStats(basicResults);

	/**
	 * Toggle filter on/off
	 * @param {string} type 
	 * @param {string} value 
	 */
	const toggleFilter = (type, value) => {
		if (type === 'year') {
			const newIncludedYears = JSON.parse(JSON.stringify(includedYears));
			const yearIndex = newIncludedYears.indexOf(value);
			if (yearIndex < 0) {
				/* Include year */
				newIncludedYears.push(value);
			} else {
				/* Remove year */
				newIncludedYears.splice(yearIndex, 1);
			}
			setIncludedYears(newIncludedYears);
		} else if (type === 'schoolClass') {
			const newIncludedSchoolClasses = JSON.parse(JSON.stringify(includedSchoolClasses));
			const schoolClassIndex = newIncludedSchoolClasses.indexOf(value);
			if (schoolClassIndex < 0) {
				/* Include school class */
				newIncludedSchoolClasses.push(value);
			} else {
				/* Remove school class */
				newIncludedSchoolClasses.splice(schoolClassIndex, 1);
			}
			setIncludedSchoolClasses(newIncludedSchoolClasses);
		}
	};

	
	return (
		<div className='Facilitator'>
			{/* Add facilitator btn */}
			{schools.length > 0 && <div className="Facilitator-addFacilitatorBtn">
				<Button 
					text={getText('adminTexts', 'addFacilitator')}
					classes={['responsive', 'white', 'rounded', 'bold']}
					onClick={() => {setShowAddFacilitatorPopup(true);}}
				/>
			</div>}

			{/* List of schools the facilitator is connected to */}
			<div className="Facilitator-schools">
				<div className="Facilitator-schoolsTitle"><span>{getText('statsTexts', 'schools')}</span></div>
				{schools.map((school) => {
					const isSelected = (school.id === schoolId);
					return (
						<div 
							key={school.id}
							className={'Facilitator-school' + (isSelected ? ' selected' : '')}
							onClick={() => {if (!isSelected) handleSelectSchool(school.id);}}
						>
							<span>{school.name}</span>
						</div>
					);
				})}
				{schools.length === 0 && 
					<div className="Facilitator-noSchoolsText"><span>{getText('statsTexts', 'noSchools')}</span>
					</div>}
			</div>

			{(schoolId && schools.length > 0) && <div className="Facilitator-wrap">
				{/* Filters */}
				<div className="Facilitator-filters">
					<FacilitatorFilters 
						schoolClasses={schoolClasses}
						includedSchoolClasses={includedSchoolClasses}
						years={years}
						includedYears={includedYears}
						toggleFilter={toggleFilter}
					/>
				</div>

				{/* School code, diploma, games started & completed  downloadPdfGamePassword */}
				{schoolData && <div className="Facilitator-schoolAndGamesInfo">
					{/* School password and diploma btn */}
					<div className="Facilitator-schoolInfo">
						<div className="Facilitator-schoolCode">
							<span>{getText('adminTexts', 'password')}:</span>
							<span>{schoolData && schoolData.password}</span>
						</div>
						<div className="Facilitator-downloadDiplomatBtn">
							<PDFDownloadLink
								document={
									<SchoolDocument 
										code={schoolData.password} 
										schoolName={schoolData.name}
									/>
								}
								fileName={diplomaFileName}
							>
								{({loading}) => {
									return (
										<Button
											text={getText('statsTexts', 'downloadPdfGamePassword')}
											isDisabled={loading}
											classes={['shadow', 'blue', 'responsive', 'fullWidth']}
											onClick={(e) => {e.stopPropagation();}}
										/>
									);
								}}
							</PDFDownloadLink>
						</div>
					</div>
					{/* Games started & completed */}
					<div className="Facilitator-gamesInfo">
						<div className="Facilitator-gamesStarted">
							<span>{getText('statsTexts', 'numberOfStartedGames')}:</span>
							<span>{startedGames.length}</span>
						</div>
						<div className="Facilitator-gamesCompleted">
							<span>{getText('statsTexts', 'numberOfCompletedGames')}:</span>
							<span>{completedGames.length}</span>
						</div>
					</div>
				</div>}
				
				{/* Stats */}
				{(nextStepStats && careerPathStats && keywordStats) &&
					<div className='Facilitator-stats'>
						<StatsTable 
							nextStepStats={nextStepStats} 
							careerPathStats={careerPathStats}
							keywordStats={keywordStats}
						/>
					</div>}
			</div>}
			
			{/* Logout button */}
			<div className='Facilitator-logoutButton'>
				<Button 
					text={getText('statsTexts', 'logout')}
					classes={['responsive', 'white', 'rounded', 'small', 'bold']}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{showAddFacilitatorPopup && 
				<PopupAddFacilitator 
					schools={schools}
					users={users}
					updateSchool={updateSchool}
					setShowAddFacilitatorPopup={setShowAddFacilitatorPopup}
				/>
			}
		</div>
	);
};

Facilitator.propTypes = {
	schoolId: PropTypes.string,
	schoolStats: PropTypes.array,
	schools: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	handleSelectSchool: PropTypes.func.isRequired,
	updateSchool: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Facilitator;