import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {calculateDataForKeywordCategory} from 'helpers/keywords-helpers';
import {getText} from 'helpers/text-helper';
import {keywordCategories} from 'data/keywords-data';
import Button from 'components/ui/button/button';
import './results-keywords-all.scss';

const ResultsKeywordsAll = ({taskData, keywordPoints, handleCompleteTask, goToPreviousTask}) => {
	/**
	 * Complete task
	 */
	const completeTask = () => {
		handleCompleteTask([], {});
	};

	return (
		<div className="ResultsKeywordsAll">
			{/* Title */}
			<div className="ResultsKeywordsAll-title"><span>{taskData.title}</span></div>

			{/* Keyword categories */}
			<div className="ResultsKeywordsAll-categories">
				{keywordCategories.map((category) => {
					/* Get results for keyword category */
					let keywordsResult = calculateDataForKeywordCategory(category.id, keywordPoints);
					if (!keywordsResult) return null;

					/* Limit max number of keywords displayed */
					if (keywordsResult.length > appConfig.shownKeywords) {
						keywordsResult.splice(appConfig.shownKeywords, keywordsResult.length);
					}

					return (
						<div key={category.id} className={'ResultsKeywordsAll-category ' + category.id}>
							<div className="ResultsKeywordsAll-categoryWrap">
								<div className="ResultsKeywordsAll-categoryTitle"><span>{category.name}</span></div>
								<div className="ResultsKeywordsAll-categoryKeywords">
									{keywordsResult.map((keyword, index) => {
										return (
											<div key={index} className={'ResultsKeywordsAll-keyword ' + category.id}>
												<span>{keyword.name}</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</div>

			{/* Back button */}
			<div className="ResultsKeywordsAll-backBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'back')}
					onClick={() => {goToPreviousTask();}}
				/>
			</div>
			
			{/* Continue button */}
			<div className="ResultsKeywordsAll-continueBtn">
				<Button
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'continue')}
					onClick={() => {completeTask();}}
				/>
			</div>
		</div>
	);
};

ResultsKeywordsAll.propTypes = {
	taskData: PropTypes.object.isRequired,
	keywordPoints: PropTypes.array.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
};

export default ResultsKeywordsAll;