import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import './admin-login.scss';

const AdminLogin = ({isLoggingIn, email, password, feedback, handleInput, handleLogin}) => {
	return (
		<div className="AdminLogin">
			<div className="AdminLogin-title"><span>{getText('adminTexts', 'title')}</span></div>
			<div className="AdminLogin-container">
				<form className="AdminLogin-form" onSubmit={handleLogin}>
					<input
						name="email"
						type="text"
						placeholder={getText('adminTexts', 'email')} 
						autoComplete="section-adminlogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={getText('adminTexts', 'password')}
						autoComplete="section-adminlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input 
						className={'Button ' + (isLoggingIn ? ' loading' : '')}
						type="submit"
						value={getText('adminTexts', 'login')} 
					/>
					<p className="AdminLogin-errorMessage"><span>{feedback}</span></p> 
				</form>			
			</div>
		</div>
	);
};

AdminLogin.propTypes = {
	isLoggingIn: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default AdminLogin;