const nextStepData = [
	{
		id: 'stx',
		title: 'STX',
		subtitle: 'Det almene gymnasium',
		careerIds: [],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'analytical'},
			{keywordId: 'variation'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
			{keywordId: 'writing'}
		],
		description: `STX er en 3-årig gymnasial uddannelse med en bred vifte af fag inden for samfundsvidenskab, naturvidenskab og humaniora.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/gymnasialeuddannelser/almen-studentereksamen-stx" target="_blank">uddannelsesguiden.dk</a>`
	},
	{
		id: 'hhx',
		title: 'HHX',
		subtitle: 'Handelsgymnasium',
		careerIds: [],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'math'},
			{keywordId: 'business'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
			{keywordId: 'officeWork'}
		],
		description: `HHX er en 3-årig gymnasial uddannelse. Den har især fag inden for økonomi og erhvervsforhold, men også sprog og andre almene fag.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/gymnasialeuddannelser/merkantil-studentereksamen-hhx" target="_blank">uddannelsesguiden.dk</a>`
	},
	{
		id: 'htx',
		title: 'HTX',
		subtitle: 'Teknisk gymnasium',
		careerIds: [],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'math'},
			{keywordId: 'technique'},
			{keywordId: 'computers'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
			{keywordId: 'variation'}
		],
		description: `HTX er en 3-årig gymnasial uddannelse med særligt fokus på tekniske og naturvidenskabelige fag.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/gymnasialeuddannelser/teknisk-studentereksamen-htx" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eud-technology',
		title: 'EUD',
		subtitle: 'Teknologi, byggeri og transport',
		careerIds: [],
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'drawing'},
			{keywordId: 'workingOutside'},
			{keywordId: 'building'},
			{keywordId: 'practicalWork'},
			{keywordId: 'creating'},
		],
		description: `Her finder du erhvervsuddannelser med fokus på håndværk, teknik og teknologi. Det kan handle om byggeri, industri, motorer og maskiner og it-systemer, men også om at arbejde med transport, lyd, tekst og billede.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/erhvervsuddannelser/teknologibyggeriogtransport" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eud-health',
		title: 'EUD',
		subtitle: 'Omsorg, sundhed og pædagogik',
		careerIds: [],
		keywords: [
			{keywordId: 'helping'},
			{keywordId: 'people'},
			{keywordId: 'health'},
			{keywordId: 'practicalWork'},
			{keywordId: 'social'},
		],
		description: `Her finder du erhvervsuddannelser, der uddanner dig i at tage dig af andre mennesker, både børn, unge og ældre.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/erhvervsuddannelser/omsorgsundhedogpaedagogik" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eud-office',
		title: 'EUD',
		subtitle: 'Kontor, handel og forretningsservice',
		careerIds: [],
		keywords: [
			{keywordId: 'systematic'},
			{keywordId: 'business'},
			{keywordId: 'officeWork'},
			{keywordId: 'routines'},
		],
		description: `Her finder du erhvervsuddannelser, der lærer dig om økonomi, salg, administration, kundeservice, kommunikation og planlægning af fx events.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/erhvervsuddannelser/kontorhandelogforretning" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eud-food',
		title: 'EUD',
		subtitle: 'Fødevarer, jordbrug og oplevelser',
		careerIds: [],
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'nature'},
			{keywordId: 'cooking'},
			{keywordId: 'practicalWork'},
			{keywordId: 'workingOutside'},
			{keywordId: 'creating'}
		],
		description: `Her finder du erhvervsuddannelser, der lærer dig at producere fødevarer, lave mad, arbejde med dyrehold og planter eller naturpleje.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/erhvervsuddannelser/foedevarerjordbrugogoplevelser" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eux-technology',
		title: 'EUX',
		subtitle: 'Teknologi, byggeri og transport',
		careerIds: [],
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'drawing'},
			{keywordId: 'workingOutside'},
			{keywordId: 'building'},
			{keywordId: 'practicalWork'},
			{keywordId: 'creating'},
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'manyOpportunities'},
		],
		description: `Her finder du erhvervsuddannelser med fokus på håndværk, teknik og teknologi. Det kan handle om byggeri, industri, motorer og maskiner og it-systemer, men også om at arbejde med transport, lyd, tekst og billede.
		<br /><br />Med en EUX tager du både en erhvervsuddannelse og en gymnasial eksamen og bliver altså både faglært og student på samme tid.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/artikleromuddannelser/omerhvervsuddannelser/eux" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eux-health',
		title: 'EUX',
		subtitle: 'Omsorg, sundhed og pædagogik',
		careerIds: [],
		keywords: [
			{keywordId: 'helping'},
			{keywordId: 'people'},
			{keywordId: 'health'},
			{keywordId: 'practicalWork'},
			{keywordId: 'social'},
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'manyOpportunities'},
		],
		description: `Her finder du erhvervsuddannelser, der uddanner dig i at tage dig af andre mennesker, både børn, unge og ældre.
		<br /><br />Med en EUX tager du både en erhvervsuddannelse og en gymnasial eksamen og bliver altså både faglært og student på samme tid.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/artikleromuddannelser/omerhvervsuddannelser/eux" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eux-office',
		title: 'EUX',
		subtitle: 'Kontor, handel og forretningsservice',
		careerIds: [],
		keywords: [
			{keywordId: 'systematic'},
			{keywordId: 'business'},
			{keywordId: 'officeWork'},
			{keywordId: 'routines'},
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'manyOpportunities'},
		],
		description: `Her finder du erhvervsuddannelser, der lærer dig om økonomi, salg, administration, kundeservice, kommunikation og planlægning af fx events.
		<br /><br />Med en EUX tager du både en erhvervsuddannelse og en gymnasial eksamen og bliver altså både faglært og student på samme tid.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/artikleromuddannelser/omerhvervsuddannelser/eux" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'eux-food',
		title: 'EUX',
		subtitle: 'Fødevarer, jordbrug og oplevelser',
		careerIds: [],
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'nature'},
			{keywordId: 'cooking'},
			{keywordId: 'practicalWork'},
			{keywordId: 'workingOutside'},
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'manyOpportunities'},
		],
		description: `Her finder du erhvervsuddannelser, der lærer dig at producere fødevarer, lave mad, arbejde med dyrehold og planter eller naturpleje.
		<br /><br />Med en EUX tager du både en erhvervsuddannelse og en gymnasial eksamen og bliver altså både faglært og student på samme tid.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/artikleromuddannelser/omerhvervsuddannelser/eux" target="_blank">uddannelsesguiden.dk</a>`,
	},
	{
		id: 'hf',
		title: 'HF',
		subtitle: '2-årig gymnasial uddannelse',
		careerIds: [],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'reading'},
			{keywordId: 'analytical'},
			{keywordId: 'variation'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
			{keywordId: 'writing'},
			{keywordId: 'independent'},
			{keywordId: 'shortEducation'}
		],
		description: `HF er en 2-årig gymnasial uddannelse målrettet erhvervsakademi- og professionsbacheloruddannelser. Uddannelsen kan også give adgang til at læse videre på universitetet.
		<br /><br />Se mere på <a href="https://www.ug.dk/uddannelser/gymnasialeuddannelser/hf-eksamen-hf" target="_blank">uddannelsesguiden.dk</a>`,
	},
];

export {
	nextStepData,
};