import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getText, renderMarkdown } from 'helpers/text-helper';
import Button from '../button/button';
import './cookie-banner.scss';

const CookieBanner = ({toggleAcceptCookies}) => {

	/* Hide / show more info */
	const [showMoreInfo, setShowMoreInfo] = useState(false);

	return (
		<div className='CookieBanner'>
			<div className='CookieBanner-content'>
				<div className='CookieBanner-cookieImage'/>
				<div className='CookieBanner-cookieTextWrapper'>
					<div className='CookieBanner-cookieText'>
						{renderMarkdown(getText('cookieUi', 'text1'))}
						{showMoreInfo && renderMarkdown(getText('cookieUi', 'text2'))}
					</div>
					<div className='CookieBanner-moreInfoLink'onClick={() => {setShowMoreInfo(!showMoreInfo);}}>
						<span>
							{showMoreInfo ? getText('cookieUi', 'seeLess') : getText('cookieUi', 'seeMore')}
						</span>
					</div>
				</div>
				<div className='CookieBanner-buttonWrapper'>
					<div className="CookieBanner-button no">
						<Button 
							text={getText('cookieUi', 'no')} 
							classes={['white', 'blueText', 'blueBorder', 'rounded', 'responsive', 'shadow']} 
							onClick={() => {toggleAcceptCookies(false);}} 
						/>
					</div>
					<div className="CookieBanner-button yes">
						<Button 
							text={getText('cookieUi', 'yes')} 
							classes={['blue', 'rounded', 'responsive', 'shadow']} 
							onClick={() => {toggleAcceptCookies(true);}} 
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

CookieBanner.propTypes = {
	toggleAcceptCookies: PropTypes.func.isRequired,
};

export default CookieBanner;