import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './facilitator-create-user.scss';

const FacilitatorCreateUser = (props) => {
	const {
		isCreatingUser,
		userIsCreated,
		email,
		password,
		passwordRepeat,
		feedback,
		handleEmailInput,
		handlePasswordInput,
		handlePasswordRepeatInput,
		createUser,
		goToPage
	} = props;

	if (userIsCreated) {
		return (
			<div className="FacilitatorCreateUser">
				<div className="FacilitatorCreateUser-content">
					<div className="FacilitatorCreateUser-header">
						<div className="FacilitatorCreateUser-title">
							<span>{getText('statsTexts', 'createUser')}</span>
						</div>
					</div>
					<div className="FacilitatorCreateUser-body">
						<div className="FacilitatorCreateUser-text">
							<span>{getText('adminTexts', 'createUserFeedback')}</span>
						</div>
						<div className="FacilitatorCreateUser-goToPageBtn">
							<Button
								text={getText('adminTexts', 'backToLogin')}
								classes={['smallFontSize', 'darkBlueText', 'responsive']}
								onClick={() => {goToPage('login');}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="FacilitatorCreateUser">
			<div className="FacilitatorCreateUser-content">
				<div className="FacilitatorCreateUser-header">
					<div className="FacilitatorCreateUser-title">
						<span>{getText('statsTexts', 'createUser')}</span>
					</div>
				</div>
				<div className="FacilitatorCreateUser-body">
					<form className="FacilitatorCreateUser-form" onSubmit={createUser}>
						<div className='FacilitatorCreateUser-inputWrapper'>
							<span>{getText('statsTexts', 'email')}</span>
							<input
								className={'FacilitatorCreateUser-input'}
								name="email"
								type="text"
								placeholder={getText('statsTexts', 'email')}
								autoComplete="section-createUser email"
								value={email ? email : ''}
								onChange={(event)=>{handleEmailInput(event);}}
							/>
						</div>
						<div className='FacilitatorCreateUser-inputWrapper'>
							<span>{getText('statsTexts', 'password')}</span>
							<input
								className={'FacilitatorCreateUser-input'}
								name="password"
								type="password"
								placeholder={getText('statsTexts', 'password')}
								autoComplete="section-createUser password"
								value={password ? password : ''}
								onChange={(event)=>{handlePasswordInput(event);}}
							/>
						</div>
						<div className='FacilitatorCreateUser-inputWrapper'>
							<span>{getText('statsTexts', 'repeatPassword')}</span>
							<input
								className={'FacilitatorCreateUser-input'}
								name="passwordRepeat"
								type="password"
								placeholder={getText('statsTexts', 'repeatPassword')}
								autoComplete="section-createUser repeatpassword"
								value={passwordRepeat ? passwordRepeat : ''}
								onChange={(event)=>{handlePasswordRepeatInput(event);}}
							/>
						</div>
						<div className="FacilitatorCreateUser-confirmBtn">
							<Button
								isLoading={isCreatingUser}
								classes={['shadow', 'blue', 'blueBorder', 'responsive']}
								text={getText('statsTexts', 'createUser')}
								onClick={createUser}
							/>
						</div>
						<p className="FacilitatorCreateUser-errorMessage">
							<span>{feedback}</span>
						</p>
					</form>
					<div className="FacilitatorCreateUser-goToPageBtn">
						<Button
							text={getText('adminTexts', 'backToLogin')}
							classes={['smallFontSize', 'darkBlueText', 'responsive']}
							onClick={() => {goToPage('login');}}
						/>
					</div>
				</div>
			</div>
		</div>
	);	
};

FacilitatorCreateUser.propTypes = {
	isCreatingUser: PropTypes.bool.isRequired,
	userIsCreated: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleEmailInput: PropTypes.func.isRequired,
	handlePasswordInput: PropTypes.func.isRequired,
	handlePasswordRepeatInput: PropTypes.func.isRequired,
	createUser: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default FacilitatorCreateUser;