import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {checkIfValidEmail} from 'helpers/format-helper';
import {getText} from 'helpers/text-helper';
import FacilitatorLogin from './facilitator-login';
import FacilitatorController from '../facilitator-controller';
import FacilitatorPasswordReset from './facilitator-password-reset';
import FacilitatorCreateUser from './facilitator-create-user';

class FacilitatorLoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoggingIn: false,
			isLoggedIn: false,
			isResettingPassword: false,
			passwordIsReset: false,
			isCreatingUser: false,
			userIsCreated: false,
			page: 'login', // login, create-user, reset-password, 
			email: null,
			password: null,
			passwordRepeat: null,
			feedback: null,
			userData: null,
			schoolId: null,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}
	
	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if logged in
	 */
	checkIfLoggedIn = () => {
		/* Unsubscribe previous onAuthStateChanged */
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
		
		/* Subscribe to onAuthStateChanged */
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* User is logged in, get user data */
				const db = firebase.firestore();
				db.collection(appConfig.usersDbName).doc(user.uid).get().then((doc) => {
					if (doc.exists) {
						this.setState({
							isCheckingLogin: false,
							isLoggedIn: true,
							password: null,
							userData: {
								userId: user.uid,
								email: user.email,
								role: user.role
							}
						});
					} else {
						/* No user data */
						this.handleLogout();
					}
				});
			} else {
				/* Not logged in or invalid login*/
				this.setState({isCheckingLogin: false, isLoggedIn: false, userData: null});
			}
		});
	};

	/**
	 * Go to login page
	 * @param {string} page 
	 */
	goToPage = (page) => {
		if (this.state.isLoggingIn || this.state.isResettingPassword || this.state.isCreatingUser) return;

		this.setState({
			page, 
			feedback: null, 
			email: null, 
			password: null, 
			passwordRepeat: null,
			passwordIsReset: false,
			userIsCreated: false
		});
	};

	
	/**
	 * Update email input field
	 * @param {obj} event 
	 */
	handleEmailInput = (event) => {
		const value = event.target.value;
		this.setState({email: value, feedback: null});
	};

	/**
	 * Update password input field
	 * @param {obj} event 
	 */
	handlePasswordInput = (event) => {
		const value = event.target.value;
		this.setState({password: value, feedback: null});
	};

	/**
	 * Update repeat password input field
	 * @param {obj} event 
	 */
	handlePasswordRepeatInput = (event) => {
		const value = event.target.value;
		this.setState({passwordRepeat: value, feedback: null});
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();

		if (this.state.isLoggingIn) return;

		this.setState({isLoggingIn: true, feedback: null}, () => {
			/* Check input */
			let newFeedback = null;
			if (!this.state.email || !this.state.password) {
				newFeedback = getText('error', 'missingFields');
			}

			if (newFeedback) {
				/* Invalid input */
				this.setState({isLoggingIn: false, feedback: newFeedback});
			} else {
				firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch((error) => {
					let errorMsg = getText('error', 'unknownError');
					if (error.code === 'auth/invalid-email') errorMsg = getText('error', 'invalidEmail');
					if (error.code === 'auth/user-not-found') errorMsg = getText('error', 'userNotFound');
					if (error.code === 'auth/wrong-password') errorMsg = getText('error', 'invalidPassword');
					this.setState({feedback: errorMsg, isLoggingIn: false});
				});
			}
		});
	};

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({isLoggedIn: false, isLoggingIn: false, userData: null});
	};

	/**
	 * Create a new user
	 * @param {object} event 
	 * @returns 
	 */
	createUser = (event) => {
		if (event) event.preventDefault();

		if (this.state.isCreatingUser) return;

		this.setState({isCreatingUser: true, feedback: null}, () => {
			let email = (this.state.email ? this.state.email.toLowerCase() : null);
			let password = this.state.password;
			let passwordRepeat = this.state.passwordRepeat;
			let feedback = null;
	
			/* Check for empty fields */
			if (!email || !password || !passwordRepeat) {
				feedback = getText('error', 'missingFields');
			} else if (!checkIfValidEmail(email)) {
				/* Check valid e-mail */
				feedback = getText('error', 'invalidEmail');
			} else {
				/* Check if passwords match */
				if (password && password.length < 8) {
					feedback = getText('error', 'shortPassword');
				} else if (password && password !== passwordRepeat) {
					feedback = getText('error', 'noPasswordMatch');
				}
			}
	
			if (feedback) {
				/* Error feedback */
				this.setState({isCreatingUser: false, feedback});
			} else {
				/* Create user */
				apiHelper('schools/create-new-user', {email, password}).then(
					(response)=>{
						if (response.status === 'success') {
							/* Success: user created */
							this.setState({userIsCreated: true, isCreatingUser: false});
						} else {
							/* Error: user not created */
							let feedback = getText('error', 'unknownError');
							if (response.error === 'user-not-facilitator') {
								feedback = getText('error', 'userNotFacilitator');
							}
							if (response.error && response.error.code) {
								if (response.error.code === 'auth/email-already-exists') {
									feedback = getText('error', 'userAlreadyExists');
								}
								if (response.error.code === 'auth/invalid-email') {
									feedback = getText('error', 'invalidEmail');
								}
							}
							this.setState({isCreatingUser: false, feedback: feedback});
						}
					},
					(error) => {
						console.error(error);
						this.setState({isCreatingUser: false, feedback: getText('error', 'unknownError')});
					}
				);
			}
		});
	};


	/** 
	 * Reset password 
	 * @param {object} event
	 */
	resetPassword = (event) => {
		if (event) event.preventDefault();

		if (this.state.isResettingPassword) return;

		/* Invalid e-mail */
		if (!this.state.email || !checkIfValidEmail(this.state.email)) {
			let errorMsg = getText('error', 'invalidEmail');
			this.setState({
				feedback: errorMsg,
			});
			return;
		}
		
		/* Reset password */
		this.setState({isResettingPassword: true, feedback: null}, () => {
			apiHelper('schools/reset-password', {email: this.state.email.toLowerCase()}).then((response) => {
				if (response.status === 'success') {
					/* Success! */
					this.setState({
						isResettingPassword: false, 
						passwordIsReset: true,
						feedback: getText('adminTexts', 'passwordResetFeedback')
					});
				} else {
					/* Error */
					let errorMsg = getText('error', 'unknownError');
					if (response.error === 'user-does-not-exist') errorMsg = getText('error', 'userNotFound');
					this.setState({isResettingPassword: false, feedback: errorMsg,});
				}
			});
		});
	};

	render = () => {
		if (!this.state.isLoggedIn) {
			/* Reset password page */
			if (this.state.page === 'reset-password') {
				return (
					<FacilitatorPasswordReset
						email={this.state.email}
						handleEmailInput={this.handleEmailInput}
						stoppingPasswordReset={this.stoppingPasswordReset}
						isResettingPassword={this.state.isResettingPassword}
						passwordIsReset={this.state.passwordIsReset}
						feedback={this.state.feedback}
						resetPassword={this.resetPassword}
						goToPage={this.goToPage}
					/>
				);
			}

			/* Create user page */
			if (this.state.page === 'create-user') {
				return (
					<FacilitatorCreateUser 
						isCreatingUser={this.state.isCreatingUser}
						userIsCreated={this.state.userIsCreated}
						email={this.state.email}
						password={this.state.password}
						passwordRepeat={this.state.passwordRepeat}
						feedback={this.state.feedback}
						handleEmailInput={this.handleEmailInput}
						handlePasswordInput={this.handlePasswordInput}
						handlePasswordRepeatInput={this.handlePasswordRepeatInput}
						createUser={this.createUser}
						goToPage={this.goToPage}
					/>
				);
			}

			/* Login page page */
			return (
				<FacilitatorLogin
					isLoggingIn={this.state.isLoggingIn}
					email={this.state.email}
					password={this.state.password}
					feedback={this.state.feedback}
					handleEmailInput={this.handleEmailInput}
					handlePasswordInput={this.handlePasswordInput}
					handleLogin={this.handleLogin}
					goToPage={this.goToPage}
				/>
			);
		}

		/* Facilitator page */
		return (
			<FacilitatorController userData={this.state.userData} handleLogout={this.handleLogout} />
		);
	};
}

export default FacilitatorLoginController;