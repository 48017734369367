/**
 * Create / write to cookie
 * @param {string} cname 
 * @param {string} cvalue 
 */
function setCookie(cname, cvalue, lifespan = 365) {
	let year = new Date();
	year.setTime(year.getTime() + (lifespan * 24 * 60 * 60 * 1000));

	let expires = 'expires=' + year.toUTCString();

	if (cname === 'karrierevejen_game_data-data') {
		/* Game data expires after 24 hours */
		year.setTime(year.getTime() + (24 * 60 * 60 * 1000));
		expires = 'expires=' + year.toUTCString();
	}
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Get cookie data
 * @param {string} cname 
 * @returns 
 */
function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

/**
 * Delete cookie
 * @param {string} cname 
 */
function deleteCookie(cname) {
	setCookie(cname, '', 0);
}

export {
	setCookie,
	getCookie,
	deleteCookie
};