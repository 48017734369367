import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import './order-dnd-preview.scss';

const OrderDndPreview = ({itemsData}) => {
	let {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemId = item.id;
	const itemData = itemsData.find((i) => {return i.id === itemId;});

	/* Position */
	var itemsDiv = document.getElementById('orderItems');
	if (itemsDiv) {
		const itemsWidth = itemsDiv.clientWidth;
		const em = (itemsWidth / 16.91);
		const offset = 12 * em;
		style.left = '-' + offset + 'px';
	}
	
	return (
		<div 
			className={'OrderDndPreview'} 
			style={style}
		>
			<div className="OrderDndPreview-item">
				<span>{itemData ? itemData.text : null}</span>
			</div>
		</div>
	);
};

OrderDndPreview.propTypes = {
	itemsData: PropTypes.array.isRequired,
};

export default OrderDndPreview;