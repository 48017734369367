import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './facilitator-login.scss';

const FacilitatorLogin = (
	{
		isLoggingIn,
		email,
		password,
		feedback,
		goToPage,
		handleEmailInput,
		handlePasswordInput,
		handleLogin
	}) => {

	return (
		<div className='FacilitatorLogin'>
			<div className='FacilitatorLogin-content'>
				<div className='FacilitatorLogin-header'>
					<div className='FacilitatorLogin-title'>
						<span>{getText('statsTexts', 'schoolLogin')}</span>
					</div>
				</div>
				<div className='FacilitatorLogin-body'>
					<form className='FacilitatorLogin-form'>
						<div className='FacilitatorLogin-inputWrapper'>
							<span>{getText('statsTexts', 'email')}</span>
							<input
								className='FacilitatorLogin-input'
								name="email"
								type="text"
								placeholder={getText('statsTexts', 'email')}
								autoComplete="section-FacilitatorLogin email"
								value={email ? email : ''}
								onChange={(event) => {handleEmailInput(event);}}
							/>
						</div>
						<div className='FacilitatorLogin-inputWrapper'>
							<span>{getText('adminTexts', 'password')}</span>
							<input
								className='FacilitatorLogin-input'
								name="password"
								type="password"
								placeholder={getText('statsTexts', 'password')}
								autoComplete="section-FacilitatorLogin password"
								value={password ? password : ''} 
								onChange={(event)=>{handlePasswordInput(event);}}
							/>
						</div>
						<div className='FacilitatorLogin-loginButton'>
							<Button 
								isLoading={isLoggingIn}
								classes={['shadow', 'blue', 'blueBorder', 'responsive']}
								text={getText('statsTexts', 'login')} 
								onClick={handleLogin}
							/>
						</div>
						<div className="FacilitatorLogin-errorMessage">
							<span>{feedback}</span>
						</div>
					</form>

					<div className="FacilitatorPasswordReset-goToPageBtns">
						<Button
							isLoading={isLoggingIn}
							text={getText('statsTexts', 'createUser')}
							classes={['smallFontSize', 'darkBlueText', 'responsive']}
							onClick={() => {goToPage('create-user');}}
						/>
						<Button
							isLoading={isLoggingIn}
							text={getText('adminTexts', 'resetPassword')}
							classes={['smallFontSize', 'darkBlueText', 'responsive']}
							onClick={() => {goToPage('reset-password');}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

FacilitatorLogin.propTypes = {
	isLoggingIn: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	goToPage: PropTypes.func.isRequired,
	handleEmailInput: PropTypes.func.isRequired,
	handlePasswordInput: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
};

export default FacilitatorLogin;