const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

const module1Tasks = [
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		id: 'order-free-time',
		number: 13,
		text: 'Tænk over, hvad du godt kan lide at lave. Sæt nedenstående handlinger i rækkefølge fra det, du bedst kan lide, til det, du mindst kan lide.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'At være ude',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingOutside', 'nature'], 
						points: [2, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingOutside', 'nature'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'At være sammen med mennesker',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['community', 'social'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['community', 'social'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'At bruge hovedet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['bookish', 'intellectualChallenges'], 
						points: [1, 2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['bookish', 'intellectualChallenges'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'At skabe noget',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creating', 'creative'], 
						points: [2, 2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creating', 'creative'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: 'At dyrke sport',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6,
				text: 'At rode med maskiner',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['machines'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['machines'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-skills',
		number: 2,
		text: 'Vælg ting fra listen, du er god til. Du må gerne vælge flere svar.',
		canAnswerAnyAmount: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'At bruge hænderne',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['goodHands'], 
						points: [2],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Computere',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'], 
						points: [2], 
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At hjælpe andre',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['social', 'helping'], 
						points: [1, 1], 
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'At snakke',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight', 'communication'], 
						points: [1, 1],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Planlægning',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['systematic', 'independent'], 
						points: [2, 1],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'At bruge hovedet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['bookish'], 
						points: [2], 
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'At tegne',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing'], 
						points: [2], 
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'At skrive',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing'], 
						points: [2], 
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		id: 'order-friends-activities',
		number: 30,
		text: 'Hvad laver du med dine venner? Sæt ting i rækkefølge fra det, du bedst kan lide at lave, til det, du mindst kan lide at lave.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'At bage eller lave mad',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['cooking', 'creating'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['cooking', 'creating'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'At høre musik',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creative', 'culture'], 
						points: [1, 1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creative', 'culture'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'At tale om bøger og film',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading', 'culture'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading', 'culture'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'At dyrke sport',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: 'At game',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6,
				text: 'At tale om politik',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					}),
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-exciting-places',
		number: 12,
		text: 'Hvilket af følgende steder, synes du, ser mest spændende ud?',
		numberOfAnswersToSelect: 1,
		subtype: 'images',
		layout: 'exciting-places',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Klassisk bibliotek
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading', 'culture', 'writing'], 
						points: [1, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // High tech lab
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science', 'math'], 
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Fancy hjørnekontor
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['officeWork', 'responsibility', 'pay'], 
						points: [2, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Byggeplads
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingOutside', 'creating', 'practicalWork'], 
						points: [1, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Værksted eller galleri
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creating', 'practicalWork', 'culture'], 
						points: [1, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Lægekonsultation med plancher osv.
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['health'], 
						points: [2],
					}),
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-computers',
		number: 25, 
		text: 'Hvad bruger du computere til? Vælg en eller flere.',
		canAnswerAnyAmount: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det er mest sådan en skrivemaskine',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing', 'reading'], 
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Det er mest til gaming',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'],
						points: [1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'De er fede at få til at virke',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers', 'technique'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Jeg bruger dem mest til at se mine online-venner',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['community', 'social'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Til at programmere',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers', 'analytical'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Til at tegne, lave videoer eller lignende',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creativeWork', 'communication'],
						points: [1, 1],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-punching-projects',
		number: 11, 
		text: 'Hvordan har du det med hobbyprojekter? Vælg en eller flere.',
		canAnswerAnyAmount: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Jeg sætter hele tiden ting i gang',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independent', 'startingThings'],
						points: [1, 2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Jeg elsker at hjælpe andre med projekter',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork', 'helping', 'social'],
						points: [1, 2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Hvis jeg brænder for en sag, så gør jeg noget',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping', 'startingThings'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Jeg vil hellere være sammen med venner',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['social', 'community'],
						points: [1, 1],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-project-role',
		number: 10,
		text: 'Hvilken rolle har du mest, når du laver gruppearbejde?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Jeg knokler med det, der er behov for',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['thorough', 'routines'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Jeg sætter arbejdet i gang',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['startingThings', 'responsibility'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Jeg sørger for god stemning',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['social', 'community'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Jeg skriver',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Jeg planlægger arbejdet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['analytical', 'responsibility'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Jeg læser og researcher',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading'],
						points: [2],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-musical-role',
		number: 20, 
		text: 'Hvis årgangen skal lave musical, hvad vil du så helst lave?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Kostumer og sminke',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creative', 'drawing', 'culture'],
						points: [1, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Lys og teknik',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['technique', 'computers'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Kulisser',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing', 'culture', 'building', 'creative'],
						points: [2, 1, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Noget på scenen',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creative', 'spotlight', 'creating'],
						points: [1, 2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Stå for snacks og moralsk opbakning',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping', 'social', 'community', 'teamwork'],
						points: [1, 2, 1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Markedsføring',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business', 'communication', 'officeWork'],
						points: [1, 1, 1],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-challenge',
		number: 35, 
		text: 'Du har en udfordring, hvordan vil du helst løse den?',
		subtype: 'images',
		layout: 'challenge',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af bog eller manual
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['bookish', 'reading'],
						points: [2, 2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af matematik
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['math', 'science'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Billede af værksted/forsøgslokale
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork', 'technique'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Workshop
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork'],
						points: [2],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-solving-tasks',
		number: 29, 
		text: 'Du skal lave en opgave, hvordan løser du den?',
		subtype: 'images',
		layout: 'solving-tasks',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af gruppe der snakker
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork', 'social'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af enkeltperson der grubler
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independent', 'independence'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Billede af chef der uddelegerer
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork', 'responsibility'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gantt-skema eller lignende
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['systematic', 'analytical'],
						points: [1, 1],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		id: 'order-good-mood',
		number: 3, 
		text: 'Hvad gør dig i godt humør? Sæt i rækkefølge fra mest til mindst.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'At gøre noget for andre',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping', 'people'], 
						points: [2, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping', 'people'],
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'At bygge eller ordne noget',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['building', 'creating'], 
						points: [2, 1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['building', 'creating'], 
						points: [1, 1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'At lære noget nyt',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['variation', 'manyOpportunities'],
						points: [2, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['variation', 'manyOpportunities'],
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'At være ude i naturen',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['nature', 'workingOutside'], 
						points: [3, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['nature', 'workingOutside'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: 'At dyrke sport',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6,
				text: 'At tjene penge',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-school',
		number: 17, 
		text: 'Hvilken følelse passer bedst, når du tænker på at gå i skole?',
		subtype: 'images',
		layout: 'school',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Træt type
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork', 'shortEducation'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Begejstret type
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['bookish'],
						points: [2],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-improvement-wishes',
		number: 18,
		text: 'Vælg to ting fra listen, du gerne vil bruge tid på at blive bedre til.',
		numberOfAnswersToSelect: 2,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Computere',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Matematik',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['math'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Videnskab',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Samarbejde',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'At skrive',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing', 'communication'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'At holde oplæg',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight'],
						points: [1],
					}),
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-things-about-you',
		number: 1, 
		text: 'Vælg ting, du synes kendetegner dig. Du må gerne vælge mere end en.',
		canAnswerAnyAmount: true,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Musikalsk',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['culture', 'creative'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Kreativ',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creative'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Logisk',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science', 'analytical'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Omhyggelig',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['thorough'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Ansvarlig',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['responsibility'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Selvstændig',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independent'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Omsorgsfuld',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['social', 'helping'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'Praktisk',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['goodHands'],
						points: [2],
					}),
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-inspiration',
		number: 4, 
		text: 'Vælg det billede, der inspirerer dig mest.',
		numberOfAnswersToSelect: 1,
		subtype: 'images',
		layout: 'inspiration',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af computer
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers', 'technique'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af natur
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['nature', 'workingOutside'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Billede af værktøj eller noget ingeniørhalløj
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork', 'analytical'],
						points: [2, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Billede af bøger
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['intellectualChallenges', 'bookish'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Billede af lærred og maling
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing', 'creative', 'creativeWork'],
						points: [1, 1, 2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Operationsstue
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['health'],
						points: [2],
					}),
				]
			}),
		],
	}),
    Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		id: 'sortTemplate-job-qualities',
		number: 26, 
		text: 'Når du tænker på det arbejde, du skal have, hvad skal det så kunne. Sortér følgende i de tre kasser.',
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '3', text: 'Meget vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Lidt vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'Ikke vigtigt'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1,
				text: 'God løn',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['pay'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['pay'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2,
				text: 'Rutiner',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['routines'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['routines'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3,
				text: 'Selvbestemmelse',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independence'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independence'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4,
				text: 'Arbejde med hænderne',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5,
				text: 'Arbejde med hovedet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['intellectualChallenges'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['intellectualChallenges'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6,
				text: 'Det skal være på et kontor',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['officeWork'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['officeWork'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					}),
				]
			}),
    	]
    }),
    Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
			id: 'sortTemplate-education',
			number: 16, 
			text: 'Når du tænker på uddannelse, hvad er så vigtigt? Sortér følgende i de tre kasser.',
			categories: [
				Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '3', text: 'Meget vigtigt'}),
				Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Lidt vigtigt'}),
				Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'Ikke vigtigt'})
			],
			items: [				
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 1,
					text: 'Stærkt fællesskab',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['community'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['community'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 2,
					text: 'Masser af muligheder',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['manyOpportunities'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['manyOpportunities'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 3,
					text: 'Meget praksis',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['practicalWork'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['practicalWork'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 4,
					text: 'Kort uddannelse',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['shortEducation'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['shortEducation'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 5,
					text: 'Meget bogligt arbejde',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['bookish'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['bookish'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
				Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
					id: 6,
					text: 'At jeg kan være analytisk',
					effects: [
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['analytical'],
							points: [1],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
							]
						}),
						Object.assign({}, effectTemplate.mainTemplate, {
							keywordIds: ['analytical'],
							points: [2],
							conditions: [
								Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
							]
						}),
					]
				}),
			]
    }),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-handywork',
		number: 14, 
		text: 'Hvis du skulle lave noget håndværk, hvad synes du så ser mest spændende ud? Du må gerne vælge mere end et billede.',
		canAnswerAnyAmount: true,
		subtype: 'images',
		layout: 'handywork',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Noget med store maskiner
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['machines'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Noget med træ eller maling
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Noget i et køkken
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['cooking'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Noget med teknik
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['technique'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Kreativt arbejde
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creativeWork'],
						points: [2],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-study',
		number: 15, 
		text: 'Hvis du skulle fordybe dig i et studie, hvad skulle det så være? Du må gerne vælge mere end et billede.',
		canAnswerAnyAmount: true,
		subtype: 'images',
		layout: 'study',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Kunst
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['culture'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Christiansborg
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Rummet
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Penge
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Kroppen (medicinsk)
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['health'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Psykologi
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [2],
					}),
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-most-important',
		text: 'Sidste spørgsmål. Hvad er vigtigt for dig? Du må gerne vælge mere end en mulighed.',
		canAnswerAnyAmount: true,
		number: 36, 
		options: [			
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Familie',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['building'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'At gøre en forskel for andre',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At kunne bestemme selv',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['independence'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'At kunne udtrykke sig',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight', 'communication'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'At tjene penge',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['pay'],
						points: [2],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'At passe på naturen',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['nature', 'environment'],
						points: [1, 1],
					}),
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'At komme hurtigt ud at arbejde',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['shortEducation'],
						points: [1],
					}),
				]
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		id: 'order-presentation',
		number: 31, 
		text: 'I skal lave et projekt med fremlæggelse. Sortér følgende fra, hvad du bedst kan lide ved arbejdet, til det, du mindst kan lide.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: 'Researche og læse',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: 'Diskussion med gruppen',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['teamwork'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: 'Skrive opgaven',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing'], 
						points: [2], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['writing'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: 'Fremlægge',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight', 'communication'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight'], 
						points: [1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: 'Organisere arbejdet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['responsibility', 'systematic'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 3
							})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['responsibility', 'systematic'], 
						points: [1, 1], 
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {
								type: 'maxPositionNumber',
								maxPositionNumber: 1
							})
						]
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-image-interesting',
		number: 21, 
		text: 'Hvad synes du er mest spændende?',
		numberOfAnswersToSelect: 1,
		subtype: 'images',
		layout: 'interesting',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af landbrugsdyr, søde
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingOutside', 'nature'], 
						points: [1, 1],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af sejt auditorium
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['intellectualChallenges', 'science'], 
						points: [1, 1],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Billede af motor
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['machines'], 
						points: [2],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Billede af butik med tøj
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'], 
						points: [2],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Vindmøller og solceller
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['environment'], 
						points: [2],
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Fodbold
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['sport'], 
						points: [2],
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-independent-work',
		number: 6, 
		text: 'Hvor god er du til at arbejde selvstændigt?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['independent'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['independent'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['independent'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-learning-new-skills',
		number: 34, 
		text: 'Hvor glad er du for at lære nye ting?',
		minText: 'Ikke så glad',
		maxText: 'Ret glad',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['variation'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['variation'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['variation'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-task-immersion',
		number: 27, 
		text: 'Hvor god er du til at fordybe dig og knokle igennem med en opgave?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['thorough'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['thorough'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['thorough'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-cooperation',
		number: 7, 
		text: 'Hvor god er du til at arbejde sammen med andre?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['teamwork'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['teamwork'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['teamwork'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-math-skills',
		number: 28, 
		text: 'Hvor god er du til matematik og regning?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['math'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['math'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['math'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-talk-new-people',
		number: 8, 
		text: 'Hvor god er du til at tale med folk, du ikke kender?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['social'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['social'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['social'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-roadside-stand',
		number: 24, 
		text: 'Hvis du som lille skulle lave en vejbod, hvad var så det fedeste?',
		options: [			
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'At bage',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['cooking'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'At tjene penge',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business', 'pay'],
						points: [2, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At bygge en flot bod',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing', 'building'],
						points: [1, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'At sætte mine søskende til at lave arbejdet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['responsibility'],
						points: [2]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'At tale med kunderne',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [2]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'At lave nogle fede skilte',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing'],
						points: [2]
					})
				],
			}),
		],
	}),
	Object.assign({}, taskTemplates.sliderTemplate.mainTemplate, {
		id: 'slider-good-with-hands',
		number: 32, 
		text: 'Hvor god er du til at bruge dine hænder?',
		minText: 'Ikke så god',
		maxText: 'Ret god',
		effects: [
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['goodHands'],
				points: [1],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 51, maxValue: 65
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['goodHands'],
				points: [2],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 66, maxValue: 85
					})
				]
			}),
			Object.assign({}, effectTemplate.mainTemplate, {
				keywordIds: ['goodHands'],
				points: [3],
				conditions: [
					Object.assign({}, effectTemplate.conditionTemplate, {
						type: 'valueRange', minValue: 86, maxValue: 100
					})
				]
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-project-week',
		number: 9, 
		text: 'I har projektuge. Du og din gruppe skal dykke ned i et emne og lave en planche. Hvilken ting ser du mest frem til?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'At lave en fed planche',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['drawing', 'communication'],
						points: [2, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'At lægge en plan for arbejdet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['systematic', 'responsibility'],
						points: [2, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'At dykke ned i emnet og blive klogere',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading', 'bookish'],
						points: [1, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'At holde oplæg',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight', 'communication'],
						points: [1, 1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Jeg kan bedst lide at arbejde alene',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingAlone', 'independent'],
						points: [2, 1]
					})
				],
			}),
		],
	}),
    Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		id: 'sortTemplate-lastQuestion',
		number: 5, 
		text: 'Tænk over, hvad du synes er vigtigt, når det gælder skole og uddannelse. Sortér så følgende i de tre kasser.',
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '3', text: 'Meget vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'Ikke så vigtigt'})
		],					
		items: [				
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1,
				text: 'At jeg kan lave noget praktisk',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2,
				text: 'At der er variation',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['variation'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['variation'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3,
				text: 'At det er noget med computere og teknik',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers', 'technique'],
						points: [1, 1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers', 'technique'],
						points: [2, 2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4,
				text: 'At det er noget med forretning og salg',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['business'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5,
				text: 'At det er noget med mennesker',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6,
				text: 'At man skaber noget',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creating'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['creating'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7,
				text: 'At det ikke tager for lang tid',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['shortEducation'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['shortEducation'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
		]
    }),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-cooking',
		number: 33, 
		text: 'Hvor god er du i et køkken?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Jeg er generelt god til praktiske ting',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['practicalWork'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Jeg er ret god til at lave mad',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['cooking'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Jeg kan fortælle historier, mens andre arbejder',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['spotlight'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Jeg kan godt læse en kogebog, hvis jeg absolut skal',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['reading'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Sådan noget molekylær gastronomi lyder fedt',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Jeg kan dårligt nok koge et æg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		id: 'multipleChoice-school-interests',
		number: 22,
		canAnswerAnyAmount: true,
		text: 'Hvad vil du helst lave på skolen? Du må gerne vælge mere end en.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Skoleblad',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['communication'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Juleforestilling',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['culture'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Videnskabsprojekt',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['science'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Arrangere en fest',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['social'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Klima-kampagne',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['environment'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Valgaften',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Førstehjælpskursus',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['health'],
						points: [1]
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'LAN-party',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['computers'],
						points: [1]
					})
				],
			}),
		],
	}),
    Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		id: 'sortTemplate-importantJobAspects',
		number: 19, 
		text: 'Tænk over, hvad du godt kunne tænke dig i forhold til arbejde. Hvad er vigtigt? Sortér i boksene.',
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '3', text: 'Meget vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '2', text: 'Vigtigt'}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {id: '1', text: 'Ikke så vigtigt'})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1,
				text: 'At gøre en forskel for andre',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['helping'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2,
				text: 'At blive klogere på mennesker',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['people'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3,
				text: 'At det er noget med at skrive og kommunikere',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['communication'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['communication'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4,
				text: 'At jeg kan gøre en forskel for klimaet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['environment'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['environment'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5,
				text: 'At det handler om samfundet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['politics'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6,
				text: 'At jeg kan arbejde alene',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingAlone'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['workingAlone'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7,
				text: 'At jeg kan tage mig tid og være grundig',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['thorough'],
						points: [1],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '2'})
						]
					}),
					Object.assign({}, effectTemplate.mainTemplate, {
						keywordIds: ['thorough'],
						points: [2],
						conditions: [
							Object.assign({}, effectTemplate.conditionTemplate, {type: 'containerId', containerId: '3'})
						]
					})
				]
			}),
		]
    }),
];

export {
	module1Tasks
};
