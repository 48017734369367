import React, {Component} from 'react';
import appConfig from 'config/app.config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getText } from 'helpers/text-helper';
import AdminLogin from './admin-login';
import Admin from '../admin';

class AdminLoginController  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoggingIn: false,
			isLoggedIn: false,
			email: null,
			password: null,
			feedback: null,
			userData: null
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if logged in
	 */
	checkIfLoggedIn = () => {
		/* Unsubscribe previous onAuthStateChanged */
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	
		/* Subscribe to onAuthStateChanged */
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* User is logged in, get user data */
				const db = firebase.firestore();
				db.collection(appConfig.usersDbName).doc(user.uid).get().then((doc) => {
					if (doc.exists) {
						if (doc.data() && doc.data().role === 'admin') {
							this.setState({
								isCheckingLogin: false,
								isLoggedIn: true,
								userData: {
									userId: user.uid,
									email: user.email,
									role: doc.data().role
								}
							});
						} else {
							/* User is not admin */
							this.handleLogout();	
						}
					} else {
						/* No user data */
						this.handleLogout();
					}
				});
			} else {
				/* Not logged in or invalid login*/
				this.setState({isCheckingLogin: false, isLoggedIn: false, userData: null});
			}
		});
	};


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			feedback: null,
		});
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();

		this.setState({isLoggingIn: true}, () => {
			/* Check input */
			let email = this.state.email;
			let password = this.state.password;
			if (!email || !password) {
				/* Error: missing fields */
				this.setState({
					isLoggingIn: false,
					feedback: getText('error', 'someFieldsMissing'),
					invalidInputEmail: email ? false : true,
					invalidInputPassword: password ? false : true
				});
			} else {
				/* Input ok, sign in */
				firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
					let errorMsg = getText('error', 'unknownError');
					if (error.code === 'auth/invalid-email') errorMsg = getText('error', 'invalidEmail');
					if (error.code === 'auth/user-not-found') errorMsg = getText('error', 'userNotFound');
					if (error.code === 'auth/wrong-password') errorMsg = getText('error', 'invalidPassword');
					this.setState({feedback: errorMsg, isLoggingIn: false});
				});
			}
		});
	};

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({isLoggedIn: false, isLoggingIn: false, userData: null});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Login page */
		if (!this.state.isLoggedIn) {
			return (
				<AdminLogin 
					isCheckingLogin={this.state.isCheckingLogin}
					isLoggingIn={this.state.isLoggingIn}
					email={this.state.email}
					password={this.state.password}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}

		/* Admin page */
		return (
			<Admin userData={this.state.userData} handleLogout={this.handleLogout} />
		);
	};
};

export default AdminLoginController;