import { sortArrayByProperty } from 'helpers/array-helper';

const modulesData = [
	{
		id: 'module-1',
		title: 'Svar på spørgsmål',
		description: '',
		tasks: sortArrayByProperty(require('./module-1-tasks-data').module1Tasks, 'number', 'ASC'),
	},
	// {
	// 	id: 'module-2',
	// 	title: 'Svar på flere spørgsmål',
	// 	description: '',
	// 	tasks: [{id: 'choose-direction', type: 'choose-direction',}]
	// },
	{
		id: 'module-3',
		title: 'Resultater',
		description: '',
		tasks: require('./module-3-tasks-data').module3Tasks
	},
];

export {
	modulesData,
};