/* Effect */
const effectTemplate = {
	mainTemplate: {
		type: 'keyword-points',
		keywordIds: [], // keyword ids
		points: [] // keyword points
	},
	conditionTemplate: {
		// NB! This is a new feature, check that the game engine can handle it if you use it
		// currently works for: sort, order
		type: 'containerId', // containerId, maxPositionNumber
		
	},
};

export {effectTemplate};
