import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import { nextStepData } from 'data/next-step-data';
import Button from '../button/button';
import './next-step.scss';

const NextStep = ({resultId, style, setOpenResultId}) => {
	// Handles opening and closing of result
	const handleToggleOpenResult = (open) => {
		setIsOpen(open);
		if (open) {
			setOpenResultId(resultId);
		} else {
			setOpenResultId(null);
		}
	};

	const [isOpen, setIsOpen] = useState(false);

	/* Get result data */
	const resultData = nextStepData.find((r) => {return r.id === resultId;});
	if (!resultData) return null;

	return (
		<div className={'NextStep ' + style + (isOpen ? ' active' : ' inactive')}>
			<div className={'NextStep-circle ' + (isOpen ? '' : ' inactive')}
				onClick={() => {handleToggleOpenResult(true);}}
			>
				{!isOpen ?
					<div className='NextStep-content'>
						<div className='NextStep-title'>
							<span>{resultData.title}</span>
						</div>
						{resultData.subtitle &&
							<div className='NextStep-text'>
								<span>{resultData.subtitle}</span>
							</div>
						}
						<div className="NextStep-moreBtn">
							<Button 
								classes={[resultId, 'rounded', 'variableHeight', 'smallFontSize']}
								text={getText('gameUi', 'viewMore')}
								onClick={() => {}}
							/>
						</div>
					</div>
					:
					<div className='NextStep-openContent'>
						<div className='NextStep-title'>
							<span>{resultData.title}</span>
						</div>
						{resultData.subtitle &&
							<div className='NextStep-text'>
								<span>{resultData.subtitle}</span>
							</div>
						}
						{resultData.description &&
							<div className='NextStep-description' 
								dangerouslySetInnerHTML={{__html: resultData.description}}
							/>
						}
					</div>
				}
				{isOpen &&	
					<div className={'Result-closeBtn'}
						onClick={(e) => {
							e.stopPropagation();
							handleToggleOpenResult(false);
						}}
					/>
				}
			</div>
		</div>
	);
};

NextStep.propTypes = {
	resultId: PropTypes.string.isRequired,
	style: PropTypes.string.isRequired,
	setOpenResultId: PropTypes.func.isRequired
};

export default NextStep;