import { useState } from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {resultsData} from 'data/results-data';
import { careersData } from 'data/careers-data';
import Button from 'components/ui/button/button';
import './result.scss';

const Result = ({rank, style, resultId, setOpenResultId}) => {
	// Handles opening and closing of result
	const handleToggleOpenResult = (open) => {
		setIsOpen(open);
		if (open) {
			setOpenResultId(resultId);
		} else {
			setOpenResultId(null);
		}
	};
	const [isOpen, setIsOpen] = useState(false);

	/* Get result data */
	const resultData = resultsData.find((r) => {return r.id === resultId;});
	if (!resultData) return null;

	const isMac = navigator.userAgent.indexOf('Mac') > 0;
	
	return (
		<div className={'Result ' + (isOpen ? ' active' : style)}>
			{!isOpen ?
				<div className={'Result-circle' + (isOpen ? ' active' : '')}
					onClick={() => {handleToggleOpenResult(true);}}
				>
					{rank &&
					<div className={'Result-numbering ' + (isMac ? ' mac' : '')}>
						<span>{rank}</span>
					</div>
					}
					<div className='Result-title'>
						<span>{resultData.splitTitle ? resultData.splitTitle : resultData.title}</span>
					</div>
					<div className="Result-examplesBtn">
						<Button 
							classes={['blue', 'rounded', 'variableHeight', 'smallFontSize']}
							text={getText('gameUi', 'viewExamples')}
							onClick={() => {}}
						/>
					</div>
				</div>
				:
				<div className='Result-openCircle'>
					<div className='Result-openCircleContent'>
						<div className='Result-openCircleTitle'>
							<span>{resultData.title}</span>
						</div>
						<div className='Result-openCircleText'>
							<span>{resultData.description}</span>
						</div>
						{resultData.education && 
							<div className='Result-openCircleSubText'>
								<span className='Result-openCircleSubTitle'>{getText('gameUi', 'education')}</span>
								<span>{resultData.education}</span>
							</div>
						}
						{resultData.duration && 
							<div className='Result-openCircleSubText hasSpacing'>
								<span className='Result-openCircleSubTitle'>{getText('gameUi', 'duration')}</span>
								<span>{resultData.duration}</span>
							</div>
						}
						<div className='Result-openCircleCareers'>
							{resultData.careerIds.map((careerId) => {
								let careerTitle = '';
								const data = careersData.find((career) => {
									return career.id === careerId;
								});

								if (data) {
									careerTitle = data.title;
								}
								return (
									<div key={careerId} className='Result-openCircleCareer'>
										<span>{careerTitle}</span>
									</div>
								);
							})}
						</div>
					</div>

					<div className={'Result-closeBtn'}
						onClick={(e) => {
							e.stopPropagation();
							handleToggleOpenResult(false);
						}}
					/>
				</div>
			}
		</div>
	);
};

Result.propTypes = {
	rank: PropTypes.number,
	style: PropTypes.string.isRequired,
	resultId: PropTypes.string.isRequired,
	setOpenResultId: PropTypes.func.isRequired
};

export default Result;