
const imagesData = [
	'broken-circle.svg',
	'game-logo.svg',
	'icons/icon-check.svg',
	'icons/icon-close.svg',
	'icons/icon-cookie.svg',
	'icons/icon-dropdown.svg',
	'icons/icon-loading.svg',
	'icons/icon-menu.svg',
	'icons/icon-move.svg',
	'icons/icon-next.svg',
	'icons/icon-previous.svg',
	'icons/icon-slider-thumb.svg',
	'modules/tasks/multiple-choice/challenge/option-1.png',
	'modules/tasks/multiple-choice/challenge/option-2.png',
	'modules/tasks/multiple-choice/challenge/option-3.png',
	'modules/tasks/multiple-choice/challenge/option-4.png',
	'modules/tasks/multiple-choice/exciting-places/option-1.png',
	'modules/tasks/multiple-choice/exciting-places/option-2.png',
	'modules/tasks/multiple-choice/exciting-places/option-3.png',
	'modules/tasks/multiple-choice/exciting-places/option-4.png',
	'modules/tasks/multiple-choice/exciting-places/option-5.png',
	'modules/tasks/multiple-choice/exciting-places/option-6.png',
	'modules/tasks/multiple-choice/handywork/option-1.png',
	'modules/tasks/multiple-choice/handywork/option-2.png',
	'modules/tasks/multiple-choice/handywork/option-3.png',
	'modules/tasks/multiple-choice/handywork/option-4.png',
	'modules/tasks/multiple-choice/handywork/option-5.png',
	'modules/tasks/multiple-choice/inspiration/option-1.png',
	'modules/tasks/multiple-choice/inspiration/option-2.png',
	'modules/tasks/multiple-choice/inspiration/option-3.png',
	'modules/tasks/multiple-choice/inspiration/option-4.png',
	'modules/tasks/multiple-choice/inspiration/option-5.png',
	'modules/tasks/multiple-choice/inspiration/option-6.png',
	'modules/tasks/multiple-choice/interesting/option-1.png',
	'modules/tasks/multiple-choice/interesting/option-2.png',
	'modules/tasks/multiple-choice/interesting/option-3.png',
	'modules/tasks/multiple-choice/interesting/option-4.png',
	'modules/tasks/multiple-choice/interesting/option-5.png',
	'modules/tasks/multiple-choice/interesting/option-6.png',
	'modules/tasks/multiple-choice/school/option-1.png',
	'modules/tasks/multiple-choice/school/option-2.png',
	'modules/tasks/multiple-choice/solving-tasks/option-1.png',
	'modules/tasks/multiple-choice/solving-tasks/option-2.png',
	'modules/tasks/multiple-choice/solving-tasks/option-3.png',
	'modules/tasks/multiple-choice/solving-tasks/option-4.png',
	'modules/tasks/multiple-choice/study/option-1.png',
	'modules/tasks/multiple-choice/study/option-2.png',
	'modules/tasks/multiple-choice/study/option-3.png',
	'modules/tasks/multiple-choice/study/option-4.png',
	'modules/tasks/multiple-choice/study/option-5.png',
	'modules/tasks/multiple-choice/study/option-6.png',
];

export {
	imagesData
};
