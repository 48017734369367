import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './order-dnd-container.scss';

const OrderDndContainer = ({layout, location, positionNumber, handleMoveItem, handleUnsetPreviewItem}) => {
	/* Drop functionality */
	const [{isOver}, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (handleMoveItem) {
				/* Ignore hovering over itself */
				if (item.location === location && item.positionNumber === positionNumber) {
					return;
				}

				/* Move card */
				handleMoveItem(item.id, location, positionNumber);
			}
		},
		collect: (monitor) => {
			// We are hovering over something that is not this item and location
			if (monitor.isOver()) {
				handleUnsetPreviewItem(location, positionNumber);
			}

			return {
				isOver: monitor.isOver(),
			};
		}
	});

	return (
		<div 
			ref={drop}
			className={'OrderDndContainer' + (layout ? ' ' + layout : '') + ((isOver) ? ' hover' : '')}
		/>
	);
};

OrderDndContainer.propTypes = {
	layout: PropTypes.string,
	location: PropTypes.string.isRequired,
	positionNumber: PropTypes.number.isRequired,
	handleMoveItem: PropTypes.func.isRequired,
	handleUnsetPreviewItem: PropTypes.func.isRequired,
};

export default OrderDndContainer;
