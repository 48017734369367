import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {statsData} from 'data/stats-data';
import './stats-table.scss';

const StatsTable = ({nextStepStats, careerPathStats, keywordStats}) => {
	/* Stats navigation */
	const [currentStatId, setCurrentStatId] = useState(statsData[0].id);

	/* Stats */
	const currentStatData = statsData.find((s) => {return s.id === currentStatId;});
	let resultStats = [];
	if (currentStatId === 'nextSteps') resultStats = nextStepStats;
	if (currentStatId === 'careerPaths') resultStats = careerPathStats;
	if (currentStatId === 'keywords') resultStats = keywordStats;

	/* Sorting */
	const [sortBy, setSortBy] = useState(statsData[0].defaultSortBy);
	const [sortDir, setSortDir] = useState(statsData[0].defaultSortDir);
	let secondSortBy = null;
	if (currentStatId === 'nextSteps' || currentStatId === 'careerPaths') {
		secondSortBy = ('firstPercentage' ? 'topThreePercentage' : 'firstPercentage');
	} else if (currentStatId === 'keywords') {
		secondSortBy = (sortBy === 'category' ? 'average' : 'category');
	}
	const sortedResultStats = sortArrayByProperty(resultStats, sortBy, sortDir, secondSortBy, sortDir);

	/**
	 * Update sorting
	 * @param {string} newSortBy 
	 */
	const handleUpdateSorting = (newSortBy) => {
		if (sortBy === newSortBy) {
			/* Switch direction */
			const newSortDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
			setSortDir(newSortDir);
		} else {
			/* New sort by */
			setSortBy(newSortBy);
			setSortDir('DESC');
		}
	};

	/**
	 * New currentStatId
	 */
	useEffect(() => {
		const currentStatData = statsData.find((s) => {return s.id === currentStatId;});
		setSortBy(currentStatData.defaultSortBy);
		setSortDir(currentStatData.defaultSortDir);
	}, [currentStatId]);

	return (
		<div className="StatsTable">
			{/* Navigation */}
			<div className='StatsTable-nav'>
				<div 
					className={'StatsTable-navItem' + (currentStatId === 'nextSteps' ? ' selected' : ' ')}
					onClick={() => {setCurrentStatId('nextSteps');}}
				>
					<span>{getText('gameUi', 'nextStep')}</span>
				</div>					
				<div 
					className={'StatsTable-navItem' + (currentStatId === 'careerPaths' ? ' selected' : ' ')}
					onClick={() => {setCurrentStatId('careerPaths');}}
				>
					<span>{getText('statsTexts', 'careerPaths')}</span>
				</div>
				<div 
					className={'StatsTable-navItem' + (currentStatId === 'keywords' ? ' selected' : ' ')}
					onClick={() => {setCurrentStatId('keywords');}}
				>
					<span>{getText('gameUi', 'keywords')}</span>
				</div>
			</div>

			{/* Stats table */}
			<div className={'StatsTable-table ' + currentStatId}>
				{/* Header */}
				<div className='StatsTable-header'>
					{currentStatData.columns.map((statData) => {
						return (
							<div 
								key={statData.id}
								className={'StatsTable-title'
									+ (statData.isSortable ? ' sortable' : '' ) 
									+ (sortBy === statData.statProp ? ' sort ' + sortDir : '')}
								onClick={() => {if (statData.isSortable) handleUpdateSorting(statData.statProp);}}
							><span>{statData.title}</span>
							</div>
						);
					})}
				</div>
				<div className='StatsTable-body'>
					{sortedResultStats.map((resultStat, index) => {
						return (
							<div key={index} className='StatsTable-row'>
								{currentStatData.columns.map((statData) => {
									let value = resultStat[statData.statProp];
									if (statData.type === 'percent') {
										value = value.toFixed(2) + '%';
									}
									let value2 = (statData.statProp2 ? resultStat[statData.statProp2] : null);
									return (
										<div key={statData.id} className='StatsTable-value'>
											<span>{value}</span>
											{value2 && <span>- {value2}</span>}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

StatsTable.propTypes = {
	nextStepStats: PropTypes.array.isRequired,
	careerPathStats: PropTypes.array.isRequired,
	keywordStats: PropTypes.array.isRequired
};

export default StatsTable;