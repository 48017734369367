import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import apiHelper from 'helpers/api-helper';
import {checkIfValidEmail} from 'helpers/format-helper';
import {getNumberOfSchoolsEmailIsFacilitatorOf} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './popup-add-facilitator.scss';

const PopupAddFacilitator = ({schools, users, updateSchool, setShowAddFacilitatorPopup}) => {
	/* Error message */
	const [errorMessage, setErrorMessage] = useState(null);

	/* Add facilitator email and status */
	const [facilitatorEmail, setFacilitatorEmail] = useState('');
	const [isAddingFacilitator, setIsAddingFacilitator] = useState(false);

	/* Selected schools */
	const [schoolIds, setSchoolIds] = useState(schools.map((school) => {return school.id;}));

	/* Get list of facilitators */
	const facilitatorList = [];
	schools.forEach((school) => {
		if (school.facilitatorEmails && school.facilitatorEmails.length > 0) {
			school.facilitatorEmails.forEach((email) => {
				const listIndex = facilitatorList.findIndex((f) => {return (f.email === email);});
				if (listIndex < 0) {
					facilitatorList.push({email: email, schools: school.name});
				} else {
					facilitatorList[listIndex].schools += ', ' + school.name;
				}
			});
		}
	});
	
	/**
	 * Handle input of new facilitator e-mail
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		setFacilitatorEmail(event.target.value);
		setErrorMessage(null);
	};

	/**
	 * Toggle school on/off
	 * @param {string} schoolId 
	 */
	const toggleSchoolId = (schoolId) => {
		const newSchoolIds = JSON.parse(JSON.stringify(schoolIds));
		const schoolIndex = newSchoolIds.indexOf(schoolId);
		if (schoolIndex >= 0) {
			newSchoolIds.splice(schoolIndex, 1);
		} else {
			newSchoolIds.push(schoolId);
		}
		setSchoolIds(newSchoolIds);
	};

	/**
	 * Add facilitator to school(s)
	 * @returns 
	 */
	const handleAddFacilitator = () => {
		/* Already adding */
		if (isAddingFacilitator) return;

		/* No schools selected */
		if (schoolIds.length === 0) return;

		setIsAddingFacilitator(true);
		
		let errMsg = null;
		if (!checkIfValidEmail(facilitatorEmail)) {
			/* Invalid e-mail */
			errMsg = getText('error', 'errInvalidEmail');
			setErrorMessage(errMsg);
			setIsAddingFacilitator(false);
		} else {
			/* Check how many schools facilitator already has been added to */
			const numberOfSchools = getNumberOfSchoolsEmailIsFacilitatorOf(facilitatorEmail.toLowerCase(), schools);

			/* Add facilitator to each school */
			let promises = [];
			let alreadyAdded = 0;
			schoolIds.forEach((schoolId) => {
				const schoolData = schools.find((school) => {return school.id === schoolId;});
				if (schoolData) {
					const newFacilitatorEmails = (schoolData.facilitatorEmails 
						? JSON.parse(JSON.stringify(schoolData.facilitatorEmails))
						: []
					);
					if (newFacilitatorEmails.indexOf(facilitatorEmail.toLowerCase()) < 0) {
						newFacilitatorEmails.push(facilitatorEmail.toLowerCase());
						promises.push(updateSchool(schoolData.id, {facilitatorEmails: newFacilitatorEmails}));
					} else {
						alreadyAdded += 1;
					}
				}
			});
			if (promises.length > 0) {
				/* Update schools */
				Promise.all(promises).then(() => {
					if (
						numberOfSchools > 0 || 
						users.some((user) => {return user.email === facilitatorEmail.toLowerCase();})
					) {
						/* User already got welcome mail or already exists, all done */
						setFacilitatorEmail('');
						setIsAddingFacilitator(false);
					} else {
						/* User does not exist, send welcome e-mail */
						apiHelper('schools/send-welcome-mail', 
							{emails: [facilitatorEmail.toLowerCase()]}
						).then((response) => {
							if (response.status === 'success') {
								if (response.error === 'welcome-email-failed') {
									/* Could not send welcome e-mail */
									setErrorMessage(getText('error', 'welcomeMailFailed'));
								}
							} else {
								/* Could not send welcome e-mail */
								setErrorMessage(getText('error', 'welcomeMailFailed'));		
							}
							setFacilitatorEmail('');
							setIsAddingFacilitator(false);
						});
					}
				}).catch((error) => {
					console.error(error);
					setErrorMessage(getText('error', 'unknownError'));	
					setIsAddingFacilitator(false);
				});
			} else {
				/* Nothing to add */
				let errMsg = getText('error', 'unknownError');
				if (alreadyAdded > 0) {
					errMsg = getText('error', 'errEmailAlreadyFacilitatorOfSchool');
				}
				setIsAddingFacilitator(false);
				setErrorMessage(errMsg);
			}	
		}
	};

	return (
		<div className='PopupAddFacilitator'>
			<div className='PopupAddFacilitator-content'>
				{/* Header */}
				<div className='PopupAddFacilitator-header'>
					<span>{getText('adminTexts', 'facilitators')}</span>
				</div>

				{/* Body */}
				<div className='PopupAddFacilitator-body'>
					
					{/* Add new facilitator */}
					<div className="PopupAddFacilitator-addFacilitator">
						<div className="PopupAddFacilitator-title">
							<span>{getText('adminTexts', 'addFacilitatorToSchools')}</span>
						</div>
						<div className="PopupAddFacilitator-addFacilitatorWrap">
							<div className="PopupAddFacilitator-addFacilitatorInput">
								<input
									className='Admin-input'
									name="facilitatorEmail"
									type="text"
									placeholder={''}
									value={facilitatorEmail}
									onChange={handleInput}
								/>
							</div>
							<div className="PopupAddFacilitator-addFacilitatorButton">
								<Button 
									isLoading={isAddingFacilitator}
									text={getText('adminTexts', 'add')}
									classes={['shadow', 'blue', 'responsive']}
									onClick={() => {handleAddFacilitator();}}
								/>
							</div>
						</div>
						<div className="PopupAddFacilitator-addFacilitatorErr">
							{errorMessage && <span>{errorMessage}</span>}
						</div>
						<div className="PopupAddFacilitator-schools">
							{schools.map((school) => {
								const isSelected = schoolIds.indexOf(school.id) >= 0;
								return (
									<div 
										key={school.id}
										className={'PopupAddFacilitator-school' + (isSelected ? ' selected' : '')}
										onClick={() => {toggleSchoolId(school.id);}}
									>
										<span>{school.name}</span>
										<span>&nbsp;</span>
									</div>
								);
							})}
						</div>
					</div>
					
					{/* List of facilitators */}
					<div className="PopupAddFacilitator-facilitators">
						<div className="PopupAddFacilitator-title">{getText('adminTexts', 'facilitators')}</div>
						{facilitatorList.map((facilitator) => {
							return (
								<div key={facilitator.email} className="PopupAddFacilitator-facilitator">
									<div className="PopupAddFacilitator-email"><span>{facilitator.email}</span></div>
									<div className="PopupAddFacilitator-schools">
										<span>{facilitator.schools}</span>
									</div>
								</div>
							);
						})}
					</div>
					
					{/* Buttons */}
					<div className='PopupAddFacilitator-buttons'>
						<div className='PopupAddFacilitator-button'>
							<Button
								text={getText('adminTexts', 'close')}
								classes={['shadow', 'white', 'blueBorder', 'responsive']}
								onClick={() => {setShowAddFacilitatorPopup(false);}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

PopupAddFacilitator.propTypes = {
	schools: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	updateSchool: PropTypes.func.isRequired,
	setShowAddFacilitatorPopup: PropTypes.func.isRequired,
};

export default PopupAddFacilitator; 