const resultsData = [
	{
		id: 'industrialCraft',
		title: 'Industrihåndværk',
		splitTitle: 'Industri-håndværk',
		careerIds: ['blacksmith', 'machinist', 'industrial-operator', 'machine-joiner', 'industrial-technician', 'producer'],
		description: 'Du arbejder med metal, maskiner og produktion på fabrikker. Det kan være i brancher som fødevarer, byggeri eller landbrug.',
		duration: '2,5 til 5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'routines'},
			{keywordId: 'machines'},
			{keywordId: 'practicalWork'},
			{keywordId: 'technique'}
		]					
	},
	{
		id: 'constructionCraft',
		title: 'Håndværk',
		careerIds: ['carpenter', 'painter', 'mason', 'electrician', 'vvs', 'woodworker'],
		description: 'Håndværk dækker over mange forskellige fag, der alle har en klar praktisk dimension. Du arbejder typisk med byggeri eller vedligehold, og der er god mulighed for at starte egen virksomhed.',
		duration: '2,5 til 5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		keywords: [
			{keywordId: 'creating'},
			{keywordId: 'goodHands'},
			{keywordId: 'workingAlone'},
			{keywordId: 'drawing'},
			{keywordId: 'practicalWork'},
			{keywordId: 'building'},
			{keywordId: 'thorough'}
		]
	},
	{
		id: 'cooking',
		title: 'Madlavning',
		careerIds: ['chef', 'bakerPastryChef', 'nutritionist', 'shop-butcher'],
		description: 'Madlavning dækker over forskellige håndværk, der alle handler om fødevarer og ernæring. Arbejdet foregår typisk i butik, på restauranter, på fabrikker eller i storkøkkener.',
		duration: '2,5 til 5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		keywords: [
			{keywordId: 'cooking'},
			{keywordId: 'practicalWork'},
			{keywordId: 'creating'},
			{keywordId: 'creative'},
			{keywordId: 'routines'},
		]
	},
	{
		id: 'construction-practical',
		title: 'Byggeri',
		careerIds: ['structurer', 'mason', 'crane-operator', 'landscape-gardener', 'glazier'],
		description: 'Du kan arbejde i alle faser af byggeriet. Du støber fundament, bygger vægge, laver færdiggørelse eller står for de grønne arealer.',
		duration: '2,5 til 5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		keywords: [
			{keywordId: 'workingOutside'},
			{keywordId: 'goodHands'},
			{keywordId: 'creating'},
			{keywordId: 'machines'},
			{keywordId: 'independent'},
			{keywordId: 'routines'},
			{keywordId: 'practicalWork'},
		]
	},
	{
		id: 'health-and-care',
		title: 'Sundhed og omsorg',
		description: 'Du arbejder med sundhed og sygepleje på klinikker, i ældreplejen, på skoler, på sygehuse, på specialcentre eller som selvstændig.',
		duration: '2 år og 2 måneder til 4 år og 7 måneder',
		education: 'Erhversskole (EUX eller EUD) eller professionshøjskole',
		careerIds: ['sosu-assistant', 'sosu-helper', 'dental-clinic-assistant', 'orthopaedist', 'physiotherapist', 'health-nurse'],
		keywords: [
			{keywordId: 'helping'},
			{keywordId: 'practicalWork'},
			{keywordId: 'health'},
			{keywordId: 'social'},
			{keywordId: 'people'},
			{keywordId: 'shortEducation'},
		]
	},
	{
		id: 'health-science-and-healing',
		title: 'Sundhed',
		description: 'Du arbejder med sundhed, sygdom, pleje og helbredelse, ofte på hospitaler eller i private klinikker. Du kan også arbejde i for eksempel medicinalindustrien.',
		duration: '3,5 til 14 år',
		education: 'Universiteter eller professionshøjskoler',
		careerIds: ['nurse', 'doctor', 'dentist', 'public-health-science'],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'helping'},
			{keywordId: 'health'},
			{keywordId: 'responsibility'},
			{keywordId: 'people'},
		]
	},
	{
		id: 'lab-work',
		title: 'Laboratoriearbejde',
		splitTitle: 'Laboratorie-arbejde',
		description: 'Du arbejder med analyse, prøvetagning, kontrol, forskning og andet. Du arbejder typisk i sundhedssektoren eller medicinalindustrien, med fødevarer eller i den kemiske industri.',
		duration: '2,5 til 3,5 år',
		education: 'Professionshøjskole eller erhvervsakademi',
		careerIds: ['pharmacologist', 'bio-analyst', 'lab-technician'],
		keywords: [
			{keywordId: 'thorough'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'practicalWork'},
			{keywordId: 'science'},
			{keywordId: 'routines'},
			{keywordId: 'health'},
		]
	},
	{
		id: 'architecture',
		title: 'Arkitektur og bygningsplanlægning',
		splitTitle: 'Arkitektur og bygnings-planlægning',
		description: 'Du arbejder med planlægning af byggeprojekter. Du designer bygninger eller landskaber, koordinerer og projekterer i forskellige faser af byggeriet.',
		duration: '3-5 år',
		education: 'Erhvervsakademi eller arkitektskole',
		careerIds: ['architect', 'building-constructor', 'engineer', 'construction-technician'],
		keywords: [
			{keywordId: 'creating'},
			{keywordId: 'systematic'},
			{keywordId: 'math'},
			{keywordId: 'responsibility'},
			{keywordId: 'drawing'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'building'},
		]
	},
	{
		id: 'transport',
		title: 'Transport',
		description: 'Du kører med passagerer i bus eller tog eller fragter gods i lastbiler enten i Danmark eller internationalt.',
		duration: '10 måneder til 5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		careerIds: ['busDriver', 'truckDriver', 'trainDriver'],
		keywords: [
			{keywordId: 'workingAlone'},
			{keywordId: 'routines'},
			{keywordId: 'machines'},
			{keywordId: 'practicalWork'},
			{keywordId: 'shortEducation'},
		]
	},
	{
		id: 'agriculture',
		title: 'Jordbrug',
		description: 'Du arbejder med planter, dyrehold, dyrkning eller naturpleje. Arbejdet foregår typisk udenfor i skov, på landbrug, i haver eller lignende.',
		duration: 'ca. 2,5 til 5,5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		careerIds: ['farmer', 'gardener', 'horticulture-consultant', 'forest-nature-technician'],
		keywords: [
			{keywordId: 'workingOutside'},
			{keywordId: 'nature'},
			{keywordId: 'independence'},
			{keywordId: 'workingAlone'},
			{keywordId: 'practicalWork'},
			{keywordId: 'routines'},
			{keywordId: 'creating'},
		]
	},
	{
		id: 'humanities',
		title: 'Humaniora på universitetet',
		description: 'Humaniora handler om sprog, historie, menneskelige værdier, kunst, formidling og meget andet. Du kan arbejde som blandt andet underviser, forsker, projektleder, konsulent mm. i både det private og det offentlige.',
		duration: '3-5 år',
		education: 'Universiteter',
		careerIds: ['language-courses', 'history', 'art-and-culture-courses', 'psychology', 'pedagogy', 'communication', 'media-studies', 'philosophy', 'history-of-ideas'],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'analytical'},
			{keywordId: 'reading'},
			{keywordId: 'people'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
			{keywordId: 'culture'},
		]
	},
	{
		id: 'natural-science',
		title: 'Naturvidenskab',
		description: 'De naturvidenskabelige fag handler om observationer og eksperimenter, dataindsamling og om at undersøge og beskrive naturen. Du kan arbejde som blandt andet underviser, forsker eller projektleder eller med udvikling af produkter, kvalitetssikring og meget andet.',
		duration: '3-5 år',
		education: 'Universiteter',
		careerIds: ['engineer', 'chemistry', 'physics', 'math', 'biology', 'geography', 'geology', 'nanoscience', 'biotech', 'computer-science'],
		keywords: [
			{keywordId: 'math'},
			{keywordId: 'analytical'},
			{keywordId: 'science'},
			{keywordId: 'bookish'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'manyOpportunities'},
		]
	},
	{
		id: 'social-science',
		title: 'Samfundsvidenskab',
		splitTitle: 'Samfunds-videnskab',
		description: 'De samfundsvidenskabelige fag handler om samfundets opbygning og institutioner, forvaltning, organisation, jura og meget andet. Du kan arbejde som underviser, forsker, leder, sagsbehandler, administrator, jurist mm.',
		duration: '3-5 år',
		education: 'Universiteter',
		careerIds: ['sociology', 'law', 'administration', 'economy', 'socialstudies', 'political-science', 'social-science'],
		keywords: [
			{keywordId: 'bookish'},
			{keywordId: 'politics'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'analytical'},
			{keywordId: 'reading'},
			{keywordId: 'manyOpportunities'},
		]
	},
	{
		id: 'business',
		title: 'Business',
		description: 'Du arbejder med forretning, projektledelse, udvikling, administration, handel, kundehåndtering, projektledelse og meget andet. Du vil typisk få arbejde i det private eller måske starte din egen virksomhed.',
		duration: '3-5 år',
		education: 'Erhvervsskoler (EUD og EUX) og universiteter',
		careerIds: ['office-education', 'finance-education', 'business-economics', 'business-education'],
		keywords: [
			{keywordId: 'business'},
			{keywordId: 'pay'},
			{keywordId: 'officeWork'},
			{keywordId: 'math'},
			{keywordId: 'spotlight'},
			{keywordId: 'independent'},
		]
	},
	{
		id: 'salesAndService',
		title: 'Salg og service',
		description: 'Du hjælper kunder og laver salgsarbejde, uanset om det er i en butik, på en restaurant, som frisør, på et kontor eller andet. Du arbejder typisk i det private og har fokus på at levere service og sørge for, at folk får en god oplevelse.',
		duration: '2-5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		careerIds: ['storeEmployee', 'waiter', 'hairdresser', 'office-education', 'business-education', 'receptionist'],
		keywords: [
			{keywordId: 'spotlight'},
			{keywordId: 'independent'},
			{keywordId: 'routines'},
			{keywordId: 'helping'},
			{keywordId: 'business'},
		]
	},
	{
		id: 'administration-and-accounting',
		title: 'Administration og regnskab',
		description: 'Du arbejder med regnskaber, økonomistyring, bogholderi, rådgivning og meget andet. Du kan arbejde i både det private eller det offentlige.',
		duration: '2-5 år',
		education: 'Erhvervsskoler (EUD og EUX) og universiteter',
		careerIds: ['controller', 'accountant', 'accountant2', 'public-administration', 'business-economics', 'financial-controller'],
		keywords: [
			{keywordId: 'systematic'},
			{keywordId: 'thorough'},
			{keywordId: 'routines'},
			{keywordId: 'officeWork'},
			{keywordId: 'business'},
		]
	},
	{
		id: 'pedagogyAndTeaching',
		title: 'Pædagogik og undervisning',
		description: 'Du arbejder med at undervise og udvikle børn, unge og voksne. Du arbejder med omsorg, støtte og formidling og har typisk arbejde i en institution, på en skole eller et uddannelsessted.',
		duration: '3-6 år',
		education: 'Erhvervsskoler (EUD og EUX), professionshøjskoler og universiteter',
		careerIds: ['teacher', 'pedagogue', 'highSchoolTeacher', 'pedagogy-assistant'],
		keywords: [
			{keywordId: 'social'},
			{keywordId: 'spotlight'},
			{keywordId: 'communication'},
			{keywordId: 'helping'},
			{keywordId: 'people'},
			{keywordId: 'independence'}
		]
	},
	{
		id: 'itAndProgramming',
		title: 'IT og programmering',
		description: 'Du arbejder med udvikling og vedligeholdelse af websites, applikationer og it-systemer. Du er typisk ansat i private virksomheder, men kan også være selvstændig konsulent.',
		duration: '2 år og 3 måneder til 5 år.',
		education: 'Erhvervsskoler (EUD og EUX) og universiteter',
		careerIds: ['web-developer', 'programmer', 'gameDesigner', 'computer-scientist'],
		keywords: [
			{keywordId: 'analytical'},
			{keywordId: 'thorough'},
			{keywordId: 'computers'},
			{keywordId: 'routines'},
			{keywordId: 'officeWork'},
			{keywordId: 'creativeWork'},
			{keywordId: 'intellectualChallenges'}
		]
	},
	{
		id: 'journalismAndCommunication',
		title: 'Journalistik og kommunikation',
		description: 'Du arbejder med at skrive tekster, indsamle information og formidling på forskellige platforme. Du arbejder i mediehuse, private virksomheder, det offentlige eller som selvstændig.',
		duration: '3-5 år',
		education: 'Danmarks Medie- og Journalisthøjskole og universiteter',
		careerIds: ['reporter', 'communications-officer', 'writer'],
		keywords: [
			{keywordId: 'writing'},
			{keywordId: 'communication'},
			{keywordId: 'creative'},
			{keywordId: 'reading'},
			{keywordId: 'politics'},
			{keywordId: 'independence'},
			{keywordId: 'creativeWork'}
		]
	},
	{
		id: 'design-and-graphics',
		title: 'Design og grafik',
		description: 'Du arbejder med design af websider, magasiner, tøj, møbler, brugeroplevelser og meget andet. Du arbejder med former og farver i forskellige materialer og på forskellige platforme alt efter din konkrete uddannelse.',
		duration: '2-5 år',
		education: 'Erhvervsskoler (EUD og EUX), Designskolen i Kolding og Det Kongelige Akademi',
		careerIds: ['designer', 'media-graphic-designer', 'graphic-layouter', 'ui-developer', 'web-developer', 'ux-developer', 'multimedia-designer'],
		keywords: [
			{keywordId: 'drawing'},
			{keywordId: 'communication'},
			{keywordId: 'computers'},
			{keywordId: 'creating'},
			{keywordId: 'creativeWork'},
			{keywordId: 'creative'},
		]
	},
	{
		id: 'nature-communication',
		title: 'Naturformidling',
		description: 'Du arbejder med at skabe gode naturoplevelser og formidler viden om naturen til børn, unge og voksne. Derfor foregår en stor del af dit arbejde også ude i naturen.',
		duration: '3,5-5 år',
		education: 'Professionshøjskoler og universiteter',
		careerIds: ['nature-guide', 'nature-educator', 'biologist', 'teacher'],
		keywords: [
			{keywordId: 'people'},
			{keywordId: 'nature'},
			{keywordId: 'environment'},
			{keywordId: 'communication'},
			{keywordId: 'workingOutside'},
			{keywordId: 'spotlight'},
		]
	},
	{
		id: 'art-culture-production-technique',
		title: 'Produktion/teknik indenfor kunst og kultur',
		splitTitle: 'Produktion / teknik indenfor kunst og kultur',
		description: 'Du arbejder med den praktiske del af teater-, film- og tv-produktion. Du står for planlægning, lys og lyd, sceneteknik eller andet. Du arbejder typisk i film- og tv-selskaber, på teatre eller som freelance på events, koncerter og andet.',
		duration: '2,5-5,5 år',
		education: 'Erhvervsuddannelser (EUD og EUX)',
		careerIds: ['film-and-tv-production', 'lighting-technician', 'av-technician'],
		keywords: [
			{keywordId: 'thorough'},
			{keywordId: 'creative'},
			{keywordId: 'culture'},
			{keywordId: 'technique'},
			{keywordId: 'goodHands'},
			{keywordId: 'creativeWork'},
			{keywordId: 'practicalWork'}
		]
	},
	{
		id: 'art-culture-academic-work',
		title: 'Kunst og kultur - akademisk arbejde',
		description: 'Du arbejder med formidling, administration, pressearbejde, projektledelse og lignende på fx teatre, museer eller i en kommune. Du har typisk en akademisk uddannelse - fx indenfor et kunst- eller kulturfag.',
		duration: '3-5 år',
		education: 'Universiteter',
		careerIds: ['communications-officer', 'communicator', 'cultural-worker'],
		keywords: [
			{keywordId: 'communication'},
			{keywordId: 'writing'},
			{keywordId: 'culture'},
			{keywordId: 'intellectualChallenges'},
			{keywordId: 'creativeWork'},
			{keywordId: 'officeWork'},
		]
	},
	{
		id: 'project-management',
		title: 'Projektledelse',
		description: 'Som projektleder har du ansvaret for at planlægge og styre et projekt. Det kan være et byggeri, et it-projekt, udviklingen af et nyt produkt, en undersøgelse eller meget andet.',
		education: 'Uddannelsesvejen afhænger af branchen, du vil arbejde indenfor. Projektledere har ofte en videregående uddannelse.',
		careerIds: ['project-leader', 'consultant', 'engineer', 'building-constructor'],
		keywords: [
			{keywordId: 'analytical'},
			{keywordId: 'teamwork'},
			{keywordId: 'startingThings'},
			{keywordId: 'responsibility'},
			{keywordId: 'officeWork'},
			{keywordId: 'independence'},
		]
	},
	{
		id: 'police-and-safety',
		title: 'Politi og sikkerhed',
		description: 'Du arbejder med nødhjælp, redning, efterforskning, sikkerhed og forebyggelse. Du er typisk ansat i politiet, i ambulancetjenester, brandvæsenet eller forsvaret.',
		duration: 'ca. 6 måneder til 5 år',
		education: 'Politiskolen, erhvervsskoler (EUD), Beredskabet eller Forsvaret',
		careerIds: ['police', 'ambulance', 'fire', 'ambulance-rescuer', 'defense'],
		keywords: [
			{keywordId: 'practicalWork'},
			{keywordId: 'people'},
			{keywordId: 'helping'},
			{keywordId: 'responsibility'},
			{keywordId: 'teamwork'},
		]
	},
	{
		id: 'environment-and-climate',
		title: 'Miljø og klima',
		description: 'Du arbejder med grøn omstilling, bæredygtighed, prøvetagning, naturgenopretning og meget andet. Du kan arbejde i felten, som underviser og formidler, som forsker, i grønne virksomheder mv.',
		duration: '2-5 år',
		education: 'Erhvervsskoler (EUD) og universiteter',
		careerIds: ['engineer', 'biologist', 'researcher', 'environmental-technologist'],
		keywords: [
			{keywordId: 'analytical'},
			{keywordId: 'math'},
			{keywordId: 'science'},
			{keywordId: 'environment'},
			{keywordId: 'nature'},
			{keywordId: 'technique'},
			{keywordId: 'pay'},
			{keywordId: 'intellectualChallenges'},
		]
	},
	{
		id: 'personal-consultation',
		title: 'Personlig rådgivning',
		description: 'Du arbejder med mennesker med forskellige udfordringer. Du kan lave sagsbehandling, forebyggelse, helbredelse eller vejledning, og det kan både være som ansat i kommune, kirke eller som selvstændig.',
		duration: '3,5-5 år',
		education: 'Professionshøjskoler og universiteter',
		careerIds: ['social-worker', 'coach', 'priest', 'psychologist', 'case-manager', 'counseler'],
		keywords: [
			{keywordId: 'social'},
			{keywordId: 'communication'},
			{keywordId: 'people'},
			{keywordId: 'helping'},
			{keywordId: 'officeWork'},
			{keywordId: 'variation'},
		]
	},
	{
		id: 'production-machine-operator',
		title: 'Produktion/maskinoperatør',
		splitTitle: 'Produktion / maskinoperatør',
		description: 'Du arbejder med maskiner og hel- eller halvautomatiske produktionsanlæg. Du arbejder typisk med fremstilling af fx elektronik og fødevarer, i autobranchen eller i jern- og metalindustrien.',
		duration: '2,5-6 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		careerIds: ['industrial-operator', 'plastic-maker', 'industrial-technician', 'production-and-assembly'],
		keywords: [
			{keywordId: 'thorough'},
			{keywordId: 'goodHands'},
			{keywordId: 'machines'},
			{keywordId: 'technique'},
			{keywordId: 'practicalWork'},
			{keywordId: 'routines'},
			{keywordId: 'shortEducation'},
		]
	},
	{
		id: 'machines-and-cars',
		title: 'Arbejde med maskiner og biler',
		description: 'Du arbejder med biler, motorer og lignende maskiner og laver både service, produktion og vedligeholdelse. Du arbejder typisk på værksteder eller fabrikker.',
		duration: '2-5 år',
		education: 'Erhvervsskoler (EUD og EUX)',
		careerIds: ['car-painter', 'mechanic', 'boat-mechanic', 'car-assistance', 'automotive-technologist'],
		keywords: [
			{keywordId: 'goodHands'},
			{keywordId: 'machines'},
			{keywordId: 'technique'},
			{keywordId: 'building'},
			{keywordId: 'practicalWork'},
			{keywordId: 'routines'},
		]
	},
	{
		id: 'own-company',
		title: 'Egen virksomhed',
		description: 'Mange uddannelser kan være en solid basis, hvis du gerne vil starte egen virksomhed - blandt andet håndværksuddannelser, uddannelser inden for business og salg, rådgivning, kommunikation og meget andet. Som selvstændig har du stor frihed, men skal typisk også arbejde med mange forskellige opgaver såsom salg, administration, markedsføring osv.',
		careerIds: [],
		keywords: [
			{keywordId: 'independent'},
			{keywordId: 'spotlight'},
			{keywordId: 'startingThings'},
			{keywordId: 'business'},
			{keywordId: 'independence'},
			{keywordId: 'responsibility'},
			{keywordId: 'workingAlone'},
		]
	},
	{
		id: 'sport',
		title: 'Noget med sport',
		description: 'Det kan være, du gerne vil være professionel sportsudøver, men der er også andre måder at arbejde med og tæt på sport på. Det kan for eksempel være som træner, behandler, rådgiver eller andet.',
		duration: 'ca. 3,5 år',
		education: 'Erhvervsskoler (EUD og EUX) og professionshøjskoler',
		careerIds: ['physiotherapist', 'trainer', 'pe-teacher', 'personal-trainer', 'dietary-advisor'],
		keywords: [
			{keywordId: 'sport'},
			{keywordId: 'people'},
			{keywordId: 'routines'},
			{keywordId: 'community'},
			{keywordId: 'practicalWork'}
		]
	}
];

export {
	resultsData,
};