const module3Tasks = [
	{
		id: 'calculate-result',
		type: 'calculate-result',
		title: 'Beregner dit resultat ...',
		saveStatistics: true,
	},
	{
		id: 'results-keywords-skills',
		type: 'results-keywords',
		title: 'Din profil 1/3',
		categoryId: 'skills',
	},
	{
		id: 'results-keywords-interests',
		type: 'results-keywords',
		title: 'Din profil 2/3',
		categoryId: 'interests',
	},
	{
		id: 'results-keywords-preferences',
		type: 'results-keywords',
		title: 'Din profil 3/3',
		categoryId: 'preferences',
	},
	{
		id: 'results-keywords-all',
		type: 'results-keywords-all',
		title: 'Din profil',
	},
	{
		id: 'results-careers', 
		type: 'results-careers',
		title: 'Karriereveje',
		subtitle: `Her er de tre karriereveje, som er et særligt godt match til dig. Første match er det bedste, andet det næstbedste osv. Tryk på pilen for at se flere resultater. Måske er der noget, du synes passer endnu bedre.`
	},
	{
		id: 'results-next-step',
		type: 'results-next-step',
		title: 'Ungdomsuddannelser',
		subtitle: `Her er de tre ungdomsuddannelser, som matcher dig særligt godt. Første match er det bedste, andet det næstbedste osv. Tryk på pilen for at se flere resultater.`
	},
	{
		id: 'results-overview',
		type: 'results-overview'
	}
];

export {
	module3Tasks
};
