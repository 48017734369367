/**
 * Generate unique id from string
 * @param {string} seed
 * @param {array} exisitingIds
 * @returns 
 */
const generateUniqueIdFromString = (seed, existingIds) => {
	/* Convert to lowercase, trim leading/trailing spaces, replace space with '-', remove all invalid characters */
	const base = seed.toLowerCase().trim().replace(/[ ]+/g, '-').replace(/[^a-z0-9-]+/g, '');
	
	let suffix = 0;
	const promise = new Promise((resolve, reject) => {
		const checkId = () => {
			const tempId = base + (suffix > 0 ? '-' + suffix : '');

			if (existingIds.some((id) => {return id === tempId;})) {
				suffix += 1;
			} else {
				resolve({ id: tempId });

			}
		};
		checkId();
	});
	return promise;
};


/**
 * Check if a tag is in use by any schools
 * @param {string} tagId 
 * @param {array} schools 
 * @param {string}
 * @returns 
 */
const checkIfTagIsInUse = (tagId, schools, excludeSchoolId = null) => {
	let tagIsInUse = false; 
	
	if (schools && schools.some((school) => {
		return (
			school &&
			school.tagIds && 
			school.tagIds.length > 0 && 
			school.tagIds.indexOf(tagId) >= 0 &&
			school.id !== excludeSchoolId
		);
	})) {
		/* Tag is not in use by any school */
		tagIsInUse = true;
	};

	return tagIsInUse;
};

/**
 * Get the number of schools and e-mail is facilitator of
 * @param {string} email 
 * @param {array} schools 
 * @returns 
 */
const getNumberOfSchoolsEmailIsFacilitatorOf = (email, schools) => {
	let numberOfSchools = 0;
	schools.forEach((school) => {
		if (
			school.facilitatorEmails && 
			school.facilitatorEmails.length > 0 &&
			school.facilitatorEmails.indexOf(email) >= 0
		) {
			numberOfSchools += 1;
		}
	});

	return numberOfSchools;
};


export {
	generateUniqueIdFromString,
	checkIfTagIsInUse,
	getNumberOfSchoolsEmailIsFacilitatorOf
};