import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {calculateTaskStats} from 'helpers/stats-helper';
import {taskTypesData} from 'data/task-types-data';
import './admin-tasks.scss';

const AdminTasks = ({tasks}) => {
	/* Task type navigation */
	const [taskTypeId, setTaskTypeId] = useState(taskTypesData[0].id);

	/* Selected task type data */
	const taskTypeData = taskTypesData.find((taskType) => {return taskType.id === taskTypeId;});

	/* Get task stats */
	const taskStats = calculateTaskStats(tasks);

	return (
		<div className='AdminTasks'>
			{/* Navigation */}
			<div className='AdminTasks-nav'>
				{taskTypesData.map((taskType) => {
					return (
						<div 
							key={taskType.id}
							className={'AdminTasks-navItem' + (taskTypeId === taskType.id ? ' selected' : ' ')}
							onClick={() => {setTaskTypeId(taskType.id);}}
						>
							<span>{taskType.title}</span>
						</div>	
					);				
				})}
			</div>

			{/* Tasks table */}
			<div className={'AdminTasks-table ' + taskTypeId}>
				{/* Header */}
				<div className='AdminTasks-header'>
					{taskTypeData.columns.map((column) => {
						return (
							<div key={column.id} className={'AdminTasks-title ' + column.id}>
								<span>{column.title}</span>
							</div>
						);
					})}
				</div>
				<div className='AdminTasks-body'>
					{/* Loop over all tasks of selected type */}
					{taskStats[taskTypeId] && taskStats[taskTypeId].map((taskStat, index) => {
						const zIndex = 100 - index;
						return (
							<div key={index} className='AdminTasks-row' style={{zIndex: zIndex}}>
								{taskTypeData.columns.map((column, index) => {
									/* Nothing to show, fx if task does not have an option 8 */
									if (!taskStat.hasOwnProperty(column.statProp)) {
										return (
											<div key={column.id} className={'AdminTasks-value ' + column.id} >
												<span>-</span>
											</div>
										);
									}

									/* Get z-index - helps with layout of hover text / image */
									const zIndex = (column.statProp === 'text' ? 20 : index + 1);

									/* Get hover text / image */
									let hoverText = null;
									let hoverImage = null;
									if (column.statProp === 'text') {
										hoverText = taskStat[column.statProp];
									} else if (taskStat.hasOwnProperty(column.statProp + '-text')) {
										hoverText = taskStat[column.statProp + '-text'];
									} else if (taskStat.hasOwnProperty(column.statProp + '-image')) {
										try {
											hoverImage = 
												require('../../../assets/images/modules/tasks/multiple-choice/' 
													+ taskStat[column.statProp + '-image']);
										} catch (error) {
											console.error('Could not get image: ' 
												+ taskStat[column.statProp + '-image']);
										}
									}

									return (
										<div 
											key={column.id} 
											className={'AdminTasks-value ' + column.id 	
												+ (hoverImage ? ' image' : '')
												+ (hoverText || hoverImage ? ' hoverEffect' : '')}
											style={{zIndex: zIndex}}
										>
											<span>{taskStat[column.statProp]}</span>
											{hoverText && <span>{hoverText}</span>}
											{hoverImage && <span><img src={hoverImage} alt="" /></span>}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

AdminTasks.propTypes = {
	tasks: PropTypes.array.isRequired,
};

export default AdminTasks;