import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import { getPointOnCircle } from 'helpers/math-helper';
import { keywordCategories } from 'data/keywords-data';
import Button from '../button/button';
import './keyword-category.scss';

const KeywordCategory = ({keywordsData, categoryId}) => {
	const startAngle = 90;
	const centerPoint = {x: 0, y: 0};
	const keywordsAmount = 6;
	const totalAngle = 180;
	const baseLineLength = 13;
	const circleMaxSize = 4.6;
	const sizeIncrement = 0.6;
	const [isOpen, setIsOpen] = useState(false);

	const category = keywordCategories.find((c) => {
		return categoryId === c.id;
	});

	// Reset on category change
	useEffect(() => {
		setIsOpen(false);
	}, [categoryId]);

	return (
		<div className={'KeywordCategory ' + (isOpen ? ' active' : '')}>
			{!isOpen && keywordsData.slice(0, keywordsAmount).map((keyword, index) => {
				// calculating angle between points
				const angle = totalAngle / (keywordsAmount - 1) * index;
				let keywordPoint = getPointOnCircle(centerPoint, baseLineLength, angle - startAngle);
				let circleSize = circleMaxSize - (sizeIncrement * index);

				let isOnLeftSide = index + 1 <= keywordsAmount / 2;

				return (
					<div key={keyword.keywordId} className='KeywordCategory-keywordWrapper'>
						<div className={'KeywordCategory-line ' + category.id} 
							style={{
								transform: 'rotate(' + (-90 - angle + startAngle) + 'deg)',
								width: baseLineLength + 'em',
							}}
						/>

						<div className={'KeywordCategory-keyword ' + category.id}
							style={{
								top: (keywordPoint.y - circleSize / 2) + 'em', 
								left: (keywordPoint.x - circleSize / 2) + 'em',
								width: circleSize + 'em',
								height: circleSize + 'em'
							}}
						>
							<div 
								className={'KeyWordCategory-name ' + 
									category.id + 
									(isOnLeftSide ? ' leftAlign' : ' rightAlign')
								} 
								style={{top: circleSize + 'em'}}>
								<span>{keyword.name}</span>
							</div>
						</div>
					</div>
				);
			})}
			{category && 
				<div className={'KeywordCategory-bubble ' + (isOpen ? ' active' : ' inactive')} 
					onClick={() => {setIsOpen(true);}}
				>
					<div className={'KeywordCategory-title ' + (isOpen ? ' active' : '')}>
						<span>{category.name}</span>
					</div>
					
					{!isOpen &&
						<>
							<div className='KeywordCategory-description'>
								<span>{category.description}</span>
							</div>
							<div className="KeywordCategory-moreBtn">
								<Button 
									classes={[category.id, 'rounded', 'variableHeight', 'smallFontSize']}
									text={getText('gameUi', 'viewMore')}
									onClick={() => {}}
								/>
							</div>
						</>
					}

					{isOpen &&
						<div className={'KeywordCategory-bubbleContentWrapper ' + category.id}>
							<div className='KeywordCategory-keywordList'>
								{keywordsData.map((keyword, index) => {
									return (
										<div key={index} className='KeywordCategory-keywordListItem'>
											<div className={'KeywordCategory-keywordCircle ' + category.id}/>
											<div className='KeywordCategory-keywordName'>
												<span>{keyword.name}</span>
											</div>
											<div className='KeywordCategory-keywordPercentage'>
												<span>{keyword.percent + '%'}</span>
											</div>
										</div>
									);
								})}
							</div>
							
							<div className={'KeywordCategory-closeBtn'}
								onClick={(e) => {
									e.stopPropagation();
									setIsOpen(false);
								}}
							/>
						</div>
					}
				</div>
			}
		</div>
	);
};

KeywordCategory.propTypes = {
	keywordsData: PropTypes.array.isRequired,
	categoryId: PropTypes.string.isRequired,
};

export default KeywordCategory;