const taskTypesData = [
	{
		id: 'multiple-choice',
		title: 'Multiple Choice',
		columns: [
			{
				id: 'text',
				title: 'Spørgsmål',
				statProp: 'text'
			},
			{
				id: 'option-1',
				title: '1',
				statProp: 'option-1',
			},
			{
				id: 'option-2',
				title: '2',
				statProp: 'option-2',
				
			},
			{
				id: 'option-3',
				title: '3',
				statProp: 'option-3',
				
			},
			{
				id: 'option-4',
				title: '4',
				statProp: 'option-4',
				
			},
			{
				id: 'option-5',
				title: '5',
				statProp: 'option-5',
				
			},
			{
				id: 'option-6',
				title: '6',
				statProp: 'option-6',
				
			},
			{
				id: 'option-7',
				title: '7',
				statProp: 'option-7',
				
			},
			{
				id: 'option-8',
				title: '8',
				statProp: 'option-8',
			}

		]
	},
	{
		id: 'slider',
		title: 'Slider',
		columns: [
			{
				id: 'text',
				title: 'Spørgsmål',
				statProp: 'text'
			},
			{
				id: 'average',
				title: 'Gennemsnit',
				statProp: 'average'
			}
		]
	},
	{
		id: 'sort',
		title: 'Sorter',
		columns: [
			{
				id: 'text',
				title: 'Spørgsmål',
				statProp: 'text'
			},
		]
	},
	{
		id: 'order',
		title: 'Rækkefølge',
		columns: [
			{
				id: 'text',
				title: 'Spørgsmål',
				statProp: 'text'
			},
			{
				id: 'option-1',
				title: '1',
				statProp: 'option-1',
			},
			{
				id: 'option-2',
				title: '2',
				statProp: 'option-2',
				
			},
			{
				id: 'option-3',
				title: '3',
				statProp: 'option-3',
				
			},
			{
				id: 'option-4',
				title: '4',
				statProp: 'option-4',
				
			},
			{
				id: 'option-5',
				title: '5',
				statProp: 'option-5',
				
			},
			{
				id: 'option-6',
				title: '6',
				statProp: 'option-6',
				
			}
		]
	}
];

export {
	taskTypesData,
};