/**
 * converts degrees to radians
 * @param {number} degrees 
 * @returns returns radians
 */
export function degreesToRadians (degrees) {
	return degrees * (Math.PI / 180);
};

/**
 * Calculates point on circle from radius, angle and circle center point
 * @param {object} centerPoint object containing x and y point position 
 * @param {number} radius 
 * @param {number} angle 
 * @returns object containing x and y point coordinates
 */
export function getPointOnCircle (centerPoint, radius, angle) {
	const x = radius * Math.sin(degreesToRadians(angle)) + centerPoint.x;
	const y = radius * Math.cos(degreesToRadians(angle)) + centerPoint.y;

	return {x: x, y: y};
};

/**
 * Calculates distance between two points
 * @param {object} pointA object containing x and y point position 
 * @param {object} pointB object containing x and y point position 
 * @returns number
 */
export function getDistanceBetweenPoints (pointA, pointB) {
	const xDif = pointA.x - pointB.x;
	const yDif = pointA.y - pointB.y;
	
	return Math.sqrt(Math.pow(xDif, 2), Math.pow(yDif, 2));
};