import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import resetAnimation from 'helpers/animation-helper';
import {ReactComponent as GameLogo} from '../../../assets/images/game-logo.svg';
import './background.scss';

const Background = ({isLandingPage, isBackgroundTransitioning, setIsBackgroundTransitioning}) => {
	const [initialAnimation, setInitialAnimation] = useState(false);

	useEffect(() => {
		if (isBackgroundTransitioning) {
			setIsBackgroundTransitioning(false);

			if (!initialAnimation) {
				setInitialAnimation(true);
			} else {
				resetAnimation('centerCircle');
			}
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isBackgroundTransitioning]);


	return (
		<div className='Background'>
			<div className={'Background-blueMargin ' + (isLandingPage ? 'large' : 'small')}>
				<div className={'Background-blueCircle ' + (isLandingPage ? '' : 'inactive')}/>
			</div>
			<GameLogo className={'Background-gameLogo ' + (isLandingPage ? 'large animated' : 'small')} />
			<div id={'centerCircle'} 
				className={
					'Background-centerCircle ' + 
					(isLandingPage ? 'small' : 'large') + 
					(initialAnimation ? ' animate' : '')
				}
			/>
			<div className={'Background-fragmentedCircle ' + (isLandingPage ? '' : 'inactive')}/>
		</div>
	);
};

Background.propTypes = {
	isLandingPage: PropTypes.bool.isRequired,
	isBackgroundTransitioning: PropTypes.bool.isRequired,
	setIsBackgroundTransitioning: PropTypes.func.isRequired,
};

export default Background;