import React from 'react';
import PropTypes from 'prop-types';
import {calculateMaxKeywordPoints} from 'helpers/keywords-helpers';
import {keywordsData} from 'data/keywords-data';
import './popup-keywords.scss';

const PopupKeywords = ({setShowKeywordsPopup}) => {
 
	return (
		<div className="PopupKeywords" onClick={() => {setShowKeywordsPopup(false);}}>
			<div className="PopupKeywords-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="PopupKeywords-closeBtn" onClick={() => {setShowKeywordsPopup(false);}}/>
				<div className="PopupKeywords-text">
					{keywordsData.map((keyword) => {
						const maxPoints = calculateMaxKeywordPoints(keyword.id);
						return (
							<div key={keyword.id} className="PopupKeywords-keyword">
								<span>{keyword.title}</span>
								<span>{maxPoints}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

PopupKeywords.propTypes = {
	setShowKeywordsPopup: PropTypes.func.isRequired,
};

export default PopupKeywords;
