import React, {useState} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import apiHelper from 'helpers/api-helper';
import {checkIfValidEmail} from 'helpers/format-helper';
import {
	generateUniqueIdFromString, 
	checkIfTagIsInUse, 
	getNumberOfSchoolsEmailIsFacilitatorOf
} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import Select from 'components/ui/select/select';
import SchoolDocument from 'components/pdf-styles/school-pdf';
import './popup-school.scss';

const PopupSchool = ({schoolData, users, schools, tagsData, updateSchool, setSelectedSchoolId}) => {
	/* Add facilitator email(s) and status */
	const [facilitatorEmails, setFacilitatorEmails] = useState('');
	const [isAddingFacilitators, setIsAddingFacilitators] = useState(false);
	const [facilitatorEmailsErr, setFacilitatorEmailsErr] = useState(null);

	/* Remove facilitator status */
	const [isRemovingFacilitator, setIsRemovingFacilitator] = useState(false);

	/* Update contact info */
	const [isSettingContactInfo, setIsSettingContactInfo] = useState(false);
	const [contactPerson, setContactPerson] = useState('');
	const [contactEmail, setContactEmail] = useState('');
	
	/* Editing tags status */
	const [isEditingTags, setIsEditingTags] = useState(false);
	const [tagsErr, setTagsErr] = useState(null);

	/* Diploma download file name */
	const diplomaFileName = schoolData.name.replace(/\./g, '').replace(/\s/g, '_');

	/**
	 * Handle input of new school data
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		const value = event.target.value;
		if (event.target.name === 'facilitatorEmails') {
			setFacilitatorEmails(value);
		} else if (event.target.name === 'contactPerson') {
			setContactPerson(value);
		} else if (event.target.name === 'contactEmail') {
			setContactEmail(value);
		}
		setFacilitatorEmailsErr(null);
		setTagsErr(null);
	};

	/**
	 * Updates contact information if any new contact information is given
	 */
	const handleUpdateContactInfo = () => {
		const contactInfo = {contactPerson: null, email: null};

		if (contactEmail && contactEmail !== '') {
			contactInfo.email = contactEmail;
		}
		if (contactPerson && contactPerson !== '') {
			contactInfo.contactPerson = contactPerson;
		}

		updateSchool(schoolData.id, contactInfo).then(() => {
			setIsSettingContactInfo(false);
		}).catch((error) => {
			console.error(error);
			setIsSettingContactInfo(false);
		});
	};

	/**
	 * Add facilitator to school
	 * @returns 
	 */
	const handleAddFacilitator = () => {
		if (isAddingFacilitators) return;

		setIsAddingFacilitators(true);

		/* Error: empty field */
		if (!facilitatorEmails || facilitatorEmails.length === 0) {
			setFacilitatorEmailsErr(getText('error', 'someFieldsMissing'));
			setIsAddingFacilitators(false);
			return;
		}

		/* Convert facilitator e-mail string to array */
		const facilitatorEmailsArr = facilitatorEmails.split(',');
		const facilitatorEmailsArrTrimmed = [];
		facilitatorEmailsArr.forEach((email) => {
			const emailTrimmed = email.trim();
			if (emailTrimmed.length > 0) {
				facilitatorEmailsArrTrimmed.push(emailTrimmed.toLowerCase());
			}
		});

		/* Error: empty array */
		if (facilitatorEmailsArrTrimmed.length === 0) {
			setFacilitatorEmailsErr(getText('error', 'errInvalidEmail'));
			setIsAddingFacilitators(false);
			return;
		}

		/* Get current school facilitators */
		const newFacilitatorEmails = (schoolData.facilitatorEmails 
			? JSON.parse(JSON.stringify(schoolData.facilitatorEmails))
			: []
		);

		let errMsg = null;
		if (facilitatorEmailsArrTrimmed.length === 1) {
			/* Only one e-mail */
			if (!checkIfValidEmail(facilitatorEmailsArrTrimmed[0])) {
				/* Invalid e-mail */
				errMsg = getText('error', 'errInvalidEmail');
			} else {
				if (newFacilitatorEmails.indexOf(facilitatorEmailsArrTrimmed[0]) >= 0) {
					/* E-mail is already facilitator */
					errMsg = getText('error', 'errEmailAlreadyFacilitatorOfSchool');
				}
			}
		} else {
			/* Multiple e-mails */
			let invalidEmails = [];
			facilitatorEmailsArrTrimmed.forEach((email) => {
				if (!checkIfValidEmail(email)) {
					invalidEmails.push(email);
				}
			});
			if (invalidEmails.length > 0) {
				/* Some e-mails are invalid */
				errMsg = getText('error', 'errOneOrMoreInvalidEmails') + invalidEmails.toString();
			} else {
				let alreadyFacilitator = [];
				facilitatorEmailsArrTrimmed.forEach((email) => {
					if (newFacilitatorEmails.indexOf(email) >= 0) {
						alreadyFacilitator.push(email);
					}
				});
				if (alreadyFacilitator.length > 0) {
					/* Some e-mails already facilitators */
					errMsg = getText('error', 'errSomeEmailsAlreadyFacilitators') + alreadyFacilitator.toString();
				}
			}
		}
		if (errMsg) {
			/* Invalid input, show error message */
			setFacilitatorEmailsErr(errMsg);
			setIsAddingFacilitators(false);
		} else {
			const sendWelcomeEmailTo = [];
			facilitatorEmailsArrTrimmed.forEach((email) => {
				/* Add e-mail to school facilitators */
				newFacilitatorEmails.push(email);

				/* Check how many schools facilitator already has been added to */
				const numberOfSchools = getNumberOfSchoolsEmailIsFacilitatorOf(email, schools);
				if (
					numberOfSchools === 0 && 
					!users.some((user) => {return user.email === email;})
				) {
					/* User does not exist, send welcome e-mail */
					sendWelcomeEmailTo.push(email);
				}
			});

			/* Update school */
			updateSchool(schoolData.id, {facilitatorEmails: newFacilitatorEmails}).then(() => {
				if (sendWelcomeEmailTo.length === 0) {
					/* User(s) already got welcome mail or already exists, all done */
					setFacilitatorEmails('');
					setIsAddingFacilitators(false);	
				} else {
					/* User(s) does not exist, send welcome e-mail */
					apiHelper('schools/send-welcome-mail', 
						{emails: sendWelcomeEmailTo}
					).then((response) => {
						if (response.status === 'success') {
							if (response.error === 'welcome-email-failed') {
								/* Could not send welcome e-mail */
								setFacilitatorEmailsErr(getText('error', 'welcomeMailFailed'));
							}
						} else {
							/* Could not send welcome e-mail */
							setFacilitatorEmailsErr(getText('error', 'welcomeMailFailed'));		
						}
						setFacilitatorEmails('');
						setIsAddingFacilitators(false);
					});
				}
			}).catch((error) => {
				/* Error updating school */
				console.error(error);
				setFacilitatorEmailsErr(getText('error', 'unknownError'));
				setIsAddingFacilitators(false);
			});
		}
	};

	/**
	 * Remove facilitator from school
	 * @param {string} email 
	 * @returns 
	 */
	const handleRemoveFacilitator = (email) => {
		if (isRemovingFacilitator) return;

		setIsRemovingFacilitator(true);

		const newFacilitatorEmails = (schoolData.facilitatorEmails 
			? JSON.parse(JSON.stringify(schoolData.facilitatorEmails))
			: []
		);
		const facilitatorIndex = newFacilitatorEmails.indexOf(email);
		if (facilitatorIndex >= 0) {
			newFacilitatorEmails.splice(facilitatorIndex, 1);
			updateSchool(schoolData.id, {facilitatorEmails: newFacilitatorEmails}).then(() => {
				setIsRemovingFacilitator(false);
			}).catch((error) => {
				console.error(error);
				setIsRemovingFacilitator(false);
			});

		} else {
			setIsRemovingFacilitator(false);
		}
	};


	/**
	 * Add school tag
	 * @param {string} tag 
	 * @param {bool} isNew 
	 */
	const addTag = (tag, isNew) => {
		if (isEditingTags) return;

		setIsEditingTags(true);
		setTagsErr(null);
		const newTagIds = (schoolData.tagIds ? JSON.parse(JSON.stringify(schoolData.tagIds)) : []);

		if (!isNew) {
			/* Tag already exists in database, just add tag id to school */
			newTagIds.push(tag);
			updateSchool(schoolData.id, {tagIds: newTagIds}).then(() => {
				setIsEditingTags(false);
			}).catch((error) => {
				console.error(error);
				setIsEditingTags(false);
			});
		} else {
			/* New tag, generate unique tag id */
			const existingTagIds = tagsData.map((tag) => {return tag.id;});
			generateUniqueIdFromString(tag, existingTagIds).then((response) => {
				/* Add tag to database */
				const db = firebase.firestore();
				db.collection(appConfig.tagsDbName).doc(response.id).set({value: tag.trim()}).then(() => {
					/* Add tag to school */
					newTagIds.push(response.id);
					updateSchool(schoolData.id, {tagIds: newTagIds}).then(() => {
						setIsEditingTags(false);
					}).catch((error) => {
						console.error(error);
						setIsEditingTags(false);
					});
				}).catch((error) => {
					/* Error: could not add tag do database */
					console.error(error);
					setTagsErr(getText('error', 'unknownError'));
					setIsEditingTags(false);
				});
			});
		}		
	};

	/**
	 * Remove school tag
	 * @param {string} tagId 
	 */
	const removeTag = (tagId) => {
		if (isEditingTags) return;

		setIsEditingTags(true);
		setTagsErr(null);
		const newTagIds = (schoolData.tagIds ? JSON.parse(JSON.stringify(schoolData.tagIds)) : []);
		const tagIndex = newTagIds.indexOf(tagId);
		if (tagIndex >= 0) {
			/* Remove tag from school */
			newTagIds.splice(tagIndex, 1);
			updateSchool(schoolData.id, {tagIds: newTagIds}).then(() => {
				const tagIsInUse = checkIfTagIsInUse(tagId, schools, schoolData.id);
				if (!tagIsInUse) {
					/* Tag is not in use by any school, delete from tag database */
					const db = firebase.firestore();
					db.collection(appConfig.tagsDbName).doc(tagId).delete().then(() => {
						setIsEditingTags(false);
					}).catch((error) => {
						console.error(error);
						setIsEditingTags(false);
					});
				}  else {
					setIsEditingTags(false);
				}
			}).catch((error) => {
				console.error(error);
				setIsEditingTags(false);
			});
		} else {
			setIsEditingTags(false);
		}
	};

	/* Tag options */
	const tagOptions = tagsData.filter((tag) => {
		return (!schoolData.tagIds || !schoolData.tagIds.includes(tag.id));
	});

	return (
		<div className='PopupSchool'>
			<div className='PopupSchool-content'>
				{/* Header */}
				<div className='PopupSchool-header'>
					<span>{getText('adminTexts', 'editSchool')}</span>
				</div>

				{/* Body */}
				<div className='PopupSchool-body'>
					<div className="PopupSchool-title">{getText('adminTexts', 'info')}</div>
					<div className="PopupSchool-infoWrap">
						{/* School info */}
						<div className='PopupSchool-infoColumn'>
							<div className="PopupSchool-info">
								<div className="PopupSchool-infoLabel">
									<span>{getText('adminTexts', 'name')}</span>
								</div>
								<div className="PopupSchool-infoData">
									<span>{schoolData.name}</span>
								</div>
							</div>
							<div className="PopupSchool-info">
								<div className="PopupSchool-infoLabel">
									<span>{getText('adminTexts', 'contactPerson')}</span>
								</div>
								{isSettingContactInfo ?
									<div className='PopupSchool-input'>
										<input
											name="contactPerson"
											type="text" 
											value={contactPerson ? contactPerson : ''} 
											onChange={(event)=>{handleInput(event);}}/>
									</div>
									:
									<div className="PopupSchool-infoData">
										<span>{(schoolData.contactPerson ? schoolData.contactPerson : '-')}</span>
									</div>
								}
							</div>
							<div className="PopupSchool-info">
								<div className="PopupSchool-infoLabel">
									<span>{getText('adminTexts', 'contactEmail')}</span>
								</div>
								{isSettingContactInfo ?
									<div className='PopupSchool-input'>
										<input
											name="contactEmail"
											type="text" 
											value={contactEmail ? contactEmail : ''} 
											onChange={(event)=>{handleInput(event);}}/>
									</div>
									:
									<div className="PopupSchool-infoData">
										<span>{(schoolData.email ? schoolData.email : '-')}</span>
									</div>
								}
							</div>
							{isSettingContactInfo ?
								<div className='PopupSchool-infoButtons'>
									<Button
										isDisabled={contactEmail === '' && contactPerson === ''}
										text={getText('adminTexts', 'saveChanges')}
										classes={['shadow', 'blue', 'responsive']}
										onClick={() => {handleUpdateContactInfo();}}
									/>
									<Button
										text={getText('adminTexts', 'cancel')}
										classes={['shadow', 'blue', 'responsive']}
										onClick={() => {setIsSettingContactInfo(false);}}
									/>
								</div>
								:
								<div className='PopupSchool-infoButton'>
									<Button
										text={getText('adminTexts', 'setContactInfo')}
										classes={['shadow', 'blue', 'responsive']}
										onClick={() => {setIsSettingContactInfo(true);}}
									/>
								</div>
							}
						</div>

						{/* Diploma */}
						<div className='PopupSchool-infoColumn right'>
							<div className="PopupSchool-info">
								<div className='PopupSchool-pdfDownloadLink'>
									<PDFDownloadLink
										document={
											<SchoolDocument 
												code={schoolData.password} 
												schoolName={schoolData.name}
											/>
										}
										fileName={diplomaFileName}
									>
										{({loading}) => {
											return (
												<Button
													text={getText('gameUi', 'savePdf')}
													isDisabled={loading}
													classes={['shadow', 'blue', 'responsive', 'fullWidth']}
													onClick={(e) => {e.stopPropagation();}}
												/>
											);
										}}
									</PDFDownloadLink>
								</div>
							</div>
						</div>
					</div>

					<div className="PopupSchool-facilitatorsAndTags">
						{/* Facilitators */}
						<div className="PopupSchool-facilitatorsWrap">
							{/* List of facilitators */}
							<div className="PopupSchool-facilitators">
								<div className="PopupSchool-title">{getText('adminTexts', 'facilitators')}</div>
								{schoolData.facilitatorEmails && schoolData.facilitatorEmails.map((email) => {
									return (
										<div key={email} className="PopupSchool-facilitator">
											<div className="PopupSchool-facilitatorEmails"><span>{email}</span></div>
											<div className="PopupSchool-removeFacilitator">
												<div 
													className="PopupSchool-removeFacilitatorBtn"
													onClick={() => {handleRemoveFacilitator(email);}}
												/>
											</div>
										</div>
									);
								})}
							</div>
							{/* Add new facilitator */}
							<div className="PopupSchool-addFacilitator">
								<div className="PopupSchool-title">
									<span>{getText('adminTexts', 'addFacilitator')}</span>
								</div>
								<div className="PopupSchool-addFacilitatorWrap">
									<div className="PopupSchool-addFacilitatorInput">
										<input
											className='Admin-input'
											name="facilitatorEmails"
											type="text"
											placeholder={''}
											value={facilitatorEmails}
											onChange={handleInput}
										/>
									</div>
									<div className="PopupSchool-addFacilitatorButton">
										<Button 
											isLoading={isAddingFacilitators}
											text={getText('adminTexts', 'add')}
											classes={['shadow', 'blue', 'responsive']}
											onClick={() => {handleAddFacilitator();}}
										/>
									</div>
								</div>
								<div className="PopupSchool-subtitle">
									<span>{getText('adminTexts', 'useCommaToAddMultipleFacilitators')}.</span>
								</div>
								<div className="PopupSchool-addFacilitatorErr">
									{facilitatorEmailsErr && <span>{facilitatorEmailsErr}</span>}
								</div>
							</div>
						</div>

						{/* Tags */}
						<div className="PopupSchool-tagsWrap">
							{/* List of acilitators */}
							<div className="PopupSchool-tags">
								<div className="PopupSchool-title">{getText('adminTexts', 'tags')}</div>
								{schoolData.tagIds && schoolData.tagIds.map((tagId) => {
									const tagData = tagsData.find((tag) => {return tag.id === tagId;});
									return (
										<div key={tagId} className="PopupSchool-tag">
											<div className="PopupSchool-tagValue">
												<span>{(tagData ? tagData.value : tagId)}</span>
											</div>
											<div className="PopupSchool-removeTag">
												<div 
													className="PopupSchool-removeTagBtn"
													onClick={() => {removeTag(tagId);}}
												/>
											</div>
										</div>
									);
								})}
							</div>
							{/* Add new tag */}
							<div className="PopupSchool-addTag">
								<div className="PopupSchool-title">
									<span>{getText('adminTexts', 'addTag')}</span>
								</div>
								<div className="PopupSchool-tagSelect">
									<Select 
										isDisabled={false}
										canSearch={true} 
										canAddOption={true}
										type="tags" 
										placeholderText={getText('adminTexts', 'typeToAddTag')}
										noMatchText={getText('adminTexts', 'noMatchesPressEnter')}
										options={tagOptions}
										onSelect={addTag}
									/>
								</div>
								<div className="PopupSchool-addFacilitatorErr">
									{tagsErr && <span>{tagsErr}</span>}
								</div>
							</div>
						</div>
					</div>


					{/* Buttons */}
					<div className='PopupSchool-buttons'>
						<div className='PopupSchool-button'>
							<Button
								text={getText('adminTexts', 'close')}
								classes={['shadow', 'white', 'blueBorder', 'responsive']}
								onClick={() => {setSelectedSchoolId(null);}}
							/>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
};

PopupSchool.propTypes = {
	schoolData: PropTypes.object.isRequired,
	users: PropTypes.array.isRequired,
	schools: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	updateSchool: PropTypes.func.isRequired,
	setSelectedSchoolId: PropTypes.func.isRequired,
};

export default PopupSchool; 