import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TaskIntro from '../task-intro/task-intro';
import {shuffleArray} from 'helpers/array-helper';
import './multiple-choice.scss';
import Button from 'components/ui/button/button';
import { getText } from 'helpers/text-helper';
import { getMultipleChoiceEffects } from 'helpers/module-helper';

const MultipleChoice = (props) => {
	const {
		moduleId, 
		previousTaskData, 
		taskData, 
		handleCompleteTask, 
		updateLoggedTime, 
		goToPreviousTask, 
		canGoBack
	} = props;
	/* Animate selected options */
	// TODO IMPLEMENT NEW ANIMATIONS ON OPTIONS CLICK
	// const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false);
	
	const [isLoading, setIsLoading] = useState(false);

	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];
		if (previousTaskData && previousTaskData.selectedOptionIds) optionIds = previousTaskData.selectedOptionIds;
		return optionIds;
	};

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	
	/* Update selected items if new task */
	useEffect(() => {
		setIsLoading(false);
		setOptionIds(getOptionIds());
		setSelectedOptionIds(getSelectedOptionIds());

		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Update logged time */
		updateLoggedTime();

		const optionIndex = selectedOptionIds.indexOf(optionId);
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		if (optionIndex >= 0) {
			/* Already selected, remove selection */
			let newOptions = [...selectedOptionIds];
			newOptions.splice(optionIndex, 1);
			newSelectedOptionIds = newOptions;
		} else {
			if (taskData.numberOfAnswersToSelect === 1 && !taskData.canAnswerAnyAmount) {
				/* Select option, deselect previous */
				newSelectedOptionIds = [optionId];
			} else if (taskData.numberOfAnswersToSelect > newSelectedOptionIds.length || taskData.canAnswerAnyAmount) {
				/* Select option */
				newSelectedOptionIds.push(optionId);
			} else {
				/* Already selected max number of options */
				return;
			}
		};
		setSelectedOptionIds(newSelectedOptionIds);

		/* Enable animation */
		// setAnimateSelectedOptions(true);		
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 */
	const completeTask = (newSelectedOptionIds) => {
		/* Prepare array of effects */
		let newEffects = getMultipleChoiceEffects(taskData, selectedOptionIds);

		/* Save completed task */
		handleCompleteTask(
			newEffects,
			{selectedOptionIds: newSelectedOptionIds}
		);
	};

	const doneButtonIsEnabled = (
		selectedOptionIds.length === taskData.numberOfAnswersToSelect || 
		(taskData.canAnswerAnyAmount && selectedOptionIds.length > 1)
	);
	const canSelect = (
		(taskData.numberOfAnswersToSelect === 1 || selectedOptionIds.length < taskData.numberOfAnswersToSelect)
	);
	return (
		<div className={'MultipleChoice'
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			<div id="taskIntro" className="MultipleChoice-intro">
				<TaskIntro 
					moduleId={moduleId}
					taskId={taskData.id}
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					numberOfCorrectAnswers={numberOfCorrectAnswers} 
					text={taskData.text}
					image={taskData.image}
				/>
			</div>
			<div id="multipleChoiceOptions" className="MultipleChoice-options">
				<div className={'MultipleChoice-optionsWrap'}>
					{optionIds.map((optionId, index) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;
						const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
						let optionClass = 'MultipleChoice-option';
						if (isSelected) {
							optionClass += ' selected';
						} else if (!isSelected && !canSelect) {
							optionClass += ' disabled';
						}					
						if (taskData.layout) {
							optionClass += ' option-' + optionData.id + ' position-' + (index + 1);
						}

						return (
							<div 
								key={optionData.id} 
								className={optionClass} 
								onClick={() => {selectOptionId(optionData.id);}}
							>
								<span>{optionData.text}</span>
								<div className={'MultipleChoice-choiceIndicator ' + (isSelected ? 'selected' : '')}/>
							</div>
						);
					})}
				</div>
			</div>

			{/* Done button */}
			<div className="MultipleChoice-doneBtn">
				<Button
					isDisabled={!doneButtonIsEnabled || isLoading}
					classes={['white', 'confirmTask', 'responsive', 'shadow']}
					text={getText('gameUi', 'ok')}
					onClick={() => {completeTask(selectedOptionIds); setIsLoading(true);}}
				/>
			</div>
			{canGoBack &&
				<div className='MultipleChoice-previousButton'>
					<Button
						isDisabled={isLoading}
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'back')}
						onClick={() => {goToPreviousTask(true); setIsLoading(true);}}
					/>
				</div>
			}
		</div>
	);
};

MultipleChoice.propTypes = {
	moduleId: PropTypes.string.isRequired,
	previousTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
	canGoBack: PropTypes.bool.isRequired,
};

export default MultipleChoice;
