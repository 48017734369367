import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './calculate-result.scss';
import CalculatingDot from './calculating-dot';
import { getPointOnCircle } from 'helpers/math-helper';

const CalculateResults = ({handleCompleteTask, taskData}) => {
	/**
	 * Complete task
	 */
	const completeTask = () => {
		handleCompleteTask([], {});
	};

	const dots = [...Array(20)];
	const colors = ['blue', 'yellow', 'orange'];
	const centerPoint = {x: 0, y: 0};
	const dotSize = 3;

	const loadTime = 4000;
	const timeout = useRef(null);

	// Setup timeout for switching page
	useEffect(() => {
		timeout.current = setTimeout(() => {
			completeTask();
		}, loadTime);

		return () => {
			/* Clear timeout on unmount */
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='CalculateResults'>
			<div className='CalculateResults-circle'>
				<div className='CalculateResults-dotsWrapper'>
					{dots.map((dot, index) => {
						const angle = 360 / dots.length * index;
						const dotPoint = getPointOnCircle(centerPoint, 13, angle);
						
						return (
							<div key={index} className='CalculateResults-dot'
								style={{
									top: dotPoint.y - (dotSize / 2) + 'em',
									left: dotPoint.x - (dotSize / 2) + 'em',
								}}
							>
								<CalculatingDot
									index={index}
									size={dotSize}
									colors={colors}
									animationDelay={index / 7}
								/>
							</div>
						);
					})}

				</div>
				<div className='CalculateResults-title'>
					<span>{taskData.title}</span>
				</div>
			</div>
		</div>
	);
};

CalculateResults.propTypes = {
	handleCompleteTask: PropTypes.func.isRequired,
	taskData: PropTypes.object.isRequired,
};

export default CalculateResults;