let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let pdfUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let statisticsDbName = 'statistics';
let tasksDbName = 'tasks';
let schoolsDbName = 'schools';
let usersDbName = 'users';
let tagsDbName = 'tags';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-karrierevejen-test.web.app';
	pdfUrl = 'cgl-karrierevejen-test.web.app';
	apiURL = 'https://cgl-karrierevejen-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-karrierevejen-demo.web.app';
		pdfUrl = 'cgl-karrierevejen-demo.web.app';
		apiURL = 'https://cgl-karrierevejen-demo.web.app/api/';
		statisticsDbName = 'statistics-demo';
		tasksDbName = 'tasks-demo';
		schoolsDbName = 'schools-demo';
		tagsDbName = 'tags-demo';
	}
}
if (env === 'production') {
	gameUrl = 'https://karrierevejen.nu';
	pdfUrl = 'karrierevejen.nu';
	apiURL = 'https://cgl-karrierevejen-production.web.app/api/';
}

const appConfig = {
	env,
	gameUrl,
	pdfUrl,
	apiURL,
	showDevTools: (env === 'development' || env === 'test'),
	name: 'Karrierevejen',
	cookiesAcceptedCookieName: 'karrierevejen_cookies_accepted',
	gameDataCookieName: 'karrierevejen_game_data',
	loginCookieName: 'karrierevejen_login',
	statisticsDbName: statisticsDbName,
	tasksDbName,
	schoolsDbName: schoolsDbName,
	usersDbName: usersDbName,
	inactivityLimitSeconds: 30,
	shownCareers: 3,
	shownKeywords: 6,
	shownSteps: 3,
	minRequiredStatEntries: 6,
	tagsDbName
};

export default appConfig;