import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { sortArrayByLikeness } from 'helpers/array-helper';
import './input-search.scss';

const InputSearch = ({schools, setSelectedSchoolId}) => {
	const [search, setSearch] = useState('');
	const [currentSearchResult, setCurrentSearchResult] = useState(null);
	const [currentFocusId, setCurrentFocusId] = useState(0);

	const handleEnter = (id) => {
		setSelectedSchoolId(currentSearchResult[id].id);
		setSearch(currentSearchResult[id].name);
		setCurrentSearchResult(null);
	};

	const handleKeyEvent = (event) => {
		const searchList = currentSearchResult;

		if (event.key === 'Enter') {
			handleEnter(currentFocusId);
		} else if (searchList) {
			let newFocusId = currentFocusId ? currentFocusId : 0;

			let alignToTop = false;

			if (event.key === 'ArrowUp') {
				newFocusId = Math.max(newFocusId - 1, 0);
				alignToTop = true;
			} else if (event.key === 'ArrowDown') {
				newFocusId = Math.min(newFocusId + 1, searchList.length - 1);
				alignToTop = false;
			}

			if (newFocusId !== currentFocusId) {
				const element = document.getElementById(newFocusId);

				const elementWrapper = document.getElementById('options');
				let isInView = true;
				if (elementWrapper && element) {
					const wrapperRect = elementWrapper.getBoundingClientRect();
					const elementRect = element.getBoundingClientRect();
					isInView = elementRect.top > wrapperRect.top && elementRect.bottom < wrapperRect.bottom;
				}

				if (!isInView) {
					element.scrollIntoView(alignToTop);
				}
				setCurrentFocusId(newFocusId);
			}
		}
	};

	const handleInput = (event) => {
		const value = event.target.value;

		setSearch(value);
		// Search only starts with 2 or more letters
		if (value.length < 2) {
			setCurrentSearchResult(null);
			return;
		}
		// Search through schools list, 
		// find all schools that contain current string value and order according to likeness
		let newSearch = sortArrayByLikeness(schools, value, 'name');
		setCurrentSearchResult(newSearch);
	};

	return (
		<div className='InputSearch' onKeyDown={(event) => {handleKeyEvent(event);}}>
			<input
				autoComplete='off'
				className='InputSearch-schoolInput'
				name='school'
				type='text'
				placeholder={''}
				value={search}
				onChange={(event) => {
					handleInput(event);
				}}
				// Preventing arrow keys up and down skipping back and forth in text
				onKeyDown={(event) => {
					if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
						event.preventDefault();
						return;
					}
				}}
			/>
			{(currentSearchResult && currentSearchResult.length > 0) && 
				<div id={'options'} className='InputSearch-optionsWrapper'>
					{currentSearchResult.map((searchResult, index) => {
						let isInFocus = false;
						if (index === currentFocusId) {
							isInFocus = true;
						}

						return (
							<div
								key={index} 
								id={index} 
								className={'InputSearch-option' + (isInFocus ? ' focus' : '')}
								onClick={() => {handleEnter(index); setCurrentFocusId(index);}}
							>
								<span>{searchResult.name}</span>
							</div>
						);
					})}
				</div>
			}
		</div>
	);
};

InputSearch.propTypes = {
	schools: PropTypes.array.isRequired,
	setSelectedSchoolId: PropTypes.func.isRequired,
};

export default InputSearch;