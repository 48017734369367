const gameUiTexts = {
	start: 'Start',
	reset: 'Reset',
	task: 'Opgave',
	choose: 'Vælg',
	ok: 'Ok',
	continue: 'Videre',
	back: 'Forrige',
	keywords: 'Nøgleord',
	results: 'Resultater',
	other: 'Andet',
	yourProfile: 'Din profil',
	login: 'Log ind',
	viewExamples: 'Se eksempler',
	viewMore: 'Se mere',
	school: 'Skole:',
	password: 'Password:',
	startNew: 'Start forfra',
	continueGame: 'Fortsæt',
	education: 'Uddannelsessted:',
	duration: 'Varighed:',
	startNewTest: 'Start ny test',
	savePdf: 'Hent diplom',
	code: 'Kode',
	loginName: 'Navn',
	nextStep: 'Ungdomsuddannelser',
	class: 'Klasse',
	logoutWarning: `Hvis du logger ud, så mister du din nuværende spildata!<br /><br />Vil du fortsætte?`,
	continue: 'Fortsæt',
	cancel: 'Fortryd',
};

const errorTexts = {
	unknownTaskType: 'UNKNOWN TASK TYPE:',
	unknownConditionType: 'UNKNOWN CONDITION TYPE:',
	couldNotGetSchool: 'could not get schools: ',
	couldNotGetStatistics: 'could not get statistics: ',
	couldNotGetUsers: 'Fejl: Kunne ikke hente brugerdata.',
	invalidLogin: 'Check om din skole og kode er korrekte.',
	error: 'error',
	noLoginData: 'Could not get login data:',
	invalidClass: 'Klasse er ikke valid',

	emailNotFound: 'Fejl: Der findes ingen bruger med denne email.',
	invalidEmail: 'Fejl: Din email er ikke gyldig.',
	invalidEmailPassword: 'Fejl: Check om din e-mail og kodeord er korrekte.',
	unknownError: 'Fejl: Ukendt fejl.',
	invalidPassword: 'Fejl: Forkert kodeord',
	userNotFound: 'Fejl: Ukendt bruger',
	missingFields: 'Fejl: Husk at udfylde alle felter.',
	someFieldsMissing: 'Fejl: Husk at udfylde alle felter',
	shortPassword: 'Dit kodeord skal være på mindst 8 tegn.',
	noPasswordMatch: 'Dit kodeord skal være ens i begge felter.',
	errEmptySchoolName: 'Fejl: Husk at udfylde navnet på skolen.',
	errSchoolNameExists: 'Fejl: Der eksisterer allerede en skole med dette navn.',
	errInvalidEmail: 'Fejl: E-mail ikke gyldig.',
	userNotFacilitator: 'Denne e-mail er ikke knyttet til nogen skole. Du kan derfor ikke oprette en konto.',
	userAlreadyExists: 'Der eksisterer allerede en konto knyttet til denne e-mail.',

	errEmailAlreadyFacilitatorOfSchool: 'Denne e-mail er allerede facilitator for skolen.',

	errSomeEmailsAlreadyFacilitators: 'En eller flere e-mails er allerede facilitator(er) for skolen: ',
	errOneOrMoreInvalidEmails: 'En eller flere e-mails er ikke gyldige: ',

	resetPwMailFailed: 'Facilitatoren blev oprettet, men e-mailen til at nulstille password blev ikke sendt.',
	welcomeMailFailed: 'Facilitatoren blev oprettet, men velkomst-mailen blev ikke sendt.',
	resetPwAndWelcomeMailFailed: 'Facilitatoren blev oprettet, men både velkomst-mailen og e-mailen til at nulstille password blev ikke sendt.',

	allFields: 'Begge felter skal udfyldes!',
	codesNotTheSame: 'Koder er ikke ens!',
	unknownResetError: 'Der er noget galt. Prøv igen senere'
};

const cookieUiTexts = {
	text1: `Vi vil gerne gemme dine valgte handlinger og din progression i spillet i en cookie.
		<br />Du kan ikke spille uden denne cookie. Tryk "JA" for at acceptere den.`,
	text2: `<br />Cookien bliver gemt i et år.
		<br />Tryk "NEJ" for at slette cookien og trække dit samtykke tilbage.`,
	seeMore: 'Se mere',
	seeLess: 'Se mindre',
	yes: 'Ja',
	no: 'Nej',
	cookieWarning: 'Cookies er krævet for at bruge appen'
};

const flowTexts = {
	landingPage: `Svar på spørgsmål om dig selv og få bud på uddannelses- og karriereveje.`,
	landingPage2: `Tryk start for at begynde.`,
	welcome: 'Velkommen til Karrierevejen!',
	welcome2: 'Få forslag til mulige uddannelses- og karriereveje.'
};

const pdfTexts = {
	fileName: 'resultat',
	getPdf: 'Hent PDF'
};

const statsUiTexts = {
	facilitator: 'Facilitator',
	back: 'Tilbage til spillet',
	title: 'Statistik for Karrierevejen',
	email: 'e-mail',
	password: 'kode',
	repeatPassword: 'gentag kode',
	login: 'Log ind',
	logout: 'Log ud',
	createUser: 'Opret konto',
	numberOfPlayers: 'Antal spillere',
	generalStats: 'Generel statistik',
	keywordsStats: 'Keywords statistik',
	careerPath: 'Karrierevej',
	careerPaths: 'Karriereveje',
	nextStep: 'Uddannelse',
	nextSteps: 'Uddannelser',
	allKeywords: 'Alle keywords',
	tasks: 'Opgaver',

	schools: 'Skoler',
	stats: 'Statistik',
	noSchools: 'Du er ikke facilitator af nogen skoler.', 

	years: 'År',
	classLevel: 'Klassetrin',
	schoolClasses: 'Klasser',
	downloadPdfGamePassword: 'Download pdf med kode',
	numberOfStartedGames: 'Antal startede spil',
	numberOfCompletedGames: 'Antal afsluttede spil',
	avrCompletionTime: 'Gns. tid',
	minutes: 'min.',

	gameAmount: 'Antal spil',
	result: 'Resultat',
	topOne: 'Top 1',
	topThree: 'Top 3',
	schoolLogin: 'Skole login',
	point: 'Point',
	category: 'Kategori',
	averagePercent: 'Gennemsnitlig Procent',
	avrPercent: 'Gns. %',
	noAccess: 'Denne bruger har ikke adgang til statistikken',
}

const adminUiTexts = {
	title: 'Admin konsol for Karrierevejen',
	email: 'e-mail',
	login: 'Log ind',
	logout: 'Log ud',
	school: 'Skole',
	password: 'Kode',
	delete: 'Slet',
	deleteUser: 'slet bruger',
	typeToAddTag: 'Skriv navnet på et tag',
	noMatchesPressEnter: 'Ikke fundet. Tryk enter for at tilføje nyt tag.',

	editSchool: 'Rediger skole',
	info: 'Info',
	facilitators: 'Facilitatorer',
	addFacilitator: 'Tilføj facilitator',
	addFacilitatorToSchools: 'Tilføj facilitator til valgte skole(r)',
	useCommaToAddMultipleFacilitators: 'Brug komma mellem e-mails for at tilføje flere facilitatorer',
	add: 'Tilføj',
	addTag: 'Tilføj tag',
	close: 'Luk',

	addSchool: 'Tilføj skole',
	name: 'Navn',
	contactPerson: 'Kontaktperson',
	contactEmail: 'Kontakt e-mail',
	tags: 'Tags',
	

	deleteSchoolPopupTitle: 'Slet skole',
	deleteSchoolPopupText: 'Advarsel! Du er igang med at slette en skole. Denne handling kan ikke laves om! Vil du fortsætte?',
	deleteSchoolUserPopupTitle: 'Slet bruger',
	deleteSchoolUserPopupText: 'Advarsel! Du er igang med at slette en bruger. Denne handling kan ikke laves om! Vil du fortsætte?',
	continue: 'Fortsæt',
	cancel: 'Fortryd',

	admin: 'Admin',
	addAdmin: 'Tilføj admin',
	addAdminText: 'Tilføj en administrator bruger til',
	addUser: 'Tilføj bruger',
	resetPassword: 'Gendan kode',
	resetMessage: 'Indtast din e-mail for at nulstille din kode',
	backToLogin: 'Tilbage til login',

	setContactInfo: 'Indstil kontaktperson',
	saveChanges: 'Gem ændringer',

	playthroughCounts: 'Afviklinger',
	created: 'Oprettet',

	passwordResetFeedback: 'Der er sendt en e-mail til dig med et link til gendannelse af din kode. Husk at tjekke din spam-mappe, hvis du ikke kan finde den.',
	createUserFeedback: 'Din konto er blevet oprettet, og du kan nu logge ind.',

	newPassword: 'Ny kode',
	success: 'Success!',
	goToLogin: 'Gå til skole login',
	resetPassword2: 'Nulstil kode',
	newPassword: 'Ny kode',
	repeatPassword: 'Gentag kode',
}

export default {
	gameUi: gameUiTexts,
	error: errorTexts,
	cookieUi: cookieUiTexts,
	flow: flowTexts,
	statsTexts: statsUiTexts,
	adminTexts: adminUiTexts,
	pdf: pdfTexts,
}