import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getSchoolClassesAndYears} from 'helpers/stats-helper';
import AdminStats from './admin-stats';

class AdminStatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			selectedYears: [],
			selectedClassLevels: [],
			selectedTagIds: [],
		};
		this.unsubscribeSchools = null;
		this.unsubscribeTags = null;
		this.unsubscribeStats = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Select all years and class levels as default*/
		if (this.props.statistics && this.props.statistics.length > 0) {
			const [, years, levels] = getSchoolClassesAndYears(this.props.statistics);
			this.setState({selectedYears: years, selectedClassLevels: levels}, () => {
				this.setState({isLoading: false});
			});	
		} else {
			this.setState({isLoading: false});
		}
	};


	/**
	 * Toggle year
	 * @param {string} year 
	 */
	toggleYear = (year) => {
		const newSelectedYears = JSON.parse(JSON.stringify(this.state.selectedYears));
		const yearIndex = newSelectedYears.indexOf(year);
		if (yearIndex < 0) {
			/* Include year */
			newSelectedYears.push(year);
		} else {
			/* Remove year */
			newSelectedYears.splice(yearIndex, 1);
		}
		this.setState({selectedYears: newSelectedYears});
	};

	/**
	 * Toggle class level
	 * @param {string} classLevel 
	 */
	toggleClassLevel = (classLevel) => {
		const newSelectedClassLevels = JSON.parse(JSON.stringify(this.state.selectedClassLevels));
		const classLevelIndex = newSelectedClassLevels.indexOf(classLevel);
		if (classLevelIndex < 0) {
			/* Include class level */
			newSelectedClassLevels.push(classLevel);
		} else {
			/* Remove class level */
			newSelectedClassLevels.splice(classLevelIndex, 1);
		}
		this.setState({selectedClassLevels: newSelectedClassLevels});
	};

	/**
	 * Toggle tag
	 * @param {string} tagId 
	 */
	toggleTag = (tagId) => {
		const newSelectedTagIds = JSON.parse(JSON.stringify(this.state.selectedTagIds));
		const tagIndex = newSelectedTagIds.indexOf(tagId);
		if (tagIndex < 0) {
			/* Include tag id */
			newSelectedTagIds.push(tagId);
		} else {
			/* Remove tag id */
			newSelectedTagIds.splice(tagIndex, 1);
		}
		this.setState({selectedTagIds: newSelectedTagIds});
	};


	/**
	 * Render component
	 */
	render = () => {	
		/* Loading */
		if (this.state.isLoading) {
			return <div>loading ...</div>;
		}

		/* Get available years and class levels */
		const [, years, classLevels] = getSchoolClassesAndYears(this.props.statistics);

		return (
			<AdminStats 
				schools={this.props.schools} 
				tagsData={this.props.tagsData}
				statistics={this.props.statistics}
				years={years}
				classLevels={classLevels}
				selectedYears={this.state.selectedYears}
				selectedClassLevels={this.state.selectedClassLevels}
				selectedTagIds={this.state.selectedTagIds}
				toggleYear={this.toggleYear}
				toggleClassLevel={this.toggleClassLevel}
				toggleTag={this.toggleTag}
				
			/>
		);
	};
}

AdminStatsController.propTypes = {
	schools: PropTypes.array.isRequired,
	tagsData: PropTypes.array.isRequired,
	statistics: PropTypes.array.isRequired
};

export default AdminStatsController;