import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import appConfig from 'config/app.config';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import apiHelper from 'helpers/api-helper';
import { addPointsToKeyword } from 'helpers/keywords-helpers';
import {playerDataTemplate} from 'data/templates/player-data-template';
import Module from 'components/modules/module';
import './game.scss';

const Game = ({transitionBackground, loginInfo}) => {
	/* Player data */
	const [playerData, setPlayerData] = useState(null);

	/* Player task data for previous task. Used to go back to previous task. */
	const [previousTaskData, setPreviousTaskData] = useState(null);

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
			
		/* Default player data */
		let newPlayerData = JSON.parse(JSON.stringify(playerDataTemplate));

		/* Get player data from cookie */
		const gameDataFromCookie = getCookie(appConfig.gameDataCookieName);
		if (gameDataFromCookie && gameDataFromCookie.length > 0) {
			const gameDataObj = JSON.parse(gameDataFromCookie);
			if (gameDataObj) {
				newPlayerData = Object.assign({}, {...playerDataTemplate}, {...gameDataObj});
			}
		}

		/* Update state */
		setPlayerData(newPlayerData);
	}, []);

	/**
	 * Update player data
	 * @param {object} updates 
	 */
	const updatePlayerData = (updates) => {
		return new Promise((resolve) => {
			/* Check if this is the first question answered */
			if (!playerData || !playerData.tasks || playerData.tasks.length === 0) {
				saveStatistics('new-game');
			}

			/* Update state */
			const newPlayerData = Object.assign({}, {...playerData}, {...updates});
			setPlayerData(newPlayerData);

			// Check for cookie accept
			const acceptCookie = getCookie(appConfig.cookiesAcceptedCookieName);
			if (acceptCookie && acceptCookie.length > 0) {


				/* Update cookie */
				const newPlayerDataStr = JSON.stringify(newPlayerData);
				setCookie(appConfig.gameDataCookieName, newPlayerDataStr, 7);
			}

			resolve({status: 'success', playerData: newPlayerData});
		});
	};

	/**
	 * Update previous task data
	 * @param {object} taskData new previous task data
	 * @param {object} currentPlayerData
	 */
	const updatePreviousTaskData = (taskData, currentPlayerData) => {
		return new Promise((resolve) => {
			let newPreviousTaskData = previousTaskData;
			const isPreviousTask = (previousTaskData && newPreviousTaskData.id === taskData.id);
			// Data is new
			if (!isPreviousTask) {
				if (previousTaskData) {
					/* Add previous task data to db */	
					if (previousTaskData.moduleId === 'module-1') {
						saveTaskChoices(previousTaskData);
					}
				}

				/* Update previous task data */
				newPreviousTaskData = taskData;
			} else {
				// Answers already exist for task
				// Undo old taskData keyword changes
				let keywordPoints = (currentPlayerData.keywordPoints ? 
					JSON.parse(JSON.stringify(currentPlayerData.keywordPoints))
					:
					[]
				);

				const oldKeywordPoints = newPreviousTaskData.keywordPoints;
				oldKeywordPoints.forEach((keyword) => {
					keyword.keywordIds.forEach((keywordId, index) => {
						if (keyword.points.length > index) {
							keywordPoints = addPointsToKeyword(keywordPoints, keywordId, -keyword.points[index]);
						}
					});
				});

				updatePlayerData({keywordPoints});

				// Replace taskData with new
				newPreviousTaskData = taskData;
			}

			/* Update state */
			setPreviousTaskData(newPreviousTaskData);

			resolve({status: 'success'});
		});
	};

	/**
	 * Start new test
	 */
	const startNewTest = () => {
		return new Promise((resolve) => {
			/* Reset player data to default player data */
			const newPlayerData = JSON.parse(JSON.stringify(playerDataTemplate));

			/* Update player data */
			updatePlayerData(newPlayerData).then(() => {
				resolve({status: 'success', newPlayerData: newPlayerData});
			});
		});
	};

	/**
	 * Save task choices (2 task delay)
	 * @param {object} previousTaskData 
	 */
	const saveTaskChoices = (previousTaskData) => {
		const db = firebase.firestore();
		const taskChoices = JSON.parse(JSON.stringify(previousTaskData));
		delete taskChoices.keywordPoints;
		db.collection(appConfig.tasksDbName).add(taskChoices).catch((error) => {
			console.error(error);
		});
	};

	/**
	 * Saves player statistics anonymously
	 */
	const saveStatistics = (type) => {
		/* Year and week number */
		const year = new Date().getFullYear();
		const currentDate = new Date();
		const startDate = new Date(currentDate.getFullYear(), 0, 1);
		const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
		const weekNumber = Math.ceil(days / 7).toString().padStart(2, 0);
		
		/* Keywords */
		const keywordData = (type === 'results' ? playerData.keywordPoints : null);

		/* Time spend */
		const tasks = (type === 'results' ? playerData.tasks : []);
		let totalTime = 0;
		tasks.forEach((task) => {
			if (task.time) totalTime += task.time;
		});

		return new Promise((resolve) => {
			apiHelper('statistics/create-statistics', {
				date: year + '-' + weekNumber,
				schoolId: loginInfo.schoolId,
				schoolClass: loginInfo.schoolClass,
				keywordData: keywordData,
				totalTime: totalTime,
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	return (
		<div className='Game'>
			<DndProvider options={HTML5toTouch}>
				{playerData &&
				<Module
					playerData={playerData} 
					updatePlayerData={updatePlayerData}
					transitionBackground={transitionBackground}
					saveStatistics={saveStatistics}
					updatePreviousTaskData={updatePreviousTaskData}
					previousTaskData={previousTaskData}
					startNewTest={startNewTest}
				/>}
			</DndProvider>
		</div>
	);
};

Game.propTypes = {
	transitionBackground: PropTypes.func.isRequired,
	loginInfo: PropTypes.object.isRequired,
};

export default Game;