/**
 * Check if valid email
 * @param {string} email 
 * @returns 
 */
const checkIfValidEmail = (email) => {
	const regex = new RegExp('^([a-zA-Z0-9._%-‐]+@[a-zA-Z0-9.-‐]+.[a-zA-Z]{2,})$');
	return regex.test(email);
};

export {
	checkIfValidEmail
};