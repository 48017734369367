/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB2SMRxvdgw294W9LydAG3NdvNVKLhJqX0',
		authDomain: 'cgl-karrierevejen-test.firebaseapp.com',
		projectId: 'cgl-karrierevejen-test',
		storageBucket: 'cgl-karrierevejen-test.appspot.com',
		messagingSenderId: '265315437144',
		appId: '1:265315437144:web:ba652ab620ded2afa2982d'
	};
}

/* Connect to firebase project production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCTzHEUanl8jjcXq_b0pFUkbkkM9iO608I',
		authDomain: 'cgl-karrierevejen-production.firebaseapp.com',
		projectId: 'cgl-karrierevejen-production',
		storageBucket: 'cgl-karrierevejen-production.appspot.com',
		messagingSenderId: '127726228467',
		appId: '1:127726228467:web:7f155b1af9b5b7398b7886'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;