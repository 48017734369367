import React from 'react';
import {
	StyleSheet,
	Document,
	Circle,
	Page,
	Font,
	View,
	Text,
	Svg,
	Polygon,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { calculateDataForKeywordCategory, getResultsFromData } from 'helpers/keywords-helpers';
import { keywordCategories } from 'data/keywords-data';
import { resultsData } from 'data/results-data';
import { nextStepData } from 'data/next-step-data';

// Colors
const green = '#96B35C';
const white = '#FFFFFF';
const orange = '#D95F37';
const yellow = '#D9B237';
const blue = '#3E72CC';
const fontBlue = '#203966';

// Education Colors:
const stx = '#A73B45';
const hhx = '#2D659E';
const htx = '#263457';
const eudTechnology = '#594171';
const eudHealth = '#594171';
const eudOffice = '#594171';
const eudFood = '#594171';
const euxTechnology = '#85868A';
const euxHealth = '#85868A';
const euxOffice = '#85868A';
const euxFood = '#85868A';
const hf = '#9EB9D6';

const styles = StyleSheet.create ({
	background: {
		backgroundColor: green,
		color: fontBlue,
	},
	row: {
		flexDirection: 'row',
	},
	column: {
		flexDirection: 'column',
	},
	title: {
		justifyContent: 'space-between',
		position: 'relative',
		fontFamily: 'poppins',
		fontWeight: 'bold',
		fontSize: '7.7mm'
	},
	subtitle: {
		fontFamily: 'poppins',
		fontWeight: 'bold',
		fontSize: '5.75mm'
	},
	text: {
		fontFamily: 'poppins',
		fontWeight: 'semibold',
		fontSize: '3.75mm'
	},
	topCircles: {
		top: '25.6mm'
	},
	stx: {
		backgroundColor: stx
	},
	hhx: {
		backgroundColor: hhx
	},
	htx: {
		backgroundColor: htx
	},
	eudtechnology: {
		backgroundColor: eudTechnology
	},
	eudhealth: {
		backgroundColor: eudHealth
	},
	eudoffice: {
		backgroundColor: eudOffice
	},
	eudfood: {
		backgroundColor: eudFood
	},
	euxtechnology: {
		backgroundColor: euxTechnology
	},
	euxhealth: {
		backgroundColor: euxHealth
	},
	euxoffice: {
		backgroundColor: euxOffice
	},
	euxfood: {
		backgroundColor: euxFood
	},
	hf: {
		backgroundColor: hf
	}
});

Font.register({
	family: 'poppins',
	fonts: [
		{
			src: 'https://use.typekit.net/af/f2b266/00000000000000007735fdb4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3.woff2'
		},
		{
			src: 'https://use.typekit.net/af/21f608/00000000000000007735fdb2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3.woff2',
			fontWeight: 'semibold'
		},
		{
			src: 'https://use.typekit.net/af/5066eb/00000000000000007735fdbb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3.woff2',
			fontWeight: 'bold'
		}
	]
});

const ResultDocument = ({keywords}) => {
	const keywordsResults = [];

	/* Get keyword results */
	keywordCategories.forEach((category) => {
		keywordsResults.push(
			{
				title: category.name,
				categoryId: category.id,
				data: calculateDataForKeywordCategory(category.id, keywords).slice(0, 6),
			}
		);
	});
	
	/* Get career results */
	const careerResults = getResultsFromData(keywords, resultsData).slice(0, 3);

	/* Get next step results */
	const nextStepResults = getResultsFromData(keywords, nextStepData).slice(0, 3);

	// A4 is for some reason 595.28mm x 841.89mm,
	// this does fit roughly with aspect ratio for a normal A4 but does fuck up all calculations if not accounted for
	return (
		<Document>
			<Page size={'A4'} style={styles.background} orientation='landscape'>
				{/* BACKGROUND CIRCLES */}
				<View style={{position: 'absolute'}}>
					{/* Background layout */}
					<Svg style={{position: 'absolute', width: '841.89mm', height: '595.28mm'}}>
						<Polygon
							points='0,0 35,0 123.7,595.28 0,595.28'
							fill={fontBlue}
						/>
						<Circle
							cx={(48 / 2 + 9.4) + 'mm'}
							cy={(48 / 2 + 27) + 'mm'}
							r={(48 / 2) + 'mm'}
							fill={yellow}
						/>
						<Circle
							cx={((30 / 2) + 48 + 9.4) + 'mm'}
							cy={((30 / 2) + 27 + 27) + 'mm'}
							r={(30 / 2) + 'mm'}
							fill={orange}
						/>
						<Circle
							cx={((20 / 2) + 29 + 9.4) + 'mm'}
							cy={((20 / 2) + 54 + 27) + 'mm'}
							r={(20 / 2) + 'mm'}
							fill={blue}
						/>
						<Circle
							cx={((615.7 / 2) + 53.7) + 'mm'}
							cy={((615.7 / 2) + 26.6) + 'mm'}
							r={(615.7 / 2) + 'mm'}
							fill={white}
							opacity={0.2}
						/>

						{/* TOP CIRCLES */}
						<Circle
							cy={((199.6 / 2) + 78) + 'mm'}
							cx={((199.6 / 2) + 91.4) + 'mm'}
							r={(199.6 / 2) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((199.6 / 2) + 78) + 'mm'}
							cx={((199.6 / 2) + 261.3) + 'mm'}
							r={(199.6 / 2) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((199.6 / 2) + 78) + 'mm'}
							cx={((199.6 / 2) + 431.6) + 'mm'}
							r={(199.6 / 2) + 'mm'}
							fill={white}
						/>
						
						{/* SIDE CIRCLES */}
						<Circle
							cy={((173.4 / 2) + 81.3) + 'mm'}
							cx={((173.4 / 2) + 653) + 'mm'}
							r={(173.4 / 2) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((138.7 / 2) + 265.4) + 'mm'}
							cx={((138.7 / 2) + 687.7) + 'mm'}
							r={((138.7 / 2)) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((111 / 2) + 414.6) + 'mm'}
							cx={((111 / 2) + 668.9) + 'mm'}
							r={((111 / 2)) + 'mm'}
							fill={white}
						/>

						{/* BOTTOM CIRCLES */}
						<Circle
							cy={((173.4 / 2) + 338.44) + 'mm'}
							cx={((173.4 / 2) + 122.4) + 'mm'}
							r={((173.4 / 2)) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((138.7 / 2) + 355.78) + 'mm'}
							cx={((138.7 / 2) + 318.7) + 'mm'}
							r={((138.7 / 2)) + 'mm'}
							fill={white}
						/>
						<Circle
							cy={((111 / 2) + 369.7) + 'mm'}
							cx={((111 / 2) + 486.5) + 'mm'}
							r={((111 / 2)) + 'mm'}
							fill={white}
						/>
					</Svg>
				</View>

				<View>
					{/* KEYWORDS */}
					<View style={{position: 'absolute'}}>
						<Text style={{
							...styles.title,
							position: 'absolute',
							width: '40mm', 
							top: '13.6mm', 
							left: '45.4mm'
						}}>
							Din profil
						</Text>
						{keywordsResults.map((result, index) => {
							let color = orange;
							let left = 103.9;
							if (result.categoryId === 'skills') {
								color = blue;
								left = 45.4;
							} else if (result.categoryId === 'interests') {
								color = yellow;
								left = 167;
							}

							return (
								<View key={index} 
									style={{
										position: 'absolute',
										top: '41mm',
										left: left + 'mm',
										width: '80mm',
									}}>
									<Text style={styles.subtitle}>{result.title}</Text>
									{result.data.map((data, i) => {
										return (
											<View key={i}
												style={{
													padding: '0mm',
													marginBottom: '-1mm',
													flexDirection: 'row',
													justifyContent: 'flex-start',
													alignItems: 'center'
												}}
											>
												<Text style={{...styles.text, color: color, marginRight: '2mm'}}>
													{data.percent}%
												</Text>
												<Text style={styles.text}>{data.name}</Text>
											</View>
										);
									})}
								</View>
							);
						})}
					</View>
					{/* RESULTS */}
					<View>
						<Text style={{
							...styles.title, 
							position: 'absolute',
							width: '80mm',
							top: '107.3mm',
							left: '45.4mm'
						}}>
							Karriereveje
						</Text>
						{careerResults.map((career, index) => {
							let left = 43.2;
							let top = 119.4;
							let fontsize = 4.5;
							let width = 61.2;
							if (index === 1) {
								left = 112.4;
								top = 125.5;
								fontsize = 4.2;
								width = 49;
							} else if (index === 2) {
								left = 171.6;
								top = 130.4;
								fontsize = 2.8;
								width = 39.1;
							}

							const resultData = resultsData.find((r) => {return r.id === career.resultId;});
							let resultText = resultData.splitTitle ? resultData.splitTitle : resultData.title;
							resultText = resultText.replace(/\./g, '').replace(/\s/g, '_');
							return (
								<View key={index} style={{
									position: 'absolute',
									top: top + 'mm',
									left: left + 'mm',
									width: width + 'mm',
									height: width + 'mm',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
									<Text style={{
										...styles.subtitle,
										textAlign: 'center',
										fontSize: fontsize + 'mm',
									}}>
										{resultText}
									</Text>
								</View>
							);
						})}
					</View>
					{/* NEXT STEPS */}
					<View>
						<Text style={{
							...styles.title,
							top: 14 + 'mm',
							left: 230 + 'mm',
						}}>
							Next step
						</Text>
						{nextStepResults.map((next, index) => {
							const resultData = nextStepData.find((r) => {return r.id === next.resultId;});
							const subtitle = resultData.subtitle;

							const colorFieldClassName = next.resultId.replace('-', '');

							let left = 230.3;
							let top = 28.7;
							let fontsize = 6.5;
							let subFontsize = 3;
							let width = 61.2;
							if (index === 1) {
								left = 242.6;
								top = 93.6;
								fontsize = 6.2;
								subFontsize = 2.65;
								width = 49;
							} else if (index === 2) {
								left = 236;
								top = 146.2;
								fontsize = 5.1;
								subFontsize = 2.5;
								width = 39.1;
							}

							return (
								<View key={index} style={{
									position: 'absolute',
									left: left + 'mm',
									top: top + 'mm',
									width: width + 'mm',
									height: width + 'mm',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
									<Text style={{
										...styles.subtitle,
										width: 62 - (10 * index) + 'mm',
										textAlign: 'center',
										fontSize: fontsize + 'mm',
										marginBottom: '-1mm',
									}}>
										{resultData.title}
									</Text>
									{subtitle &&
										<Text style={{
											width: 62 - (10 * index) + 'mm',
											textAlign: 'center',
											fontSize: subFontsize + 'mm',
										}}>
											{subtitle}
										</Text>
									}
									<View style={{
										...styles[colorFieldClassName],
										width: 15.6 + 'mm', 
										height: 1.6 + 'mm',
										borderRadius: 0.8 + 'mm',
										marginTop: 2.2 + 'mm'
									}}/>
								</View>
							);
						})}
					</View>
				</View>
			</Page>
		</Document>
	);
};

ResultDocument.propTypes = {
	keywords: PropTypes.array.isRequired,
};

export default ResultDocument;