import React, {useState, useEffect} from 'react';
import Proptypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import {getNumberOfPlaythroughs} from 'helpers/stats-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import PopupSchool from './popup-school/popup-school';
import PopupAddSchool from './popup-add-school/popup-add-school';
import PopupDeleteSchool from './popup-delete-school/popup-delete-school';
import './admin-schools.scss';

const AdminSchools = ({schools, tagsData, users, statistics}) => {
	/* Schools sorting  */
	const [sortBy, setSortBy] = useState('name');
	const [sortDir, setSortDir] = useState('ASC');
	
	/* School popup data */
	const [selectedSchoolId, setSelectedSchoolId] = useState(null);
	
	/* Add new school data, loading status and error messages */
	const [showAddSchoolPopup, setShowAddSchoolPopup] = useState(false);
	const [newestSchoolAddition, setNewestSchoolAddition] = useState(null);

	/* Delete school popup */
	const [deleteSchoolId, setDeleteSchoolId] = useState(null);

	/* Tag filter list */
	const [tagList, setTagList] = useState([]);

	/* Filter schools by tag */
	let filteredSchools = JSON.parse(JSON.stringify(schools));
	if (tagList.length > 0) {
		filteredSchools = filteredSchools.filter((school) => {
			let containsTag = false;
			
			// Check if school even has tags, if not no need to check
			if (school.tagIds) {
				tagList.forEach((tag) => {
					const tagIndex = school.tagIds.findIndex((tagId) => {
						return tagId === tag;
					});

					// If we find a tag that is not contained, we break out of the loop
					if (tagIndex > -1) {
						containsTag = true;
						return;
					}
				});
			}

			return containsTag;
		});
	}

	/* Sort schools */
	const sortedSchools = sortArrayByProperty(
		filteredSchools.map((school) => {
			school.playThroughs = getNumberOfPlaythroughs(school.id, statistics);
			return school;
		}), 
		sortBy, 
		sortDir
	);



	/**
	 * Update sorting
	 * @param {string} newSortBy 
	 */
	const handleUpdateSorting = (newSortBy) => {
		if (sortBy === newSortBy) {
			/* Switch direction */
			const newSortDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
			setSortDir(newSortDir);
		} else {
			/* New sort by */
			setSortBy(newSortBy);
			setSortDir('DESC');
		}
	};


	/**
	 * Toggles tag in list of selected tags
	 * @param {string} tagId 
	 */
	const toggleTag = (tagId) => {
		const newList = [...tagList];

		// Checking if tag is already in list
		const tagIndex = newList.findIndex((tag) => {
			return tag === tagId;
		});

		// Removing tag if already in list
		if (tagIndex !== -1) {
			newList.splice(tagIndex, 1);
		} else {
			// Adding tag if not in list
			newList.push(tagId);
		}

		setTagList(newList);
	};


	/**
	 * Update school data
	 * @param {string} schoolId
	 * @param {object} updates
	 * @returns {promise}
	 */
	const updateSchool = (schoolId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update course data */
		const db = firebase.firestore();
		return db.collection(appConfig.schoolsDbName).doc(schoolId).update(updates);
	};

	/**
	 * Scroll to specific school
	 * @param {string} elementId 
	 */
	const scrollToListElement = (elementId) => {
		const list = document.getElementById('AdminSchools-body');
		if (list) {
			const newSchool = document.getElementById(elementId);
			if (newSchool) {
				const listRect = list.getBoundingClientRect();
				const rect = newSchool.getBoundingClientRect();
				const rectCenter = rect.top;
				const listCenter = listRect.top;
				const scrollDiff = rectCenter - listCenter;
				list.scrollBy({
					top: scrollDiff,
					left: 0,
					behavior: 'instant',
				});
			}
		}
	};

	/**
	 * Call scroll-function if new school is added
	 */
	useEffect(() => {
		if (newestSchoolAddition) {
			scrollToListElement(newestSchoolAddition);
			setSelectedSchoolId(newestSchoolAddition);
		}
	}, [newestSchoolAddition]);

	return (
		<div className="AdminSchools">
			{/* Add school btn */}
			<div className='AdminSchools-addSchoolButton'>
				<Button 
					classes={['white', 'confirmTask', 'shadow', 'responsive']} 
					text={getText('adminTexts', 'addSchool')} 
					onClick={() => {setShowAddSchoolPopup(true);}}
				/>
			</div>

			<div className='AdminSchools-filters'>
				<div className='AdminSchools-filterTitle'>
					<span>{getText('adminTexts', 'tags') + ':'}</span>
				</div>
				<div className='AdminSchools-tags'>
					{tagsData && tagsData.map((tag) => {
						// Checking if tag is selected
						const tagIndex = tagList.findIndex((tagId) => {
							return tagId === tag.id;
						});

						const isSelected = tagIndex !== -1;
					
						return (
							<div key={tag.id} className='AdminSchools-tagWrapper'>
								<div className={'AdminSchools-tagToggle' + (isSelected ? ' selected' : '')}
									onClick={() => {toggleTag(tag.id);}}/>
								<div className='AdminSchools-tagName'>
									<span>{tag.value}</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* List of schools */}
			<div className='AdminSchools-schools'>
				<div className='AdminSchools-row header'>
					<div 
						className={'AdminSchools-cell name sortable' + (sortBy === 'name' ? ' sort ' + sortDir : '')}
						onClick={() => {handleUpdateSorting('name');}}
					>
						<span>{getText('adminTexts', 'school')}</span>
						<span>&nbsp;</span>
					</div>
					<div className='AdminSchools-cell password'>
						<span>{getText('adminTexts', 'password')}</span>
					</div>
					<div className='AdminSchools-cell facilitators'>
						<span>{getText('adminTexts', 'facilitators')}</span>
					</div>
					<div 
						className={'AdminSchools-cell created sortable' 
							+ (sortBy === 'created' ? ' sort ' + sortDir : '')}
						onClick={() => {handleUpdateSorting('created');}}
					>
						<span>{getText('adminTexts', 'created')}</span>
						<span>&nbsp;</span>
					</div>
					<div className={'AdminSchools-cell plays sortable'
						+ (sortBy === 'playThroughs' ? ' sort ' + sortDir : '')}
					onClick={() => {handleUpdateSorting('playThroughs');}}
					>
						<span>{getText('adminTexts', 'playthroughCounts')}</span>
						<span>&nbsp;</span>
					</div>
					<div className='AdminSchools-cell delete'>
						<span>{getText('adminTexts', 'delete')}</span>
					</div>
				</div>
				<div id="AdminSchools-body" className='AdminSchools-body'>
					{sortedSchools.map((school, index) => {
						return (
							<div 
								key={index} 
								id={school.id}
								className='AdminSchools-row body' 
								onClick={() => {setSelectedSchoolId(school.id);}}
							>
								<div className='AdminSchools-cell name leftAligned'>
									<span>{school.name}</span>
								</div>
								<div className='AdminSchools-cell password rightAligned'>
									<span>{school.password}</span>
								</div>
								<div className='AdminSchools-cell facilitators leftAligned'>
									<span>{(school.facilitatorEmails ? school.facilitatorEmails.length : 0)}</span>
								</div>
								<div className='AdminSchools-cell created rightAligned'>
									<span>{school.created ? dayjs(school.created).format('DD/MM/YY') : '-'}</span>
								</div>
								<div className='AdminSchools-cell plays rightAligned'>
									<span>{school.playThroughs}</span>
								</div>
								<div className='AdminSchools-cell delete rightAligned'>
									<div className='AdminSchools-deleteButton'
										onClick={(e) => {e.stopPropagation(); setDeleteSchoolId(school.id);}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* School popup */}
			{
				(selectedSchoolId && 
				schools.some((school) => {return school.id === selectedSchoolId;})) &&
					<PopupSchool 
						schoolData = {schools.find((school) => {return school.id === selectedSchoolId;})}
						users={users}
						schools={schools} 
						tagsData={tagsData} 
						updateSchool={updateSchool}
						setSelectedSchoolId = {setSelectedSchoolId}
					/>
			}

			{/* Add school popup */}
			{showAddSchoolPopup &&
				<PopupAddSchool 
					tagsData={tagsData} 
					schools={schools} 
					users={users}
					setShowAddSchoolPopup={setShowAddSchoolPopup} 
					setNewestSchoolAddition={setNewestSchoolAddition}
				/>}

			{/* Delete school popup */}
			{deleteSchoolId &&
				<PopupDeleteSchool
					schoolId={deleteSchoolId}
					setDeleteSchoolId={setDeleteSchoolId}
					schools = {schools}
				/>}

		</div>
	);
};

AdminSchools.propTypes = {
	schools: Proptypes.array.isRequired,
	tagsData: Proptypes.array.isRequired,
	users: Proptypes.array.isRequired,
	statistics: Proptypes.array.isRequired
};

export default AdminSchools;