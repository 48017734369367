const statsData = [
	{
		id: 'nextSteps',
		title: 'Ungdomsuddannelser',
		defaultSortBy: 'firstPercentage',
		defaultSortDir: 'DESC',
		columns: [
			{
				id: 'result',
				title: 'Resultat',
				statProp: 'title',
				statProp2: 'subtitle',
				type: 'text',
				isSortable: true
			},
			{
				id: 'top1',
				title: 'Top 1',
				statProp: 'firstPercentage',
				type: 'percent',
				isSortable: true
			},
			{
				id: 'top3',
				title: 'Top 3',
				statProp: 'topThreePercentage',
				type: 'percent',
				isSortable: true
			},
			{
				id: 'averagePercent',
				title: 'Gns. %',
				statProp: 'avrPercentage',
				type: 'percent',
				isSortable: true
			}
		]
	},
	{
		id: 'careerPaths',
		title: 'Karriereveje',
		defaultSortBy: 'firstPercentage',
		defaultSortDir: 'DESC',
		columns: [
			{
				id: 'result',
				title: 'Resultat',
				statProp: 'title',
				statProp2: 'subtitle',
				type: 'text',
				isSortable: true
			},
			{
				id: 'top1',
				title: 'Top 1',
				statProp: 'firstPercentage',
				type: 'percent',
				isSortable: true
			},
			{
				id: 'top3',
				title: 'Top 3',
				statProp: 'topThreePercentage',
				type: 'percent',
				isSortable: true
			},
			{
				id: 'averagePercent',
				title: 'Gns. %',
				statProp: 'avrPercentage',
				type: 'percent',
				isSortable: true
			}
		]
	},
	{
		id: 'keywords',
		title: 'Nøgleord',
		defaultSortBy: 'average',
		defaultSortDir: 'DESC',
		columns: [
			{
				id: 'keyword',
				title: 'Nøgleord',
				statProp: 'title',
				type: 'text',
				isSortable: true
			},
			{
				id: 'category',
				title: 'Kategori',
				statProp: 'category',
				type: 'text',
				isSortable: true
			},
			{
				id: 'average',
				title: 'Gns. %',
				statProp: 'average',
				type: 'percent',
				isSortable: true
			}
		]
	}
];

export {
	statsData,
};