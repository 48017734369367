import React from 'react';
import PropTypes from 'prop-types';
import {getExpandedResults} from 'helpers/keywords-helpers';
import {resultsData} from 'data/results-data';
import {keywordsData} from 'data/keywords-data';
import './popup-results.scss';

const PopupResults = ({keywordPoints, setShowResultsPopup}) => {
 
	const expandedResults = getExpandedResults(keywordPoints);

	return (
		<div className="PopupResults" onClick={() => {setShowResultsPopup(false);}}>
			<div className="PopupResults-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="PopupResults-closeBtn" onClick={() => {setShowResultsPopup(false);}}/>
				<div className="PopupResults-text">
					{expandedResults.map((result) => {
						const resultData = resultsData.find((r) => {return r.id === result.resultId;});
						if (!resultData) return null;

						return (
							<div key={result.resultId} className="PopupResults-result">
								<div className="PopupResults-titleAndScore">
									<span>{resultData.title}</span>
									<span>{Math.round(result.score * 10000) / 100.}</span>
								</div>
								<div className="PopupResults-keywords">
									{result.keywords.map((keyword) => {
										const keywordData = keywordsData.find((k) => {
											return k.id === keyword.keywordId;
										});
										if (!keywordData) return null;

										return (
											<span key={keyword.keywordId}>
												{keywordData.title} ({keyword.points} / {keyword.maxPoints})
											
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

PopupResults.propTypes = {
	keywordPoints: PropTypes.array.isRequired,
	setShowResultsPopup: PropTypes.func.isRequired,
};

export default PopupResults;
