import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {getOrderEffects} from 'helpers/module-helper';
import Button from 'components/ui/button/button';
import TaskIntro from 'components/modules/task-intro/task-intro';
import OrderDndBackgroundContainer from './order-dnd-background-container';
import OrderDndContainer from './order-dnd-container';
import OrderDndItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import './order.scss';

const Order = (props) => {
	const {
		moduleId,
		previousTaskData, 
		taskData,
		handleCompleteTask,
		updateLoggedTime,
		goToPreviousTask,
		canGoBack
	} = props;

	/* Track ordered items */
	const [orderedItems, setOrderedItems] = useState([]);
	/* Holds necessary data for showing item preview */
	const [previewItem, setPreviewItem] = useState();
	const [isLoading, setIsLoading] = useState(false);

	/* Check if all items have been placed in final containers */
	const allItemsInFinalContainers = !orderedItems.some((item) => {return item.location === 'initial';});

	/* Get items to be ordered */
	const getOrderedItems = () => {
		let items = [];

		if (previousTaskData && previousTaskData.orderedItems) {
			/* Task solved, get items from player data */	
			items = JSON.parse(JSON.stringify(previousTaskData.orderedItems));
			items.forEach((item, index) => {
				if (!item.location) item.location = 'final';
				if (!item.positionNumber) item.positionNumber = index + 1;
			});
		} else {
			/* Get items data file and shuffle */
			items = taskData.items.map((item) => {return {id: item.id};});
			items.forEach((item, index) => {
				item.location = 'initial';
				item.positionNumber = index + 1;
			});
		}
		return items;
	};


	/* Update ordered items if new task */
	useEffect(() => {
		setIsLoading(false);
		setOrderedItems(getOrderedItems());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);



	/**
	 * Switch items
	 * @param {string} itemId 
	 * @param {string} toLocation
	 * @param {number} toPositionNumber 
	 */
	const handleMoveItem = (itemId, toLocation, toPositionNumber) => {
		// Stop preview as we are no longer dragging an item
		if (previewItem) {
			setPreviewItem(null);
		}

		/* Move / switch items */
		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		
		/* Update logged time */
		updateLoggedTime();

		const fromIndex = orderedItems.findIndex((i) => {return i.id === itemId;});
		const toIndex = orderedItems.findIndex((i) => {
			return (i.location === toLocation && i.positionNumber === toPositionNumber);
		});

		/* Move item */
		newOrderedItems[fromIndex].location = toLocation;
		newOrderedItems[fromIndex].positionNumber = toPositionNumber;
		if (toIndex >= 0) {
			/* Switch item */
			newOrderedItems[toIndex].location = orderedItems[fromIndex].location;
			newOrderedItems[toIndex].positionNumber = orderedItems[fromIndex].positionNumber;
		}
		setOrderedItems(newOrderedItems);
	};

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Check if all items have been placed */
		if (!allItemsInFinalContainers) return;
		
		/* Get effects */
		let newEffects = getOrderEffects(taskData, orderedItems);

		/* Complete task */
		handleCompleteTask(
			newEffects,
			{orderedItems: orderedItems}
		);
	};

	/**
	 * Sets preview item data
	 * @param {string} hoveredItem 
	 * @param {number} hoveringItem 
	 */
	const handleSetPreviewItem = (hoveredItem, hoveringItem) => {
		if (!previewItem || (previewItem && previewItem.id !== hoveredItem.id)) {
			const newPreviewItem = {
				id: hoveredItem.id,
				itemLocation: hoveredItem.location,
				itemPositionNumber: hoveredItem.positionNumber,
				previewLocation: hoveringItem.location, 
				previewPositionNumber: hoveringItem.positionNumber
			};
			setPreviewItem(newPreviewItem);
		}
	};

	/**
	 * Handles unsetting preview item, if there is one.
	 * @param {string} location 
	 * @param {number} positionNumber 
	 */
	const handleUnsetPreviewItem = (location, positionNumber) => {
		if (previewItem && 
			// We are not hovering over the preview items location anymore
			(previewItem.itemLocation !== location || previewItem.itemPositionNumber !== positionNumber)) {
			setPreviewItem(null);
		}
	};

	return (
		<div className={'Order'}>
			<div id="orderContainer" className="Order-content">
				<div id="taskIntro" className="Order-intro">
					<TaskIntro 
						moduleId={moduleId}
						taskId={taskData.id}
						text={taskData.text}
						image={taskData.image}
					/>
				</div>
				<div className='Order-backgroundContainer'>
					<OrderDndBackgroundContainer handleUnsetPreviewItem={handleUnsetPreviewItem}/>
				</div>
				<div className={'Order-items size-' + orderedItems.length}>
					{/* Final containers */}
					{[...Array(orderedItems.length)].map((_, index) => {
						/* Check if can be dropped into  */		
						return (
							<div key={index} className={'Order-container final pos-' + (index + 1)}>
								<div className="Order-containerNumber"><span>{(index + 1)}</span></div>
								<OrderDndContainer
									layout={taskData.layout ? taskData.layout : null}
									location="final"
									positionNumber={index + 1}
									handleMoveItem={handleMoveItem}
									handleUnsetPreviewItem={handleUnsetPreviewItem}
								/>
							</div>
						);
					})}

					{/* Initial containers */}
					{[...Array(orderedItems.length)].map((_, index) => {
						return (
							<div key={index} className={'Order-container initial pos-' + (index + 1)}>
								<OrderDndContainer
									layout={taskData.layout ? taskData.layout : null}
									location="initial"
									positionNumber={index + 1}
									handleMoveItem={handleMoveItem}
									handleUnsetPreviewItem={handleUnsetPreviewItem}
								/>
							</div>
						);
					})}

					{/* Items (also work as containers) */}
					{orderedItems.map((item, index) => {
						/* Get placed item data (if any) */
						const itemData = taskData.items.find((i) => {return i.id === item.id;});
						if (!itemData) return null;
						
						let className = 'Order-item ';

						const isPreview = previewItem && previewItem.id === itemData.id;
						if (isPreview) {
							className = className + 
								previewItem.previewLocation + 
								' pos-' + previewItem.previewPositionNumber;
						} else {
							className = className + item.location + ' pos-' + item.positionNumber;
						}

						

						return (
							<div 
								key={index} 
								className={className}
							>
								<OrderDndItem
									layout={taskData.layout ? taskData.layout : null}
									location={item.location}
									positionNumber={item.positionNumber}
									itemData={itemData}
									isPreview={isPreview}
									handleMoveItem={handleMoveItem}
									handleSetPreviewItem={handleSetPreviewItem}
								/>
							</div>
						);
					})}
					<OrderDndPreview itemsData={taskData.items} />
				</div>
			
				{/* Done button */}
				{<div className="Order-doneBtn">
					<Button
						isDisabled={!allItemsInFinalContainers || isLoading}
						classes={['white', 'confirmTask', 'responsive', 'shadow']}
						text={getText('gameUi', 'ok')}
						onClick={() => {confirmOrder(); setIsLoading(true);}}
					/>
				</div>}
				{canGoBack &&
					<div className='Order-previousButton'>
						<Button
							isDisabled={isLoading}
							classes={['white', 'confirmTask', 'responsive', 'shadow']}
							text={getText('gameUi', 'back')}
							onClick={() => {goToPreviousTask(true); setIsLoading(true);}}
						/>
					</div>
				}
			</div>
		</div>
	);
};

Order.propTypes = {
	moduleId: PropTypes.string.isRequired,
	previousTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	goToPreviousTask: PropTypes.func.isRequired,
	canGoBack: PropTypes.bool.isRequired,
};

export default Order;
