/**
 * Sort an array of objects by a specific property (tiebreaker second and third properties optional)
 * @param {array} array 
 * @param {string} propertyName 
 * @param {string} order 
 * @param {string} secondPropertyName 
 * @param {string} secondOrder 
 * @param {string} thirdPropertyName 
 * @param {string} thirdOrder 
 * @returns 
 */
export function sortArrayByProperty(
	array, propertyName, order = 'ASC',
	secondPropertyName = null, secondOrder = 'ASC',
	thirdPropertyName = null, thirdOrder = 'ASC'
) {
	if (!array || array.length < 2) return array;
	let sortedArray = array.sort(function(a, b) {
		if (!a.hasOwnProperty(propertyName) || !b.hasOwnProperty(propertyName)) return 0;

		const aVal = (typeof a[propertyName] === 'string' ? a[propertyName].toLowerCase() : a[propertyName]);
		const bVal = (typeof b[propertyName] === 'string' ? b[propertyName].toLowerCase() : b[propertyName]);
		const aVal2 = (secondPropertyName && a.hasOwnProperty(secondPropertyName) ? 
			(typeof a[secondPropertyName] === 'string' 
				? a[secondPropertyName].toLowerCase() : a[secondPropertyName])
			: null
		);
		const bVal2 = (secondPropertyName && b.hasOwnProperty(secondPropertyName) ? 
			(typeof b[secondPropertyName] === 'string' 
				? b[secondPropertyName].toLowerCase() : b[secondPropertyName])
			: null
		);
		const aVal3 = (thirdPropertyName && a.hasOwnProperty(thirdPropertyName) ? 
			(typeof a[thirdPropertyName] === 'string' 
				? a[thirdPropertyName].toLowerCase() : a[thirdPropertyName])
			: null
		);
		const bVal3 = (thirdPropertyName && b.hasOwnProperty(thirdPropertyName) ? 
			(typeof b[thirdPropertyName] === 'string' 
				? b[thirdPropertyName].toLowerCase() : b[thirdPropertyName])
			: null
		);
		/* Sort by third property */
		if (
			aVal === bVal && 
			aVal2 !== null && bVal2 !== null && aVal2 === bVal2 &&
			aVal3 !== null && bVal3 !== null
		) {
			if (thirdOrder === 'ASC') {
				if (aVal3 < bVal3) { return -1; }
				if (aVal3 > bVal3) { return 1; }
				return 0;
		
			} else if (thirdOrder === 'DESC') {
				if (aVal3 > bVal3) { return -1; }
				if (aVal3 < bVal3) { return 1; }
				return 0;
			}
			return 0;
		} 

		/* Sort by secondary property */
		if (aVal === bVal && aVal2 !== null && bVal2 !== null) {
			if (secondOrder === 'ASC') {
				if (aVal2 < bVal2) { return -1; }
				if (aVal2 > bVal2) { return 1; }
				return 0;
		
			} else if (secondOrder === 'DESC') {
				if (aVal2 > bVal2) { return -1; }
				if (aVal2 < bVal2) { return 1; }
				return 0;
			}
			return 0;
		} 

		/* Sort by primary property */
		if (order === 'ASC') {
			if (aVal < bVal) { return -1; }
			if (aVal > bVal) { return 1; }
			return 0;
		} else if (order === 'DESC') {
			if (aVal > bVal) { return -1; }
			if (aVal < bVal) { return 1; }
			return 0;
		} 
		return 0;
	});
	return sortedArray;
};

/**
 * Sorts array of strings according to likeness og given value.
 * @param {array} list 
 * @param {string} value string value to check likeness against
 * @returns array with all list values containing value in order by likeness 
 */
export function sortArrayByLikeness(list, value, property) {
	const valueLower = value.toLowerCase();

	// Filtering off all values that do not contain the likeness value at all
	let resultingArray = list.filter((listValue) => {
		// Lower casing values to ignore differences in casing
		return listValue[property].toLowerCase().includes(valueLower);
	});

	resultingArray = resultingArray.sort((current, next) => {
		// Lower casing values to ignore differences in casing
		const nextLower = next[property].toLowerCase();
		const currentLower = current[property].toLowerCase();

		// Next is the search value, it should trumph all other values
		if (nextLower === valueLower) {
			return 1;
		}
		// Next starts with value while current doesn't
		if (nextLower.startsWith(valueLower) && !currentLower.startsWith(valueLower)) {
			return 1;
		}
		// Current starts with value while next doesn't
		if (!nextLower.startsWith(valueLower) && currentLower.startsWith(valueLower)) {
			return -1;
		}
		// Both starts with value, we check which one is closes to the full value
		if (nextLower.startsWith(valueLower) && currentLower.startsWith(valueLower)) {
			if (currentLower.length < nextLower.length) {
				return -1;
			} else if (currentLower.length > nextLower.length) {
				return 1;
			}
		}

		return 0;
	});

	return resultingArray;
}

/**
 * Randomly shuffle an array of integers
 * @param {array} array 
 * @returns 
 */
export function shuffleArray(array) {
	if (array.length < 2) {return array;}
	// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
	return array.map((a) => { return [Math.random(), a];})
		.sort((a, b) => { return a[0] - b[0];})
		.map((a) => { return a[1];});
};