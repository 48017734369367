const careersData = [
	{
		id: 'accountant',
		title: 'Revisor'
	},
	{
		id: 'architect',
		title: 'Arkitekt'
	},
	{
		id: 'art-and-culture-courses',
		title: 'Kunst- og kulturfag'
	},
	{
		id: 'bakerPastryChef',
		title: 'Bager og konditor'
	},
	{
		id: 'bio-analyst',
		title: 'Bioanalytiker'
	},
	{
		id: 'biologist',
		title: 'Biolog'
	},
	{
		id: 'biology',
		title: 'Biologi'
	},
	{
		id: 'blacksmith',
		title: 'Smed'
	},
	{
		id: 'building-constructor',
		title: 'Bygningskonstruktør'
	},
	{
		id: 'busDriver',
		title: 'Buschauffør'
	},
	{
		id: 'shop-butcher',
		title: 'Butiksslagter' 
	},
	{
		id: 'car-painter',
		title: 'Autolakerer'
	},
	{
		id: 'carpenter',
		title: 'Tømrer'
	},
	{
		id: 'case-manager',
		title: 'Sagsbehandler'
	},
	{
		id: 'chef',
		title: 'Kok'
	},
	{
		id: 'chemistry',
		title: 'Kemi'
	},
	{
		id: 'coach',
		title: 'Coach'
	},
	{
		id: 'communications-officer',
		title: 'Kommunikationsmedarbejder'
	},
	{
		id: 'communicator',
		title: 'Formidler'
	},
	{
		id: 'controller',
		title: 'Controller'
	},
	{
		id: 'crane-operator',
		title: 'Kranfører'
	},
	{
		id: 'doctor',
		title: 'Læge'
	},
	{
		id: 'electrician',
		title: 'Elektriker'
	},
	{
		id: 'engineer',
		title: 'Ingeniør'
	},
	{
		id: 'farmer',
		title: 'Landmand'
	},
	{
		id: 'film-and-tv-production',
		title: 'Film- og tv-produktion'
	},
	{
		id: 'finance-education',
		title: 'Finansuddannelsen'
	},
	{
		id: 'gameDesigner',
		title: 'Spildesigner'
	},
	{
		id: 'gardener',
		title: 'Gartner'
	},
	{
		id: 'geography',
		title: 'Geografi'
	},
	{
		id: 'geology',
		title: 'Geologi'
	},
	{
		id: 'graphic-layouter',
		title: 'Grafisk layouter'
	},
	{
		id: 'highSchoolTeacher',
		title: 'Gymnasielærer'
	},
	{
		id: 'history',
		title: 'Historie'
	},
	{
		id: 'industrial-operator',
		title: 'Industrioperatør'
	},
	{
		id: 'lab-technician',
		title: 'Laborant'
	},
	{
		id: 'language-courses',
		title: 'Sprogfagene'
	},
	{
		id: 'lighting-technician',
		title: 'Lystekniker'
	},
	{
		id: 'machinist',
		title: 'Maskinmester'
	},
	{
		id: 'mason',
		title: 'Murer'
	},
	{
		id: 'math',
		title: 'Matematik'
	},
	{
		id: 'mechanic',
		title: 'Mekaniker'
	},
	{
		id: 'media-graphic-designer',
		title: 'Mediegrafiker'
	},
	{
		id: 'nurse',
		title: 'Sygeplejerske'
	},
	{
		id: 'nutritionist',
		title: 'Ernæringsassistent'
	},
	{
		id: 'office-education',
		title: 'Kontoruddannelsen'
	},
	{
		id: 'orthopaedist',
		title: 'Ortopædist'
	},
	{
		id: 'painter',
		title: 'Maler',
	},
	{
		id: 'pedagogue',
		title: 'Pædagog'
	},
	{
		id: 'pharmacologist',
		title: 'Farmakolog'
	},
	{
		id: 'physics',
		title: 'Fysik'
	},
	{
		id: 'physiotherapist',
		title: 'Fysioterapeut'
	},
	{
		id: 'plastic-maker',
		title: 'Plastmager'
	},
	{
		id: 'priest',
		title: 'Præst'
	},
	{
		id: 'programmer',
		title: 'Programmør'
	},
	{
		id: 'project-leader',
		title: 'Projektleder'
	},
	{
		id: 'psychologist',
		title: 'Psykolog'
	},
	{
		id: 'reporter',
		title: 'Journalist'
	},
	{
		id: 'researcher',
		title: 'Forsker'
	},
	{
		id: 'shipping',
		title: 'Spedition'
	},
	{
		id: 'social-worker',
		title: 'Socialrådgiver'
	},
	{
		id: 'storeEmployee',
		title: 'Butiksmedarbejder'
	},
	{
		id: 'structurer',
		title: 'Struktør'
	},
	{
		id: 'teacher',
		title: 'Lærer'
	},
	{
		id: 'trainDriver',
		title: 'Lokofører'
	},
	{
		id: 'truckDriver',
		title: 'Lastvognschauffør'
	},
	{
		id: 'ui-developer',
		title: 'UI-udvikler'
	},
	{
		id: 'ux-developer',
		title: 'UX-udvikler'
	},
	{
		id: 'waiter',
		title: 'Tjener'
	},
	{
		id: 'web-developer',
		title: 'Webudvikler'
	},
	{
		id: 'writer',
		title: 'Tekstforfatter'
	},
	{
		id: 'vvs',
		title: 'VVS'
	},
	{
		id: 'woodworker',
		title: 'Snedker'
	},
	{
		id: 'police',
		title: 'Politi'
	},
	{
		id: 'ambulance',
		title: 'Ambulance'
	},
	{
		id: 'fire',
		title: 'Brand'
	},
	{
		id: 'ambulance-rescuer',
		title: 'Ambulanceredder'
	},
	{
		id: 'counseler',
		title: 'Vejleder'
	},
	{
		id: 'psychology',
		title: 'Psykologi'
	},
	{
		id: 'pedagogy',
		title: 'Pædagogik'
	},
	{
		id: 'law',
		title: 'Jura'
	},
	{
		id: 'nature-guide',
		title: 'Naturvejleder'
	},
	{
		id: 'nature-educator',
		title: 'Naturpædagog'
	},
	{
		id: 'machine-joiner',
		title: 'Maskinsnedker'
	},
	{
		id: 'industrial-technician',
		title: 'Industritekniker'
	},
	{
		id: 'producer',
		title: 'Produktør'
	},
	{
		id: 'landscape-gardener',
		title: 'Anlægsgartner'
	},
	{
		id: 'glazier',
		title: 'Glarmester'
	},
	{
		id: 'sosu-assistant',
		title: 'Social- og sundhedsassistent',
	},
	{
		id: 'sosu-helper',
		title: 'Social- og sundhedshjælper'
	},
	{
		id: 'dental-clinic-assistant',
		title: 'Tandklinikassistent'
	},
	{
		id: 'dentist',
		title: 'Tandlæge'
	},
	{
		id: 'horticulture-consultant',
		title: 'Havebrugskonsulent'
	},
	{
		id: 'forest-nature-technician',
		title: 'Skov- og naturtekniker'
	},
	{
		id: 'communication',
		title: 'Kommunikation'
	},
	{
		id: 'nanoscience',
		title: 'Nanoscience'
	},
	{
		id: 'sociology',
		title: 'Sociologi'
	},
	{
		id: 'administration',
		title: 'Forvaltning'
	},
	{
		id: 'economy',
		title: 'Økonomi'
	},
	{
		id: 'business-economics',
		title: 'Erhvervsøkonomi'
	},
	{
		id: 'hairdresser',
		title: 'Frisør'
	},
	{
		id: 'accountant2',
		title: 'Bogholder'
	},
	{
		id: 'receptionist',
		title: 'Receptionist'
	},
	{
		id: 'pedagogy-assistant',
		title: 'Pædagogisk assistent'
	},
	{
		id: 'av-technician',
		title: 'AV-tekniker'
	},
	{
		id: 'production-and-assembly',
		title: 'Produktion og montage'
	},
	{
		id: 'boat-mechanic',
		title: 'Bådmekaniker'
	},
	{
		id: 'trainer',
		title: 'Træner'
	},
	{
		id: 'pe-teacher',
		title: 'Idrætslærer'
	},
	{
		id: 'personal-trainer',
		title: 'Personlig træner'
	},
	{
		id: 'dietary-advisor',
		title: 'Kostvejleder'
	},
	{
		id: 'health-nurse',
		title: 'Sundhedsplejerske'
	},
	{
		id: 'public-health-science',
		title: 'Folkesundhedsvidenskab'
	},
	{
		id: 'construction-technician',
		title: 'Byggetekniker'
	},
	{
		id: 'media-studies',
		title: 'Medievidenskab'
	},
	{
		id: 'philosophy',
		title: 'Filosofi'
	},
	{
		id: 'history-of-ideas',
		title: 'Idéhistorie'
	},
	{
		id: 'biotech',
		title: 'Bioteknologi'
	},
	{
		id: 'computer-science',
		title: 'Datalogi'
	},
	{
		id: 'socialstudies',
		title: 'Samfundsfag'
	},
	{
		id: 'political-science',
		title: 'Statskundskab'
	},
	{
		id: 'social-science',
		title: 'Socialvidenskab'
	},
	{
		id: 'business-education',
		title: 'Handelsuddannelsen'
	},
	{
		id: 'public-administration',
		title: 'Offentlig forvaltning'
	},
	{
		id: 'financial-controller',
		title: 'Financial controller'
	},
	{
		id: 'computer-scientist',
		title: 'Datamatiker'
	},
	{
		id: 'designer',
		title: 'Designer'
	},
	{
		id: 'multimedia-designer',
		title: 'Multimediedesigner'
	},
	{
		id: 'cultural-worker',
		title: 'Kulturmedarbejder'
	},
	{
		id: 'consultant',
		title: 'Konsulent'
	},
	{
		id: 'defense',
		title: 'Forsvaret'
	},
	{
		id: 'environmental-technologist',
		title: 'Miljøteknolog'
	},
	{
		id: 'car-assistance',
		title: 'Autohjælp'
	},
	{
		id: 'automotive-technologist',
		title: 'Autoteknolog'
	}
];

export {
	careersData
};