import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import resetAnimation from 'helpers/animation-helper';
import './order-dnd-item.scss';

const OrderDndItem = (props) => {
	const {
		layout,
		location, 
		positionNumber, 
		itemData,
		isPreview,
		handleMoveItem,
		handleSetPreviewItem,
	} = props;

	const itemId = 'OrderDndItem-' + itemData.id;
	const wrapperId = 'OrderDndWrapper-' + itemData.id;
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [shouldAnimate, setShouldAnimate] = useState(false);

	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {type: 'item', id: (itemData ? itemData.id : null), location, positionNumber},
		isDragging(monitor) {
			return (itemData && itemData.id === monitor.getItem().id);
		},
		collect: (monitor) => {
			return {isDragging: monitor.isDragging()};
		},
	});

	/* Drop functionality */
	const [, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (handleMoveItem) {			
				/* Ignore hovering over itself */
				if (item.location === location && item.positionNumber === positionNumber) {
					return;
				}

				/* Move card */
				handleMoveItem(item.id, location, positionNumber);
			}
		},
		collect: (monitor) => {
			const monitorItem = monitor.getItem();

			// We are being hovering by over something that is not this item and location
			if (monitor.isOver() && !isPreview &&
				itemData.id !== monitorItem.id) {
				handleSetPreviewItem({
					id: itemData.id,
					location: location,
					positionNumber: positionNumber
				}, monitorItem);
			}
		}
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	useEffect(() => {
		if (!isFirstRender) {
			setShouldAnimate(true);
			resetAnimation(itemId);
			resetAnimation(wrapperId);
		} else {
			setIsFirstRender(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, positionNumber]);

	/* Opacity (invisible if dragging) */
	let opacity = (isDragging ? 0 : 1);
	/* Opacity (low if preview) */
	opacity = (isPreview ? 0.5 : opacity);

	/* Class name */
	let className = 'OrderDndItem' + (layout ? ' ' + layout : '');
	if (isPreview) className += ' isPreview';
	if (shouldAnimate) {
		className += ' animate';
	}

	const ref = useRef(null);
	const dragDropRef = drag(drop(ref));
	
	return (
		<div
			id={itemId}
			ref={dragDropRef}
			className={className}
			style={{opacity}}
		>
			<div id={wrapperId} className={'OrderDndItem-wrap' + (shouldAnimate ? ' animate' : '')}>
				{(itemData && itemData.text) && 
					<span>{itemData.text}</span>
				}
			</div>
		</div>
	);
};

OrderDndItem.propTypes = {
	layout: PropTypes.string,
	location: PropTypes.string.isRequired,
	positionNumber: PropTypes.number.isRequired,
	itemData: PropTypes.object,
	isPreview: PropTypes.bool,
	handleMoveItem: PropTypes.func.isRequired,
	handleSetPreviewItem: PropTypes.func.isRequired,
};

export default OrderDndItem;
