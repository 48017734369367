import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import { getText } from 'helpers/text-helper';
import appConfig from 'config/app.config';
import Button from 'components/ui/button/button';
import InputSearch from 'components/ui/input-search/input-search';
import './login.scss';

const Login = ({handleLogin}) => {
	/* Schools subscription and data */
	const unsubscribeSchools = useRef(null);
	const [schools, setSchools] = useState([]);
	
	/* Login input */
	const [selectedSchoolId, setSelectedSchoolId] = useState(null);
	const [schoolClass, setSchoolClass] = useState('');
	const [isClassValid, setIsClassValid] = useState(false);
	const [password, setPassword] = useState('');
	
	/* Error message */
	const [errorMessage, setErrorMessage] = useState(null);

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Subscribe to school */
		subscribeToSchools();

		return () => {
			/* Unsubscribe on unmount */
			if (unsubscribeSchools.current) {
				unsubscribeSchools.current();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * Handles login class input
	 * @param {object} event 
	 */
	const handleClassInput = (event) => {
		/* Validate and update input */
		const value = event.target.value;
		validateClassInput(value);
		setSchoolClass(value);

		/* Resetting error message if there is one */
		setErrorMessage(null);
	};

	/**
	 * Validates current class input
	 */
	const validateClassInput = (input) => {
		const classInput = cleanClassInput(input);

		const regex = new RegExp('^\\d{1,2}\\D{1,2}$');
		if (regex.test(classInput)) {
			setIsClassValid(true);
		} else {
			setIsClassValid(false);
			setErrorMessage(getText('error', 'invalidClass'));
		}
	};

	/**
	 * Removes spaces, commas and periods
	 * @param {string} input 
	 */
	const cleanClassInput = (input) => {
		// eslint-disable-next-line no-useless-escape
		return input.replace(/\./g, '').replace(/\,/g, '').replace(/ /g, '').toLowerCase();
	};

	/**
	 * Handles login input
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		/* Update value */
		const value = event.target.value;
		setPassword(value);
		
		/* Reset error message */
		setErrorMessage(null);
	};

	/**
	 * Subscribe to schools
	 */
	const subscribeToSchools = () => {
		if (unsubscribeSchools.current !== null) unsubscribeSchools.current();
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			unsubscribeSchools.current = db.collection(appConfig.schoolsDbName).onSnapshot(
				(querySnapshot) => {
					let schools = [];
					querySnapshot.forEach((doc) => {
						schools.push({id: doc.id, ...doc.data()});
					});
					setSchools(schools);
					resolve();
				},
				(error) => {
					console.error(getText('error', 'couldNotGetSchool'), error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Validates login
	 * @returns 
	 */
	const validateLogin = () => {
		return new Promise((resolve) => {
			/* Get school */
			const db = firebase.firestore();
			db.collection(appConfig.schoolsDbName).doc(selectedSchoolId).get().then((doc) => {
				if (doc.exists && doc.data()) {
					if (doc.data().password === password) {

						handleLogin(selectedSchoolId, doc.data().name, cleanClassInput(schoolClass));
					} else {
						setErrorMessage(getText('error', 'invalidLogin'));
						resolve({status: 'error', error: 'incorrect-login'});
					}
				} else {
					setErrorMessage(getText('error', 'invalidLogin'));
					resolve({status: 'error', error: 'incorrect-login'});
				}
			}).catch((error) => {
				console.error(error);
				setErrorMessage( getText('error', 'unknownError'));
				resolve({status: 'error', error: 'noLoginData'});
			});
		});
	};

	return (
		<div className='Login'>
			<div className='Login-wrapper'>
				<form className='Login-form' onSubmit={(event) => {event.preventDefault(); validateLogin();}}>
					<div className='Login-fieldWrapper'>
						<div className='Login-fieldTitle'>
							<span>{getText('gameUi', 'school')}</span>
						</div>
						<InputSearch 
							schools={schools}
							setSelectedSchoolId={setSelectedSchoolId}
						/>
					</div>
					<div className='Login-fieldWrapper'>
						<div className='Login-fieldTitle'>
							<span>{getText('gameUi', 'class')}</span>
						</div>
						<input
							className='Login-classInput'
							name='class'
							type='text'
							placeholder={getText('gameUi', 'class')}
							value={schoolClass}
							onChange={(event) => {handleClassInput(event);}}
						/>
						<div className='Login-fieldTitle'>
							<span>{getText('gameUi', 'password')}</span>
						</div>
						<input
							className='Login-input'
							name="kode"
							type="password"
							placeholder={getText('gameUi', 'code')}
							value={password} 
							onChange={(event)=>{handleInput(event);}}
						/>
						{errorMessage &&
							<div className='Login-errorMessage'>
								<span>{errorMessage}</span>
							</div>
						}
					</div>
					<div className='Login-button'>
						<Button 
							isDisabled={!selectedSchoolId || !password || !isClassValid}
							classes={['submit', 'white', 'confirmTask', 'shadow', 'responsive']} 
							text={getText('gameUi', 'login')} 
							onClick={() => {validateLogin();}}
							type={'submit'}
						/>
					</div>
				</form>
			</div>
			<div className="Login-facilitatorBtn">
				<Button 
					type="a"
					classes={['white', 'shadow', 'small']}
					text={getText('statsTexts', 'facilitator')}
					href="/skole"
				/>
			</div>
		</div>
	);
};

Login.propTypes = {
	handleLogin: Proptypes.func.isRequired,
};

export default Login;