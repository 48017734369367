/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		subtype: null, // null, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1,
		canAnswerAnyAmount: false, // Overwrites numberOfAnswersToSelect. Allows user to select 1 or more options
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		image: null,
		text: '',
		options: [],
	},
	optionTemplate: {
		text: '', 
		effects: []
	}
};

/* Slider */
const sliderTemplate = {
	mainTemplate: {
		type: 'slider',
		image: null,
		text: '',
		minText: '',
		maxText: '',
		effects: []
	},
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		image: null,
		text: '',
		items: []
	},
	itemTemplate: {
		text: null,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		image: null,
		layout: '',
		text: '',
		withHeader: true,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		text: '',
		id: ''
	},
	itemTemplate: {
		text: '',
		effects: [
		]
	}
};

export {
	multipleChoiceTemplate,
	orderTemplate,
	sortTemplate,
	sliderTemplate,
};
