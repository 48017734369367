import React, { useEffect, useState, useRef } from 'react';
import Proptypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getText} from 'helpers/text-helper';
import AdminSchools from './admin-schools/admin-schools';
import AdminStatsController from 'components/admin/admin-stats/admin-stats-controller';
import AdminTasks from './admin-tasks/admin-tasks';
import Button from 'components/ui/button/button';
import './admin.scss';

const Admin = ({handleLogout}) => {
	/* Navigation */
	const [page, setPage] = useState('schools');

	/* Schools subscription and data */
	const unsubscribeSchools = useRef(null);
	const [schools, setSchools] = useState([]);
	const unsubscribeTagsData = useRef(null);
	const [tagsData, setTagsData] = useState([]);

	/* User subscription and data */
	const unsubscribeUsers = useRef(null);
	const [users, setUsers] = useState([]);

	/* Statistics subscription and data */
	const unsubscribeStatistics = useRef(null);
	const [statistics, setStatistics] = useState([]);

	/* Tasks subscription and data */
	const unsubscribeTasks = useRef(null);
	const [tasks, setTasks] = useState([]);
	
	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Subscribe to schools, statistics, users and tags*/
		subscribeToStatistics();
		subscribeToSchools();
		subscribeToTagsData();
		subscribeToUsers();
		subscribeToTasksData();
		
		return () => {
			/* Cancel subscriptions */
			if (unsubscribeStatistics.current) unsubscribeStatistics.current();
			if (unsubscribeSchools.current) unsubscribeSchools.current();
			if (unsubscribeUsers.current) unsubscribeUsers.current();
			if (unsubscribeTagsData.current) unsubscribeTagsData.current();
			if (unsubscribeTasks.current) unsubscribeTasks.current();

		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	/**
	 * Subscribe to users
	 */
	const subscribeToUsers = () => {
		/* Cancel previous subscription */
		if (unsubscribeUsers.current !== null) unsubscribeUsers.current();

		/* Subscribe to users */
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			unsubscribeUsers.current = db.collection(appConfig.usersDbName).onSnapshot(
				(querySnapshot) => {
					const users = [];
					querySnapshot.forEach((doc) => {
						users.push({id: doc.id, ...doc.data()});
					});
					setUsers(users);
					resolve();
				},
				(error) => {
					console.error(getText('error', 'couldNotGetUsers'), error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Subscribe to schools
	 */
	const subscribeToSchools = () => {
		/* Cancel previous subscription */
		if (unsubscribeSchools.current !== null) unsubscribeSchools.current();

		/* Subscribe to schools */
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			unsubscribeSchools.current = db.collection(appConfig.schoolsDbName).onSnapshot(
				(querySnapshot) => {
					const schools = [];
					querySnapshot.forEach((doc) => {
						schools.push({id: doc.id, ...doc.data()});
					});
					setSchools(schools);
					resolve();
				},
				(error) => {
					console.error(getText('error', 'couldNotGetSchool'), error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Subscribe to statistics
	 */
	const subscribeToStatistics = () => {
		if (unsubscribeStatistics.current !== null) unsubscribeStatistics.current();
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			unsubscribeStatistics.current = db.collection(appConfig.statisticsDbName).onSnapshot(
				(querySnapshot) => {
					let statistics = [];
					querySnapshot.forEach((doc) => {
						statistics.push({...doc.data()});
					});
					setStatistics(statistics);
					resolve();
				},
				(error) => {
					console.error(getText('error', 'couldNotGetStatistics'), error);
					reject(error);
				}
			);
		});
	};
	
	/**
	 * Subscribe to tags
	 * @returns {Promise}
	 */
	const subscribeToTagsData = () => {
		/* Cancel previous subscription */
		if (unsubscribeTagsData.current !== null) unsubscribeTagsData.current();
			
		/* Subscribe to tags */
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribeTagsData.current = db.collection(appConfig.tagsDbName).onSnapshot((querySnapshot) => {
				const tagsData = [];
				querySnapshot.forEach((doc) => {tagsData.push({id: doc.id, ...doc.data()});});
				setTagsData(tagsData);
				resolve({status: 'success'});
			},
			(error) => {
				/* Error: Could not get tags data */
				console.error('Could not get tags data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to tasks
	 * @returns {Promise}
	 */
	const subscribeToTasksData = () => {
		/* Cancel previous subscription */
		if (unsubscribeTasks.current !== null) unsubscribeTasks.current();

		/* Subscribe to tasks */
		return new Promise((resolve) => {
			const db = firebase.firestore();
			unsubscribeTasks.current = db.collection(appConfig.tasksDbName).onSnapshot((querySnapshot) => {
				const tasksData = [];
				querySnapshot.forEach((doc) => {tasksData.push({id: doc.id, ...doc.data()});});
				setTasks(tasksData);
				resolve({status: 'success'});
			},
			(error) => {
				/* Error: Could not get tasks data */
				console.error('Could not get tasks data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	return (
		<div className="Admin">
			{/* Back to game */}
			<div className='Admin-backBtn'>
				<Button 
					type="a"
					classes={['white']}
					text={getText('statsTexts', 'back')}
					href="/"
				/>
			</div>
			{/* Log out btn */}
			<div className='Admin-logout'>
				<Button
					text={getText('adminTexts', 'logout')}
					classes={['white', 'bold', 'fullWidth']}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Navigation */}
			<div className="Admin-navigation">
				<div 
					className={'Admin-navigationItem' + (page === 'schools' ? ' selected' : '')}
					onClick={() => {setPage('schools');}}
				>
					<span>{getText('statsTexts', 'schools')}</span>
				</div>
				<div 
					className={'Admin-navigationItem' + (page === 'stats' ? ' selected' : '')}
					onClick={() => {setPage('stats');}}
				>
					<span>{getText('statsTexts', 'stats')}</span>
				</div>
				<div 
					className={'Admin-navigationItem' + (page === 'tasks' ? ' selected' : '')}
					onClick={() => {setPage('tasks');}}
				>
					<span>{getText('statsTexts', 'tasks')}</span>
				</div>
			</div>

			{/* Schools */}
			{page === 'schools' && 
				<AdminSchools 
					schools={schools}
					tagsData={tagsData}
					users={users}
					statistics={statistics}
				/>
			}

			{/* Stats */}
			{page === 'stats' &&
				<AdminStatsController 
					schools={schools}
					tagsData={tagsData}
					statistics={statistics}
				/>
			}
			{/* Tasks */}
			{page === 'tasks' &&
				<AdminTasks 
					tasks={tasks}
				/>
			}
		</div>
	);
};

Admin.propTypes = {
	handleLogout: Proptypes.func.isRequired
};

export default Admin;