import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import './facilitator-filters.scss';

const FacilitatorFilters = ({schoolClasses, includedSchoolClasses, years, includedYears, toggleFilter}) => {	
	return (
		<div className='FacilitatorFilters'>
			{/* Filter by year */}
			<div className="FacilitatorFilters-filter years">
				<div className="FacilitatorFilters-filterTitle">
					<span>{getText('statsTexts', 'years')}:</span>
				</div>
				<div className="FacilitatorFilters-filterOptions">
					{years && years.map((year) => {
						const isSelected = includedYears ? (includedYears.indexOf(year) >= 0) : false;
						return (
							<div 
								key={year}
								className={'FacilitatorFilters-filterOption' + (isSelected ? ' selected' : '')}
								onClick={() => {toggleFilter('year', year);}}
							>
								<span>{year}</span>
							</div>
						);
					})}
				</div>
			</div>

			{/* Filter by school classes */}
			<div className="FacilitatorFilters-filter schoolClasses">
				<div className="FacilitatorFilters-filterTitle">
					<span>{getText('statsTexts', 'schoolClasses')}:</span>
				</div>
				<div className="FacilitatorFilters-filterOptions">
					{schoolClasses && schoolClasses.map((schoolClass) => {
						const isSelected = includedSchoolClasses ? 
							(includedSchoolClasses.indexOf(schoolClass) >= 0) : false;
						return (
							<div 
								key={schoolClass}
								className={'FacilitatorFilters-filterOption' + (isSelected ? ' selected' : '')}
								onClick={() => {toggleFilter('schoolClass', schoolClass);}}
							>
								<span>{schoolClass}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

FacilitatorFilters.propTypes = {
	schoolClasses: PropTypes.array.isRequired,
	includedSchoolClasses: PropTypes.array.isRequired,
	years: PropTypes.array.isRequired,
	includedYears: PropTypes.array.isRequired,
	toggleFilter: PropTypes.func.isRequired,
};

export default FacilitatorFilters;